import {
  LINK_LAYOUTS,
  LINK_STYLE_DISPLAY,
  LINK_TEMPLATE,
} from "@share/configs/const";
import IconRender from "@share/IconRender";
import { Button, Ellipsis, Image } from "antd-mobile";
import React, { useEffect, useMemo } from "react";
import style from "../../../BlockButtonLink/LinkLayouts/style.module.scss";

const Layout1 = (props: any) => {
  const {
    style_attributes,
    content_attributes,
    button_link_image,
    button_link_description,
    button_link_title,
    button_link_layout,
    button_link_size,
    button_link_style,
    button_link_template,
    button_link_icon,
    button_link_url_icon,
    index,
  } = props;

  const {
    button_content,
    button_icon,
    use_aff_url,
    target_url,
    origin_url,
    button_description,
    image,
  } = content_attributes || {};

  // const {
  //   button_size,
  //   button_link_template,
  //   // button_link_layout,
  //   button_link_style,
  // } = style_attributes || {};

  const buttonSizeClass = useMemo(() => {
    let valueClass = null;
    switch (button_link_size) {
      case "small":
        valueClass = "small-button";
        break;
      case "middle":
        valueClass = "middle-button";
        break;
      case "large":
        valueClass = "large-button";
        break;
    }
    return valueClass;
  }, [button_link_size]);

  const isShowIconRight = useMemo(
    () =>
      (button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_ICON ||
        button_link_style === LINK_STYLE_DISPLAY.ONLY_ICON) &&
      button_link_icon &&
      Number(index) % 2 === 0,
    [button_link_style, button_link_icon, button_link_layout]
  );

  const isShowImageRight = useMemo(
    () =>
      (button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE ||
        button_link_style === LINK_STYLE_DISPLAY.ONLY_IMAGE) &&
      button_link_image &&
      Number(index) % 2 === 1,
    [button_link_style, button_link_image, button_link_layout]
  );

  const isShowIconLeft = useMemo(
    () =>
      (button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_ICON ||
        button_link_style === LINK_STYLE_DISPLAY.ONLY_ICON) &&
      button_link_icon &&
      Number(index) % 2 === 1,
    [button_link_style, button_link_icon, button_link_layout]
  );

  const isShowImageLeft = useMemo(
    () =>
      (button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE ||
        button_link_style === LINK_STYLE_DISPLAY.ONLY_IMAGE) &&
      button_link_image &&
      Number(index) % 2 === 0,
    [button_link_style, button_link_image, button_link_layout]
  );

  const styleButtonLink = useMemo(() => {
    let styleObj = {};
    switch (button_link_template) {
      case LINK_TEMPLATE.TEMPLATE1:
        styleObj = {
          "--background-color": "#f0ecec00",
          "--border-color": "#333333",
        };
        break;
      case LINK_TEMPLATE.TEMPLATE2:
        styleObj = {
          "--background-color": "var(--button-bg-color-6)",
          "--border-color": "var(--button-border-color-6)",
        };
        break;
      case LINK_TEMPLATE.TEMPLATE3:
        styleObj = {
          "--background-color": "var(--button-bg-color-6)",
          "--border-color": "var(--button-border-color-6)",
          boxShadow: "4px 5px 0px #000000",
        };
        break;
    }
    return styleObj;
  }, [button_link_template]);

  const buttonClass = useMemo(() => {
    switch (button_link_template) {
      case LINK_TEMPLATE.TEMPLATE1:
        return style.template1;
      case LINK_TEMPLATE.TEMPLATE2:
        return style.template2;
      case LINK_TEMPLATE.TEMPLATE3:
        return style.template3;
    }
    return "";
  }, [button_link_template]);

  const imageSizeValue = useMemo(() => {
    let valueSize = null;
    switch (button_link_size) {
      case "small":
        valueSize = 38;
        break;
      case "middle":
        valueSize = 60;
        break;
      case "large":
        valueSize = 72;
        break;
    }
    return valueSize;
  }, [button_link_size]);
  return (
    <div>
      <Button
        style={{
          "--text-color": "unset",
          // ...styleButtonLink,
        }}
        size={button_link_size}
        block
        className={`${buttonSizeClass} ${style.buttonStyle} ${buttonClass}  button-border-radius button-border-width button-border-style button-link-wrapper  ${button_link_layout}`}
      >
        <div className="flex flex-row gap-2 items-center  justify-center">
          {isShowIconRight && (
            <IconRender
              iconName={button_link_icon}
              fillColor={
                button_link_template === LINK_TEMPLATE.TEMPLATE1
                  ? "var(--heading-color-6)"
                  : "var(--button-text-color)"
              }
            />
          )}
          {isShowImageLeft && (
            <div>
              <Image
                fit="cover"
                src={button_link_image}
                width={imageSizeValue}
                height={imageSizeValue}
                className="rounded"
              />
            </div>
          )}
          {(button_link_title || button_link_description) &&
          button_link_style !== LINK_STYLE_DISPLAY.ONLY_ICON &&
          button_link_style !== LINK_STYLE_DISPLAY.ONLY_IMAGE ? (
            <div className="flex flex-col gap-1 text-center grow break-words">
              {button_link_title && (
                <div
                  className="button-link-content"
                  style={{
                    color:
                      button_link_template === LINK_TEMPLATE.TEMPLATE1
                        ? "var(--heading-color-6)"
                        : "var(--button-text-color)",
                  }}
                >
                  <Ellipsis
                    content={button_link_title}
                    rows={3}
                  />
                </div>
              )}
              {button_link_description && (
                <div
                  className="button-link-description small"
                  style={{
                    color:
                      button_link_template === LINK_TEMPLATE.TEMPLATE1
                        ? "var(--heading-color-5)"
                        : "var(--button-text-color)",
                  }}
                >
                  <Ellipsis
                    content={button_link_description}
                    rows={5}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-1 text-center grow"></div>
          )}
          {isShowIconLeft && (
            <IconRender
              iconName={button_link_icon}
              fillColor={
                button_link_template === LINK_TEMPLATE.TEMPLATE1
                  ? "var(--heading-color-6)"
                  : "var(--button-text-color)"
              }
            />
          )}
          {isShowImageRight && (
            <div>
              <Image
                fit="cover"
                src={button_link_image}
                width={imageSizeValue}
                height={imageSizeValue}
                className="rounded"
              />
            </div>
          )}
        </div>
      </Button>
      <div
        className="absolute top-0 left-0 block w-full h-full rounded-lg"
        style={{
          backgroundColor: "transparent",
        }}
      ></div>
    </div>
  );
};

export default Layout1;
