import { TAB_TEMPLATE, TAB_WIDTH_DISPLAY } from "@share/configs/const";
import React, { useMemo } from "react";
import cls from "classnames";

const TextStyle = (props) => {
  const { tab, style_attributes } = props;
  const { tab_width_display, tab_template } = style_attributes || {};

  const textRender = useMemo(() => {
    let element = null;
    switch (tab_width_display) {
      case TAB_WIDTH_DISPLAY.AUTO:
        element = (
          <h5
            style={{
              color: "unset",
              margin: "unset",
            }}
          >
            {tab.name}
          </h5>
        );
        break;
      case TAB_WIDTH_DISPLAY.EQUAL:
        element = (
          <span
            className="h7"
            style={{
              color: "unset",
              margin: "unset",
            }}
          >
            {tab.name}
          </span>
        );
        break;
    }
    return element;
  }, [tab_width_display, tab.name]);

  return (
    <div
      className={cls(
        "text-center h-full",
        tab_template === TAB_TEMPLATE.TEMPLATE_2
          ? "flex items-center justify-center"
          : ""
      )}
      style={{
        whiteSpace:
          tab_width_display === TAB_WIDTH_DISPLAY.EQUAL ? "normal" : "unset",
        wordBreak:
          tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
            ? "break-word"
            : "unset",
      }}
    >
      {textRender}
    </div>
  );
};

export default TextStyle;
