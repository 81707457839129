import { CardInterface } from "@features/main/store/interface";
import { CARD_ITEM_ON_ROW, CARD_LAYOUTS } from "@share/configs/const";
import { Divider } from "antd-mobile";
import React, { useMemo } from "react";
import CardDetail, { StyleAttributesInterface } from "../../CardDetail";

type Props = {
  cardItemArr: CardInterface[];
  style_attibutes: StyleAttributesInterface;
  see_more_btn_content: string;
};

const CardStyleGrid = (props: Props) => {
  const { cardItemArr, style_attibutes, see_more_btn_content } = props;
  const { card_layout, item_on_row } = style_attibutes || {};

  const gridCardStyle = useMemo(() => {
    let gridStyleValue = null;
    switch (item_on_row) {
      case CARD_ITEM_ON_ROW.ONE:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case CARD_ITEM_ON_ROW.TWO:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      default:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
          gap: "12px",
        };
    }
    return gridStyleValue;
  }, [item_on_row]);

  return (
    <div style={gridCardStyle}>
      {cardItemArr?.map((ele, index) => (
        <div key={ele?.uid}>
          <CardDetail
            key={ele?.uid}
            style_attributes={style_attibutes}
            card_attributes={ele}
            card_see_more_btn={see_more_btn_content}
          />
          {index !== cardItemArr.length - 1 &&
            (card_layout === CARD_LAYOUTS.LAYOUT_5 ||
              card_layout === CARD_LAYOUTS.LAYOUT_6) && (
              <Divider
                style={{
                  borderColor: "#A3A3A3",
                }}
              />
            )}
        </div>
      ))}
    </div>
  );
};

export default CardStyleGrid;
