import { Block } from "@features/main/store/interface";
import { useCallback } from "react";
import { DESIGN_COMPONENT } from "../../design.config";

type Props = {
  blockData: Block;
  positionSlug?: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
  onGetLatestBlockData?: (block: Block) => void;
};

const MappingTypeComponent = (props: Props) => {
  const { blockData } = props;
  const type = blockData && blockData.block_type;

  const ElementRender = useCallback(
    (props) => {
      let Element = <></>;
      if (DESIGN_COMPONENT[type]) {
        const RenderComponent = DESIGN_COMPONENT[type];
        return <RenderComponent {...props} />;
      }
      return Element;
    },
    [type]
  );
  return <ElementRender {...props} />;
};

export default MappingTypeComponent;
