import React from "react";
import BackgroundTourOpening from "@features/setting/components/ThemeSetting/BackgroundSetting/components/BackgroundTourOpening";

interface PropsInteface {
  Icon: any;
  title: string;
  subTitle?: string;
  children?: any;
}

export default function SettingHeader(props: PropsInteface) {
  const { Icon, title, subTitle, children } = props;
  return (
    <div
      className="py-3 px-3 bg-white pb-2"
      style={{
        boxShadow: "inset 0px -1px 0px #EEEEEE",
      }}
    >
      <div className="block flex">
        <span className="flex-1 m-auto text-center items-center text-lg">
          <Icon className="inline-block mr-2" />
          {title}
        </span>
        {!!children ? (
          <div className="flex items-center">{children}</div>
        ) : null}
      </div>
      {subTitle && (
        <div className="text-descFontSize text-descColorDefault">
          {subTitle}
        </div>
      )}
    </div>
  );
}
