import { useAppSelector } from "@app/hooks";
import { IMAGE_MAX } from "@features/block/block-editor/ImagePopup";
import { Block } from "@features/main/store/interface";
import { STATUS } from "@share/configs/const";
import { Image } from "antd-mobile";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import BlockWrapper from "../../BlockWrapper";

interface ImagePopupProps {
  blockData: Block;
  positionSlug: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
}

const ImagePopup: React.FunctionComponent<
  React.PropsWithChildren<ImagePopupProps>
> = (props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const { t } = useTranslation();
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};
  const enable = blockData && blockData.enable;

  const firstImageEnable = blockData?.content_attributes?.items?.find(
    (e) => e.enable === STATUS.ENABLE
  );

  return (
    <BlockWrapper
      blockType={blockData.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      isFirstItem={isFirstItem}
      outsideSpace={outside_space}
      extraStyle={{
        padding: "0",
        border: "0",
        background: "transparent",
        // border: "0",
        borderWidth: "0",
        backdropFilter: "none",
        borderStyle: "none",
        boxShadow: "none",
      }}
      // insideSpace={inner_space_number}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      {firstImageEnable ? (
        <>
          <div className="relative">
            <div className="w-auto m-width-[80vw] m-auto">
              <Image
                src={firstImageEnable?.image}
                className="rounded-lg m-width-[460px] "
                style={{
                  maxHeight: "calc(100vh - 20px)",
                  border: "1px solid rgba(130, 130, 130, 0.75)",
                  background: "rgba(0, 0, 0, 0.4)",
                }}
              />
            </div>
            <span
              className="absolute bottom-0 right-0 m-1 py-1 px-2 rounded text-white"
              style={{
                maxHeight: "calc(100vh - 20px)",
              }}
            />
          </div>
          <span
            className="absolute bottom-0 right-0 m-1 py-1 px-2 rounded text-white"
            style={{
              background: "rgba(38, 38, 38, 0.6)",
            }}
          >
            {blockData?.content_attributes?.items?.length}/{IMAGE_MAX}
          </span>
        </>
      ) : (
        <>{t("ss_builder_title_block_image_popup")}</>
      )}
    </BlockWrapper>
  );
};

export default ImagePopup;
