import { ImageGalleryInterface } from "@features/main/store/interface";
import { SLIDE_STYLE } from "@share/configs/const";
import React, { useCallback } from "react";
import ImageNotPartialVisible from "./ImageGalleryNotPartialVisible";
import ImagePanorama from "./ImageGalleryPanorama/index";
import ImagePartialVisibleRight from "./ImageGalleryPartialVisibleRight";

type Props = {
  imageGalleryItemArr: ImageGalleryInterface[];
  style_attributes: any;
};

export const IMAGE_GALLERY_SLIDE_TYPE_COMPONENT = {
  [SLIDE_STYLE.NOT_PARTIAL_VISIBLE]: ImageNotPartialVisible,
  [SLIDE_STYLE.PANORAMA]: ImagePanorama,
  [SLIDE_STYLE.PARTIAL_VISIBLE_RIGHT]: ImagePartialVisibleRight,
};

const ImageGalleryStyleSlide = (props: Props) => {
  const { imageGalleryItemArr, style_attributes } = props;

  const ImageGallerySlideTypeRender = useCallback((props) => {
    let Element = <></>;
    if (
      IMAGE_GALLERY_SLIDE_TYPE_COMPONENT[props?.style_attributes?.slide_style]
    ) {
      const RenderComponent =
        IMAGE_GALLERY_SLIDE_TYPE_COMPONENT[
          props?.style_attributes?.slide_style
        ];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);
  return (
    <ImageGallerySlideTypeRender
      imageGalleryItemArr={imageGalleryItemArr}
      style_attributes={style_attributes}
    />
  );
};

export default ImageGalleryStyleSlide;
