import {
  TAB_LAYOUT,
  TAB_TEMPLATE,
  TAB_WIDTH_DISPLAY,
} from "@share/configs/const";
import IconRender from "@share/IconRender";
import React, { useMemo } from "react";

const TextAndIconStyle = (props) => {
  const { tab, style_attributes } = props;
  const { tab_layout, tab_width_display, tab_template } =
    style_attributes || {};

  const layoutRender1 = useMemo(() => {
    let element = null;
    switch (tab_layout) {
      case TAB_LAYOUT.TEXT_RIGHT:
        element = (
          <div className="min-h-[24px] flex flex-row gap-[6px] text-center items-center justify-center">
            <IconRender
              iconName={tab.icon}
              className="w-[20px] h-[20px]"
            />
            <h5
              style={{
                color: "unset",
                margin: "unset",
                whiteSpace:
                  tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
                    ? "normal"
                    : "unset",
                wordBreak:
                  tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
                    ? "break-word"
                    : "unset",
              }}
            >
              {tab.name}
            </h5>
          </div>
        );
        break;
      case TAB_LAYOUT.TEXT_UNDER:
        element = (
          <div className="min-h-[24px] flex flex-col gap-[6px] text-center items-center justify-center">
            <div className="box_icon">
              <IconRender
                iconName={tab.icon}
                className="w-[20px] h-[20px]"
              />
            </div>
            <h5
              style={{
                color: "unset",
                margin: "unset",
                whiteSpace:
                  tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
                    ? "normal"
                    : "unset",
                wordBreak:
                  tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
                    ? "break-word"
                    : "unset",
              }}
            >
              {tab.name}
            </h5>
          </div>
        );
        break;
    }
    return element;
  }, [tab_layout, tab.icon, tab.name]);

  const layoutRender2 = useMemo(() => {
    let element = null;
    switch (tab_layout) {
      case TAB_LAYOUT.TEXT_RIGHT:
        element = (
          <div className="min-h-[24px] flex flex-row gap-[6px] text-center items-center justify-center">
            <IconRender
              iconName={tab.icon}
              className="w-[16px] h-[16px]"
            />
            <span
              className="h7"
              style={{
                color: "unset",
                margin: "unset",
                whiteSpace:
                  tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
                    ? "normal"
                    : "unset",
                wordBreak:
                  tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
                    ? "break-word"
                    : "unset",
              }}
            >
              {tab.name}
            </span>
          </div>
        );
        break;
      case TAB_LAYOUT.TEXT_UNDER:
        element = (
          <div className="min-h-[24px] flex flex-col gap-[6px] text-center h-full">
            <div>
              <div className="box_icon m-auto">
                <IconRender
                  iconName={tab.icon}
                  className="w-[20px] h-[20px]"
                />
              </div>
              <span
                className="h7"
                style={{
                  color: "unset",
                  margin: "unset",
                  whiteSpace:
                    tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
                      ? "normal"
                      : "unset",
                  wordBreak:
                    tab_width_display === TAB_WIDTH_DISPLAY.EQUAL
                      ? "break-word"
                      : "unset",
                }}
              >
                {tab.name}
              </span>
            </div>
          </div>
        );
        break;
    }
    return element;
  }, [tab_layout, tab.icon, tab.name]);

  const layoutRender = useMemo(() => {
    let element = null;
    switch (tab_width_display) {
      case TAB_WIDTH_DISPLAY.AUTO:
        element = layoutRender1;
        break;
      case TAB_WIDTH_DISPLAY.EQUAL:
        element = layoutRender2;
        break;
    }
    return element;
  }, [tab_width_display, layoutRender1, layoutRender2]);

  return layoutRender;
};

export default TextAndIconStyle;
