const getBlurMarks = () => {
  const BLUR_BACKGROUND_MAXIMUM = 100;
  const result = {};
  for (let value = 0; value <= BLUR_BACKGROUND_MAXIMUM; value++) {
    if (value == 0 || value == BLUR_BACKGROUND_MAXIMUM) {
      result[value] = value;
    } else {
      result[value] = "";
    }
  }
  return result;
};

export const blurMarks = getBlurMarks();

const getBlurMarksBackgroundImage = () => {
  const BLUR_BACKGROUND_MAXIMUM = 30;
  const result = {};
  for (let value = 0; value <= BLUR_BACKGROUND_MAXIMUM; value++) {
    if (value == 0 || value == BLUR_BACKGROUND_MAXIMUM) {
      result[value] = value;
    } else {
      result[value] = "";
    }
  }
  return result;
};

export const blurMarksBackgroundImage = getBlurMarksBackgroundImage();
