import { useAppDispatch, useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useMemo, useRef, useState } from "react";
import BlockWrapper from "../../BlockWrapper";
import * as DOMPurify from "dompurify";
import BlockHeading from "../../BlockHeadingComponent";
import { t } from "i18next";
import { AngelRightSIcon, ModalCloseIcon } from "@share/icons/solid-icon";
import FullScreenPopup from "@share/components/full-screen-popup";
import cls from "classnames";
import Bowser from "bowser"; 
import { useDetectdevice, useResizeObserveElement } from "@share/hooks";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};
const MAX_HEIGHT_BLOCK_WRAPPER = 276;

const BlockText = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;
  const dispatch = useAppDispatch();
  const { draft_content } = blockData?.content_attributes || {};

  const content =
    typeof draft_content === "string"
      ? draft_content
      : draftToHtml(draft_content);

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const cleanContent = DOMPurify.sanitize(content);
  const wrapperRef = useRef<HTMLDivElement>();

  const [isOpenPopupDetail, setIsOpenPopupDetail] = useState(false);

  const handleOpenPopupDetail = (status) => {
    setIsOpenPopupDetail(status);
  };

  const { height: elementHeight } = useResizeObserveElement(wrapperRef);

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <div
        className={cls(
          `block-content-wrapper`,
          elementHeight > MAX_HEIGHT_BLOCK_WRAPPER ? "mask-image" : ""
        )}
        style={{
          maxHeight:
            elementHeight > MAX_HEIGHT_BLOCK_WRAPPER
              ? `${MAX_HEIGHT_BLOCK_WRAPPER}px`
              : "",
        }}
      >
        <div ref={wrapperRef}>
          <BlockHeading
            blockType={blockData.block_type}
            title={blockData?.content_attributes?.block_heading}
            subtitle={blockData?.content_attributes?.block_description}
          />
          <div
            className="text-content break-words overflow-hidden"
            style={{
              color: "var(--text-color-6)",
            }}
            dangerouslySetInnerHTML={{
              __html: cleanContent,
            }}
          ></div>
        </div>
      </div>
      {elementHeight > MAX_HEIGHT_BLOCK_WRAPPER && (
        <div
          className="btn-view-more-text"
          onClick={() => handleOpenPopupDetail(true)}
        >
          {t("ss_builder_block_text_see_more_btn_default_title")}
          <AngelRightSIcon
            width={16}
            height={16}
          />
        </div>
      )}
      <FullScreenPopup
        visible={isOpenPopupDetail}
        onMaskClick={() => setIsOpenPopupDetail(false)}
        className="full-popup-block-text"
      >
        <div className="popup-wrapper-block-text">
          <BlockHeading
            blockType={blockData.block_type}
            title={blockData?.content_attributes?.block_heading}
            subtitle={blockData?.content_attributes?.block_description}
          />
          <div
            className="w-[32px] h-[32px] p-1 cursor-pointer absolute right-2 top-2 flex justify-center items-center"
            onClick={() => handleOpenPopupDetail(false)}
          >
            <ModalCloseIcon
              width={24}
              height={24}
              fillColor="#333"
            />
          </div>
          <div
            className="text-content break-words overflow-hidden mt-3"
            dangerouslySetInnerHTML={{
              __html: cleanContent,
            }}
          ></div>
        </div>
      </FullScreenPopup>
    </BlockWrapper>
  );
};

export default BlockText;
