import { useAppDispatch, useAppSelector } from "@app/hooks";
import AffiliateProductAddNew from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductAddNew";
import { requestPostLinkMiddlewareV2 } from "@features/block/block.conf";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import { Block } from "@features/main/store/interface";
import { updateBlock } from "@features/main/store/positionsSlice";
import FullScreenPopup from "@share/components/full-screen-popup";
import { MAX_PRODUCTS_ALL_GROUPS, PRODUCT_MAX } from "@share/configs/const";
import { AddIcon, InformationTriangleIcon } from "@share/icons";
import { Button, Modal } from "antd-mobile";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  productGroupId: string;
  affiliateProductGroupData: any;
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  productGroupMaximum: number;
  isCreatedNew: boolean;
  onUpdateProductGroupId: (val: string) => void;
  onGetLatestBlockData: (block: Block) => void;
}

const AddNewProductButton = (props: Props) => {
  const {
    productGroupId,
    affiliateProductGroupData,
    blockData,
    positionSlug,
    tabUid,
    productGroupMaximum,
    isCreatedNew,
    onUpdateProductGroupId,
    onGetLatestBlockData = () => {},
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const siteSettings = useAppSelector((state) => state.siteSettings);

  const extraData = {
    user_id: siteSettings.user_id,
  };

  const [isOpenAddNewProduct, setIsOpenAddNewProduct] = useState(false);

  const isFulledProductsAllGroups = useMemo(() => {
    let existedProductArr = [];
    affiliateProductGroupData.forEach((x) => {
      const affiliateProductArr = x?.content_attributes?.items || [];
      existedProductArr = [...existedProductArr, ...affiliateProductArr];
    });
    return !!(existedProductArr?.length >= MAX_PRODUCTS_ALL_GROUPS);
  }, [affiliateProductGroupData]);

  const isAllowedToAddProducts = useMemo(
    () =>
      productGroupId
        ? affiliateProductGroupData
            ?.find((x) => x?.uid === productGroupId)
            ?.content_attributes?.items?.filter((y) => y?.is_cleanup_lock === 1)
            ?.length < PRODUCT_MAX
        : true,
    [productGroupId, affiliateProductGroupData]
  );

  const productRate = useMemo(() => {
    const productsLength = affiliateProductGroupData?.find(
      (x) => x?.uid === productGroupId
    )?.content_attributes?.items?.length;
    return `${
      productsLength < PRODUCT_MAX ? productsLength : PRODUCT_MAX
    }/${PRODUCT_MAX}`;
  }, [affiliateProductGroupData, productGroupId]);

  const isEnableOpacity = !!(
    isFulledProductsAllGroups || !isAllowedToAddProducts
  );

  const handleOpenAddAffiliateProduct = (e) => {
    e.stopPropagation();
    if (isFulledProductsAllGroups) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationTriangleIcon
            className="w-12 h-12"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px] mb-2">
              {t("ss_builder_product_total_limitation_warning_title")}
            </div>
            <div className="">
              {t("ss_builder_product_total_limitation_warning_description", {
                max_products_all_groups: MAX_PRODUCTS_ALL_GROUPS,
              })}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_confirm_replace_theme_template_understood"),
          },
        ],
      });
    } else if (!isAllowedToAddProducts) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationTriangleIcon
            className="w-12 h-12"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px] mb-2">
              {t("ss_builder_product_total_limitation_warning_title")}
            </div>
            <div className="">
              {t("ss_builder_product_group_limitation_warning_description", {
                max_products: PRODUCT_MAX,
              })}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_confirm_replace_theme_template_understood"),
          },
        ],
      });
    } else {
      setIsOpenAddNewProduct(true);
    }
  };

  const handleSaveAffiliateProductAddNew = async (
    data,
    activedProductGroup
  ) => {
    const newDataAffiliateProduct = {
      ...blockData,
      content_attributes: {
        ...blockData.content_attributes,
        items: data,
        is_created_new: isCreatedNew,
      },
    };
    try {
      const newData = await requestPostLinkMiddlewareV2(
        newDataAffiliateProduct,
        extraData
      );
      if (newData) {
        activedProductGroup && onUpdateProductGroupId(activedProductGroup);
        if (positionSlug) {
          dispatch(
            updateBlock({
              tabUid,
              positionSlug,
              blockData: newData,
            })
          );
        }
        onGetLatestBlockData(newData);
        setIsOpenAddNewProduct(false);
      }
    } catch (e) {
      dispatch(
        setDynamicParams({
          data: { isErrorNetwork: true },
        })
      );
      return;
    }
  };

  const handleAddNewAffiliateProductGroup = (data, productGroupId) => {
    const newDataAffiliateProduct = {
      ...blockData,
      content_attributes: {
        ...blockData.content_attributes,
        items: data,
        is_created_new: isCreatedNew,
      },
    };
    onUpdateProductGroupId(productGroupId);
    if (positionSlug) {
      dispatch(
        updateBlock({
          tabUid,
          positionSlug,
          blockData: newDataAffiliateProduct,
        })
      );
    }
    onGetLatestBlockData(newDataAffiliateProduct);
  };

  return (
    <>
      <Button
        block
        size="middle"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "12px",
          gap: "8px",
          backgroundColor: "#2563EB",
          borderRadius: "4px",
          color: "#FFFFFF",
          border: "none",
          opacity: isEnableOpacity ? "40%" : "",
        }}
        onClick={handleOpenAddAffiliateProduct}
      >
        <AddIcon className="w-[18px] h-[18px]" />
        <div>{t("ss_builder_add_affiliate_product_list_btn_label")}</div>
        <div>{productGroupId ? productRate : ""}</div>
      </Button>
      {isOpenAddNewProduct && (
        <FullScreenPopup
          visible={isOpenAddNewProduct}
          onMaskClick={() => setIsOpenAddNewProduct(false)}
        >
          <AffiliateProductAddNew
            callbackCancelAffiliateProductAddNew={() =>
              setIsOpenAddNewProduct(false)
            }
            callbackSaveAffiliateProductAddNew={
              handleSaveAffiliateProductAddNew
            }
            affiliateProductGroupData={affiliateProductGroupData}
            activeProductGroup={productGroupId}
            callbackAddNewAffiliateProductGroup={
              handleAddNewAffiliateProductGroup
            }
            productGroupMaximum={productGroupMaximum}
          />
        </FullScreenPopup>
      )}
    </>
  );
};

export default AddNewProductButton;
