import { useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import React from "react";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";
import ButtonMultiLinkDetail from "./ButtonMultiLinkDetail";

export interface StyleAttributesInterface {
  button_size: any;
  button_link_template: string;
  button_link_layout: string;
  button_link_style: string;
}

export interface ContentAttributesInterface {
  button_content: string;
  button_icon: string;
  button_icon_url: string;
  use_aff_url: number;
  target_url: string;
  origin_url: string;
  button_description: string;
  image: string;
  image_name: string;
}

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockButtonMultiLink = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;

  const enable = blockData && blockData.enable;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <BlockHeading
        title={blockData?.content_attributes?.button_content}
        blockType={blockData.block_type}
        subtitle={blockData?.content_attributes?.button_description}
      />
      <ButtonMultiLinkDetail {...props} />
    </BlockWrapper>
  );
};

export default BlockButtonMultiLink;
