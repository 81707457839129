import { Selector } from "antd-mobile";
import React from "react";

const ProductGroupListSelector = (props) => {
  const { options, columns, onSelectItem, valueSelectItem, onClose } = props;
  const handleSelectItem = (val) => {
    onSelectItem(val[0]);
    onClose();
  };

  return (
    <div
      style={{
        maxHeight: "65vh",
        backgroundColor: "#F5F5F5",
        overflow: "auto",
        overscrollBehavior: "contain",
      }}
    >
      <Selector
        columns={columns}
        onChange={handleSelectItem}
        options={options}
        value={valueSelectItem}
        showCheckMark={false}
        style={{
          padding: "12px",
          "--checked-border": "solid var(--adm-color-primary) 1px",
          "--color": "#FFFFFF",
        }}
      />
    </div>
  );
};

export default ProductGroupListSelector;
