import { useAppSelector } from "@app/hooks";
import BlockWrapper from "../../BlockWrapper";
import CardContentStyle from "./CardContentStyle";
import { Block } from "@features/main/store/interface";
import { BOOKING_PROFILE_STYLE } from "@features/block/block-editor/BookingProfile/constant";
import EmbedCodeStyle from "./EmbedCodeStyle";
import { BOOKING_PROFILE_PATH } from "@share/configs/booking-profile";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockBookingProfile = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;

  const enable = blockData && blockData.enable;
  const content_attributes = blockData?.content_attributes;
  const style_attributes = blockData?.style_attributes;
  const siteSettings = useAppSelector((state) => state.siteSettings);

  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};
  const {
    image,
    booking_profile_title,
    booking_profile_description,
    booking_profile_button_content,
    open_new_tab = false,
    apply_block_settings = false,
  } = content_attributes || {};

  const { booking_profile_style = BOOKING_PROFILE_STYLE.CARD_CONTENT } =
    style_attributes || {};

  const cardContentMode = !!(
    booking_profile_style === BOOKING_PROFILE_STYLE.CARD_CONTENT
  );
  const embedCodeMode = !!(
    booking_profile_style === BOOKING_PROFILE_STYLE.EMBED_CODE
  );

  //Dùng để kích hoạt việc không áp dụng các thiết lập blocks
  const advancedSetting = embedCodeMode
    ? {
        use_advanced_setting: !apply_block_settings,
        use_block_space: outside_space,
      }
    : {};

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
      advancedSetting={advancedSetting}
    >
      {cardContentMode && (
        <CardContentStyle
          image={image}
          booking_profile_title={booking_profile_title}
          booking_profile_description={booking_profile_description}
          booking_profile_button_content={booking_profile_button_content}
        />
      )}
      {embedCodeMode && (
        <EmbedCodeStyle
          apply_block_settings={apply_block_settings}
          open_new_tab={open_new_tab}
        />
      )}
    </BlockWrapper>
  );
};

export default BlockBookingProfile;
