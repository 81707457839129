import { Ellipsis } from "antd-mobile";
import React from "react";
import LazyImage from "@share/components/lazy-image";
import { useTranslation } from "react-i18next";
import { LABEL_PRODUCT } from "@share/configs/const";
import ProductMoreActions from "../../ProductMoreActions";
import { useAffiliateContext } from "@features/block/block-editor/AffiliateProductGroup/context";
import { getHorizontalResizeUrl } from "@share/helper/image";

const ProductDetailStyle3 = (props) => {
  const {
    product,
    productIndex,
    affiliateProductGroupData,
    productGroupMaximum,
    onUpdateProductGroupData,
  } = props;

  const { frameData, usingFrame } = useAffiliateContext();

  const { t } = useTranslation();
  const isHot = product?.label_product?.includes(LABEL_PRODUCT.HOT);
  const isFavourite = product?.label_product?.includes(LABEL_PRODUCT.FAVOURITE);
  const isSale = product?.label_product?.includes(LABEL_PRODUCT.SALE);

  return (
    <div className="grid grid-cols-2 gap-3">
      {productIndex % 2 !== 0 && (
        <Ellipsis
          direction="end"
          content={product.title}
          className="m-auto text-center affiliate-product-name"
          rows={6}
          style={{
            color: "var(--heading-color-6)",
          }}
        />
      )}
      <div
        className="relative"
        id={`affiliate-product-item-${product.uid}`}
      >
        {usingFrame && frameData && (
          <div
            className="absolute top-0 left-0 z-10"
            style={{
              background: `url("${getHorizontalResizeUrl(
                frameData.urlEU,
                512
              )}") no-repeat left bottom/ contain`,
              aspectRatio: "1/1",
              borderRadius: "4px",
              width: "100%",
              height: "auto",
            }}
          ></div>
        )}
        <div className="absolute top-0 right-0 z-[12]">
          <ProductMoreActions
            productData={product}
            affiliateProductGroupData={affiliateProductGroupData}
            productGroupMaximum={productGroupMaximum}
            onUpdateProductGroupData={onUpdateProductGroupData}
          />
        </div>
        <LazyImage
          key={product.uid}
          src={product.image}
          // fit="cover"
          width={512}
          height={512}
          className="object-center affiliate-product-image"
          style={{
            backgroundSize: "cover",
            aspectRatio: "1/1",
            borderRadius: "4px",
            margin: "0 auto",
            objectFit: "cover",
            width: "100%",
            outline: "solid rgba(0, 0, 0, 0.16) 1px",
            outlineOffset: "-1px",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              "https://i.ecomobi.com/ssp/passio-page/image-default.png";
          }}
        />
        <div
          className="absolute z-[11]"
          style={{ top: "8px", left: "-3px" }}
        >
          {isFavourite && (
            <div className="favourite-label ">
              <span className="text-label default-font-family px-1  ">
                {t("ss_builder_affiliate_product_label_product_like")}
              </span>
            </div>
          )}
          {isHot && (
            <div className="hot-label">
              <span className="text-label default-font-family px-1 ">
                {t("ss_builder_affiliate_product_label_product_hot")}
              </span>
            </div>
          )}
        </div>
        {isSale && (
          <div className="absolute top-2 right-2 z-[11]">
            <div className="sale">
              <span className="text-label default-font-family px-1 ">
                {t("ss_builder_affiliate_product_label_product_discount")}
              </span>
            </div>
          </div>
        )}
      </div>
      {productIndex % 2 === 0 && (
        // <div className="text-center m-auto affiliate-product-name">
        //   {product.title}
        // </div>
        <Ellipsis
          direction="end"
          content={product.title}
          className="m-auto text-center affiliate-product-name"
          rows={6}
          style={{
            color: "var(--heading-color-6)",
          }}
        />
      )}
    </div>
  );
};

export default ProductDetailStyle3;
