import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  COLOR_TYPE_SOLID,
  RADIUS_STYLE_CUSTOM,
  RADIUS_STYLE_ROUNDED,
} from "@features/block/block-editor/Combined/const";
import { BUTTON_SETTING_SHADOW_STYLE } from "@share/configs/const";
import { getHexColor } from "@share/lib";
import cls from "classnames";
import { useGetSizeImageByUrl } from "@features/block/hooks/useGetSizeImageByUrl";
import { useResizeObserveElement } from "@share/hooks";

const AdvancedSettingWrapper = (props) => {
  const { children, advancedSetting } = props;
  const {
    border_color,
    border_type,
    border_width,
    color_gradient_1,
    color_gradient_2,
    color_gradient_direction,
    color_paragraph,
    color_solid,
    color_title,
    color_type,
    custom_radius,
    image_direction,
    image_opacity,
    image_overlay_color,
    image_url,
    radius_type,
    shadow_color,
    shadow_type,
    use_advanced_setting,
    use_block_space,
    use_border,
    use_color,
    use_image,
    use_image_mask,
    image_transparent,
  } = advancedSetting || {};

  const blockContentRef = useRef(null);

  const { height: blockContentHeight } =
    useResizeObserveElement(blockContentRef);

  //Calculate the height of the block content = contentHeight + paddingY * 2 + borderWidth * 2
  const contentHeight = blockContentHeight + 12 * 2 + border_width * 2;

  const getHeightAndWidth = useMemo(() => {
    if (use_advanced_setting) {
      if (use_block_space)
        return { width: "calc(100% - 24px)", height: contentHeight };
      return { width: "100%", height: "100%" };
    } else return { width: "100%", height: "100%" };
  }, [use_block_space, use_advanced_setting, contentHeight]);

  const getBorderRadius = useMemo(() => {
    if (!use_advanced_setting) return;
    if (radius_type === RADIUS_STYLE_ROUNDED) return { borderRadius: "8px" };
    if (radius_type === RADIUS_STYLE_CUSTOM)
      return { borderRadius: `${custom_radius}px` };
    return {};
  }, [radius_type, custom_radius, use_advanced_setting]);
  const getShadow = useMemo(() => {
    if (!use_advanced_setting) return;

    if (shadow_type === BUTTON_SETTING_SHADOW_STYLE.SOFT_SHADOW)
      return { boxShadow: `0px 3px 6px 0px ${shadow_color}` };
    if (shadow_type === BUTTON_SETTING_SHADOW_STYLE.HARD_SHADOW)
      return { boxShadow: `5px 5px 0px 0px ${shadow_color}` };
    return {};
  }, [shadow_type, shadow_color, use_advanced_setting]);

  const getBorder = useMemo(() => {
    if (!use_advanced_setting || !use_border) return {};
    return {
      borderColor: border_color,
      borderWidth: border_width,
      borderStyle: border_type,
    };
  }, [
    border_color,
    border_width,
    border_type,
    use_advanced_setting,
    use_border,
  ]);

  const { width: backgroundImageWidth } = useGetSizeImageByUrl(image_url);

  const getStyleBackgroundWithImage = useMemo(() => {
    if ((!use_image && !image_url) || !use_advanced_setting) return {};
    const isCoverBackground =
      backgroundImageWidth >= 512 ||
      (backgroundImageWidth < 512 && image_direction === "center");
    return {
      backgroundImage: `url("${image_url}")`,
      opacity: image_opacity / 100,
      filter: `blur(${image_transparent}px)`,
      backgroundPosition: image_direction || "center",
      backgroundSize: isCoverBackground ? "cover" : "",
    };
  }, [
    use_image,
    image_url,
    image_opacity,
    image_transparent,
    image_direction,
    use_advanced_setting,
    backgroundImageWidth,
  ]);

  const getStyleBackgroundWithColor = useMemo(() => {
    if (!use_color || !use_advanced_setting) return {};
    else {
      if (color_type === COLOR_TYPE_SOLID) {
        return {
          backgroundColor: color_solid,
        };
      } else {
        return {
          backgroundImage: `linear-gradient(${
            color_gradient_direction ?? "circle"
          }, ${getHexColor(color_gradient_1, 300)}, ${getHexColor(
            color_gradient_2,
            300
          )})`,
        };
      }
    }
  }, [
    use_color,
    color_type,
    color_solid,
    color_gradient_direction,
    color_gradient_1,
    color_gradient_2,
    use_advanced_setting,
  ]);

  return (
    <div
      style={{
        height: contentHeight,
        width: "100%",
      }}
      className="block-advanced-setting"
    >
      {use_advanced_setting && use_image_mask && use_image && (
        <div
          className="absolute left-[50%] translate-x-[-50%] block z-[3]"
          style={{
            backgroundColor: image_overlay_color,
            // opacity: 0.6,
            ...getHeightAndWidth,
            ...getBorderRadius,
            ...getBorder,
            ...getShadow,
          }}
        ></div>
      )}
      {use_advanced_setting && use_image && (
        <div
          className=" absolute left-[50%] translate-x-[-50%] block z-[2]"
          style={{
            ...getStyleBackgroundWithImage,
            backgroundRepeat: "no-repeat",
            ...getHeightAndWidth,
            ...getBorderRadius,
            // ...getBorder,
            ...getShadow,
          }}
        ></div>
      )}
      <div
        className={cls(
          "absolute left-[50%] translate-x-[-50%] block z-[4] py-3"
          // use_block_space && "px-3"
        )}
        style={{
          ...getHeightAndWidth,
          ...getBorderRadius,
          ...getBorder,
          ...getShadow,
          paddingLeft: advancedSetting?.use_advanced_setting
            ? "var(--block-inner-space)"
            : "",
          paddingRight: advancedSetting?.use_advanced_setting
            ? "var(--block-inner-space)"
            : "",
        }}
      >
        <div
          className="combined-block-design-content w-full"
          ref={blockContentRef}
        >
          {children}
        </div>
      </div>
      {use_advanced_setting && use_color && (
        <div
          className="absolute left-[50%] translate-x-[-50%] block z-[1]"
          style={{
            ...getStyleBackgroundWithColor,
            ...getHeightAndWidth,
            ...getBorderRadius,
            // ...getBorder,
            ...getShadow,
          }}
        ></div>
      )}
    </div>
  );
};

export default AdvancedSettingWrapper;
