import React, { useEffect, useMemo, useRef, useState } from "react";
import { Popover, Toast, Modal, Input } from "antd-mobile";
import cls from "classnames";
import {
  ArrowUp,
  DeleteIcon,
  ArrowDown,
  EyeHiddenIcon,
  EyeVisibleIcon,
  EditTabIcon,
  MoreIcon,
  CheckCircleIcon,
  InformationIcon,
  CloseCircleIconV2,
  InformationTriangleIcon,
  AddIconTemplate,
  AddIconTemplateV2,
  UploadIcon,
} from "@share/icons";
import { Action } from "antd-mobile/es/components/popover";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import { saveSite } from "@share/thunk";
import {
  sortBlock,
  disableEnableBlock,
  deleteBlock,
} from "@features/main/store/positionsSlice";
import {
  ACTION_BLOCK,
  ENABLE_BLOCK,
  DISABLE_BLOCK,
  POSITION_MAIN_LAYOUT,
  KEY_BLOCK,
  IS_DISPLAY_IMPORT_PRODUCT_FILE,
} from "@features/share/configs/const";
import { closePopup } from "@features/block/store/edittingBlockSlice";
import gaEvents, {
  ACTION_CONFIG,
  BLOCK_TYPE_EVENT_CONFIG,
  CATEGORY_CONFIG,
} from "@share/lib/ga-events";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { handleCapitalizeFirstLetter } from "@share/lib";
import Portal from "@share/HOC/portal";
import { useDebounce } from "usehooks-ts";
import {
  AFFILIATE_SETTING_TOUR_CLASS_NAME,
  STEP_INDEX,
} from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductTourOpening/ProductSettingTour/setting-tour-config";
import { useBlockEditV2Context } from "@features/block/context/context";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import { useSelector } from "react-redux";
import { selectProfile } from "@features/user/userSlice";
import { useRouter } from "next/router";
import { LinkAltIcon } from "@share/icons/line-icon";

interface Props {
  isEnable?: number;
  positionSlug?: string;
  blockUid?: string;
  tabUid?: string;
  sort_order?: number;
  listBlocksSortOrder?: any;
  handeSetEnableBlock?: any;
  type?: string;
  blockType?: string;
  visible?: boolean;
  index?: any;
  dataBlock?: any;
  KEY?: string;
}

const MoreElement = (props: Props) => {
  const {
    isEnable,
    positionSlug,
    blockUid,
    tabUid,
    sort_order,
    handeSetEnableBlock,
    type,
    blockType,
    visible,
    dataBlock,
    KEY,
  } = props;
  const {
    handleOpenTemplatePopup,
    handleOpenAndHidePopupAddNewTemplate,
    isShowModalHandleLogicTemplate,
    handleLogicShowModalChooseOptionTemplate,
  } = useBlockEditV2Context();

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creator_role = passioPageProfile?.creator_role;
  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );
  const router = useRouter();
  const positions = useAppSelector((state) => state.positions);
  const positionState = positions.find((x) => x.slug === positionSlug);
  let listBlocks = positionState?.block_list;

  if (positionSlug === POSITION_MAIN_LAYOUT.BODY) {
    const block_list = listBlocks[0].content_attributes.tabs.find(
      (x) => x.uid === tabUid
    );
    listBlocks = block_list.block_list;
  }

  const CONFIRM_TEXT = "OK";
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const popoverContainerRef = useRef(null);
  const popoverOptionRef = useRef(null);
  const [isOpenConfirmEnterText, setIsOpenConfirmEnterText] = useState(false);
  const [valueConfirmText, setValueConfirmText] = useState(null);
  const debouncedValueConfirmText = useDebounce(valueConfirmText, 500);
  const handleCompareConfirmText = (val) => {
    setValueConfirmText(val);
  };
  const handleCloseModalConfirmText = () => {
    setIsOpenConfirmEnterText(false);
    setValueConfirmText(null);
  };
  const isEnableConfirm = useMemo(
    () =>
      !(
        typeof debouncedValueConfirmText === "string" &&
        debouncedValueConfirmText.toLowerCase() === CONFIRM_TEXT.toLowerCase()
      ),
    [debouncedValueConfirmText]
  );
  const actions: Action[] = [
    {
      key: ACTION_BLOCK.VISIBLE_BLOCK,
      icon:
        isEnable === ENABLE_BLOCK ? (
          <EyeVisibleIcon
            className="fill-white"
            width={20}
            height={20}
          />
        ) : (
          <EyeHiddenIcon
            className="fill-white"
            width={20}
            height={20}
          />
        ),
      text:
        isEnable === ENABLE_BLOCK
          ? t("ss_builder_hide_block_label")
          : t("ss_builder_show_block_label"),
    },
    {
      key: ACTION_BLOCK.DELETE_BLOCK,
      icon: (
        <DeleteIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_delete_block_label"),
    },
  ];
  const handleClickMoreIcon = (event) => {
    event.preventDefault();
  };

  const blockTypeEvent =
    BLOCK_TYPE_EVENT_CONFIG[blockType?.toUpperCase()] ||
    handleCapitalizeFirstLetter(blockType);

  const handleEnableDisableBlock = () => {
    if (isEnable === ENABLE_BLOCK) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BLOCK,
        `Block_Hide_${blockTypeEvent}`,
        blockTypeEvent,
        ACTION_CONFIG.HIDE
      );
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BLOCK,
        `Block_Show_${blockTypeEvent}`,
        blockTypeEvent,
        ACTION_CONFIG.SHOW
      );
    }
    const blockStatus =
      isEnable === ENABLE_BLOCK ? DISABLE_BLOCK : ENABLE_BLOCK;
    onDisableEnableBlock(positionSlug, blockUid, blockStatus);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {isEnable === ENABLE_BLOCK
            ? t("ss_builder_disable_block_successfully_label")
            : t("ss_builder_enable_block_successfully_label")}
        </div>
      ),
    });
  };

  const onRemoveItem = (sort_order: number) => {
    const deletedBlock = listBlocks.find(
      (ele) => ele.sort_order === sort_order
    );

    dispatch(deleteBlock({ deletedBlock, positionSlug, tabUid }));
    dispatch(closePopup());
  };
  const confirmRemoveBlock = () => {
    const block = listBlocks?.find((block) => block.uid === blockUid);
    const isAffProduct =
      block.block_type === BLOCK_TYPE.AFF_PRODUCT_GROUP ||
      block.block_type === BLOCK_TYPE.AFF_PRODUCT_GROUP;
    Modal.confirm({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_block_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center whitespace-normal">
          {/*{t("ss_builder_confirm-delete_block_label")}*/}
          {isAffProduct ? (
            <span className="break-words">
              {t("ss_builder_if_delete")}
              <span className="text-[#ee3244ff]">
                {" "}
                {t("ss_builder_all_setting_and_product")}{" "}
              </span>
              {t("ss_builder_in_block_delete_forever_are_you_sure")}
            </span>
          ) : (
            t("ss_builder_confirm-delete_block_label")
          )}
        </div>
      ),
      confirmText: t("ss_builder_confirm_delete_block_btn_label"),
      cancelText: t("ss_builder_cancel-delete_block_label"),
      onConfirm: () => {
        if (
          blockType === BLOCK_TYPE.AFF_PRODUCT ||
          blockType === BLOCK_TYPE.AFF_PRODUCT_GROUP
        ) {
          setIsOpenConfirmEnterText(true);
        } else {
          onRemoveItem(sort_order);
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.BLOCK,
            `Block_Delete_${blockTypeEvent}`,
            blockTypeEvent,
            ACTION_CONFIG.DELETE
          );
          Toast.show({
            icon: (
              <CheckCircleIcon
                className="text-center inline-block w-[30px] h-[30px]"
                fillColor="#00B578"
              />
            ),
            content: (
              <div className="text-center">
                {t("ss_builder_delete_block_successfully_label")}
              </div>
            ),
          });
        }
      },
      onCancel: () => {
        return;
      },
    });
  };

  const handleTemplate = () => {
    handleLogicShowModalChooseOptionTemplate(true);
    // handleOpenTemplatePopup();
  };
  const handleRemoveItem = () => {
    onRemoveItem(sort_order);
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.BLOCK,
      `Block_Delete_${blockTypeEvent}`,
      blockTypeEvent,
      ACTION_CONFIG.DELETE
    );
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {t("ss_builder_delete_block_successfully_label")}
        </div>
      ),
    });
  };
  const handleClickPopoverItem = (item: Action) => {
    const key = item?.key;
    switch (key) {
      case ACTION_BLOCK.VISIBLE_BLOCK:
        handleEnableDisableBlock();
        handeSetEnableBlock();
        break;
      case ACTION_BLOCK.DELETE_BLOCK:
        confirmRemoveBlock();
        break;
      case ACTION_BLOCK.UPLOAD_AFFILIATE_PRODUCT:
        handleUploadProductFile();
        break;
      case ACTION_BLOCK.IMPORT_SITE_URL:
        handleImportSiteUrl();
        break;
      default:
    }
  };

  const handleClickPopoverItemV2 = (item: Action) => {
    const key = item?.key;
    switch (key) {
      case ACTION_BLOCK.VISIBLE_BLOCK:
        handleEnableDisableBlock();
        handeSetEnableBlock();
        break;
      case ACTION_BLOCK.DELETE_BLOCK:
        confirmRemoveBlock();
        break;
      case ACTION_BLOCK.HANDLE_TEMPLATE:
        handleTemplate();
        break;
      default:
    }
  };

  const onDisableEnableBlock = (
    positionSlug: string,
    blockUid: string,
    blockStatus: number
  ) => {
    dispatch(
      disableEnableBlock({
        positionSlug,
        blockUid,
        blockStatus,
        tabUid,
      })
    );
    const isAutoSave = true;

    dispatch(saveSite(isAutoSave));
    dispatch(
      setDynamicParams({
        data: {
          isSaving: true,
        },
      })
    );
  };
  let actionMoreElement = actions;

  if (blockType === BLOCK_TYPE.TAB || blockType === BLOCK_TYPE.TAB_BAR) {
    actionMoreElement = actions.filter(
      (actions) => actions.key !== ACTION_BLOCK.DELETE_BLOCK
    );
  }
  if (blockType === BLOCK_TYPE.COMBINED && isAdminRole) {
    actionMoreElement = [
      ...(KEY !== KEY_BLOCK.ADD_NEW ? actions : []),
      {
        key: ACTION_BLOCK.HANDLE_TEMPLATE,
        icon: (
          <AddIconTemplateV2
            className="fill-white"
            width={20}
            height={20}
            fillColor="#fff"
          />
        ),
        text: t("ss_builder_add_new_template_label"),
        disabled: !dataBlock?.content_attributes?.blocks?.length,
      },
    ];
  }

  if (
    blockType === BLOCK_TYPE.AFF_PRODUCT_GROUP &&
    Number(router?.query?.import) === IS_DISPLAY_IMPORT_PRODUCT_FILE
  ) {
    actionMoreElement = [
      ...(KEY !== KEY_BLOCK.ADD_NEW ? actions : []),
      {
        key: ACTION_BLOCK.UPLOAD_AFFILIATE_PRODUCT,
        icon: (
          <UploadIcon
            className="w-[17px] h-[17px]"
            fillColor="white"
          />
        ),
        text: t("ss_builder_affiliate_upload_product_label"),
      },
      {
        key: ACTION_BLOCK.IMPORT_SITE_URL,
        icon: (
          <LinkAltIcon
            className="w-[17px] h-[17px]"
            fillColor="white"
          />
        ),
        text: t("ss_builder_import_site_url_label"),
      },
    ];
  }

  const handleUploadProductFile = () => {
    dispatch(
      setDynamicParams({
        data: { isUploadProductFile: true },
      })
    );
  };

  const handleImportSiteUrl = () => {
    dispatch(
      setDynamicParams({
        data: { isUploadProductFromSiteUrl: true },
      })
    );
  };

  return (
    <div
      className="flex items-center justify-center 
    w-[32px] h-[32px]  cursor-pointer"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderRadius: "4px",
      }}
      onClick={handleClickMoreIcon}
    >
      <div
        className="relative"
        ref={popoverContainerRef}
      >
        <Popover.Menu
          className={cls(
            "popover-block",
            blockType === BLOCK_TYPE.AFF_PRODUCT_GROUP &&
              AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_1
          )}
          mode="dark"
          actions={actionMoreElement}
          placement="bottom-end"
          trigger="click"
          stopPropagation={["click"]}
          getContainer={() => popoverContainerRef.current}
          onAction={(item: Action) =>
            blockType === BLOCK_TYPE.COMBINED && isAdminRole
              ? handleClickPopoverItemV2(item)
              : handleClickPopoverItem(item)
          }
          {...(visible ? { visible: true } : {})}
          ref={popoverOptionRef}
        >
          <MoreIcon className="fill-black" />
        </Popover.Menu>
      </div>
      {isOpenConfirmEnterText && (
        <Modal
          visible={isOpenConfirmEnterText}
          content={
            <>
              <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center  whitespace-normal">
                {t("ss_builder_confirm_delete_aff_block_content")}
              </div>
              <div className="mt-3 mb-1 text-[#666]">
                {t("ss_builder_confirm_delete_aff_block_label_enter_ok")}
              </div>
              <Input
                placeholder={t(
                  "ss_builder_confirm_delete_aff_block_input_placeholder"
                )}
                onChange={(val) => {
                  handleCompareConfirmText(val);
                }}
                className="input-rounded"
                maxLength={20}
                minLength={1}
              />
            </>
          }
          closeOnAction
          onClose={() => {
            handleCloseModalConfirmText();
          }}
          className="confirm-text-preview-apply-content"
          header={
            <CloseCircleIconV2
              width={48}
              height={48}
              fillColor="#FF3141"
            />
          }
          title={<div>{t("ss_builder_confirm_delete_aff_block_warining")}</div>}
          actions={[
            {
              key: "confirm",
              text: t("ss_builder_confirm_delete_aff_block_understood"),
              className: "confirm-text",
              primary: true,
              disabled: isEnableConfirm,
              onClick: () => handleRemoveItem(),
            },
            {
              key: "cancel",
              text: t("ss_builder_cancel_delete_aff_block_btn_label"),
              className: "cancel-text",
            },
          ]}
        />
      )}
      {isShowModalHandleLogicTemplate && (
        <Modal
          visible={isShowModalHandleLogicTemplate}
          content={<div>{t("ss_builder_choose_a_prototying_method")}</div>}
          closeOnAction
          onClose={() => {
            handleCloseModalConfirmText();
          }}
          bodyClassName="theme-template-selection-modal"
          header={
            <InformationTriangleIcon
              className="w-11 h-11"
              fillColor="#FF8F1F"
              onClick={() => {}}
            />
          }
          title={<div>{t("ss_builder_create_template")}</div>}
          actions={[
            {
              key: "confirm",
              text: t("ss_builder_add_new_theme_btn_label"),
              className: "create-text-template",
              primary: true,
              onClick: () => handleOpenAndHidePopupAddNewTemplate(true),
              style: {
                padding: "7px 0",
              },
            },
            {
              key: "confirm",
              text: t("ss_builder_overwrite_theme_btn_label"),
              className: "overwrite-text-template",
              primary: true,
              onClick: () => handleOpenTemplatePopup(),
              style: {
                padding: "7px 0",
              },
            },
            {
              key: "cancel",
              text: t("ss_builder_cancel_delete_aff_block_btn_label"),
              className: "cancel-text-template",
              onClick: () => handleLogicShowModalChooseOptionTemplate(false),
              style: {
                padding: "7px 0",
              },
            },
          ]}
        />
      )}
    </div>
  );
};

export default MoreElement;
