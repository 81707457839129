import React, { useEffect, useMemo, useState } from "react";
import FullScreenPopup from "@share/components/full-screen-popup";
import {
  STATUS,
  TEMPLATE_KEY,
  TEMPLATE_RENDER_LIST_KEY,
} from "@share/configs/const";
import {
  Button,
  NavBar,
  SearchBar,
  Modal,
  Divider,
  SpinLoading,
} from "antd-mobile";
import {
  InformationTriangleIcon,
  SearchIcon,
  TemplateIcon,
} from "@share/icons";
import { useTranslation } from "react-i18next";
import TemplateList from "./TemplateList";
import {
  requestDeleteTemplate,
  requestSearchTemplate,
  requestUpdateTemplate,
} from "@share/api";
import { useAppSelector } from "@app/hooks";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import cloneDeep from "lodash/cloneDeep";
import TemplateSaving from "@features/block/components/TemplateComponent/TemplateSaving";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { useToast } from "@share/hooks/toast";

const TemplateOverWriteComponent = (props) => {
  const {
    isOpenTemplate,
    handleClose,
    KEY,
    dataOverWrite,
    handleLogicShowModalChooseOptionTemplate,
  } = props;
  const { t } = useTranslation();
  const { SuccessToast } = useToast();
  const [templateData, setTemplateData] = useState(null);
  const [isAddNewTemplate, setIsAddNewTemplate] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [searchedTemplate, setSearchedTemplate] = useState([]);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creator_role = passioPageProfile?.creator_role;
  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );

  useEffect(() => {
    const requestTemplateList = async () => {
      let listTemplateResponse = null;
      const dataRequest = {
        per_page: 100,
        keyword: "",
        is_admin: isAdminRole,
        block_type: BLOCK_TYPE.COMBINED,
      };
      try {
        listTemplateResponse = await requestSearchTemplate(dataRequest);
      } catch (error) {
        console.log(error);
      }
      const listTemplateArr = listTemplateResponse?.data;
      setIsLoading(false);
      if (listTemplateArr) setTemplateData(listTemplateArr);
    };
    if (isOpenTemplate) requestTemplateList();
  }, [isAdminRole, isOpenTemplate]);

  const handleDeleteTemplate = async (id) => {
    let response = null;
    try {
      response = await requestDeleteTemplate(id);
    } catch (error) {
      console.log(error);
    }
    if (
      /^20[0-9]$/.test(response?.status.toString()) &&
      response?.data?.success
    ) {
      const templateArrClone = cloneDeep(templateData);
      const newTemplateArr = templateArrClone?.filter((ele) => ele.id !== id);
      setTemplateData(newTemplateArr);
      SuccessToast(t("ss_builder_delete_template_successfully_label"));
    }
  };

  const DataTemplateOverWrite = useMemo(() => {
    return {
      ...templateSelected,
      block_space: dataOverWrite?.block_space,
      block_data: dataOverWrite?.block_data,
      is_using_global_block_settings:
        dataOverWrite?.is_using_global_block_settings,
      advanced_settings: dataOverWrite?.advanced_settings,
    };
  }, [dataOverWrite, templateSelected]);

  const handleEditTemplate = (data) => {
    setTemplateSelected(data);
    setIsAddNewTemplate(true);
  };

  const handleSelectedTemplate = (dataTemplateSelected) => {
    setTemplateSelected(dataTemplateSelected);
  };

  const handleShowHide = async (data) => {
    const isShowTemplate = data?.status === STATUS.ENABLE;
    const newData = {
      ...data,
      status: isShowTemplate ? STATUS.DISABLE : STATUS.ENABLE,
    };
    let response = null;
    try {
      response = await requestUpdateTemplate(newData);
    } catch (error) {
      console.log(error);
    }
    if (
      /^20[0-9]$/.test(response?.status.toString()) &&
      response?.data?.success
    ) {
      const templateArrClone = cloneDeep(templateData);
      const newTemplateArr = templateArrClone?.map((ele) => {
        if (ele.id === newData.id)
          return {
            ...ele,
            status: isShowTemplate ? STATUS.DISABLE : STATUS.ENABLE,
          };
        return ele;
      });
      setTemplateData(newTemplateArr);
      SuccessToast(
        isShowTemplate
          ? t("ss_builder_hide_template_successfully_label")
          : t("ss_builder_show_template_successfully_label")
      );
    }
  };

  const triggerModalConfirm = (value) => {
    setIsShowModalConfirm(value);
  };

  const searchStringTransformed = (str: string): Array<string> =>
    str
      .toLowerCase()
      .split(/[ ,]+/)
      .filter((e) => !!e);

  const getTagsAndTitleList = (item) => {
    const tags = [...item.tags];
    const name = [item.title];
    return [...tags, ...name];
  };

  const isMatchSearchKey = (template, searchKey) => {
    const tagsAndTitleArr = getTagsAndTitleList(template);
    const isMatch = tagsAndTitleArr.some((tag) => {
      const splitedTagsArr = searchStringTransformed(tag);
      const isTagIncludeAllKey = searchKey.every((key) =>
        splitedTagsArr.includes(key)
      );
      return isTagIncludeAllKey;
    });
    return isMatch;
  };

  const handleSearchTemplate = (value) => {
    const searchKeys = searchStringTransformed(value);
    const allTemplate = [...templateData];

    if (searchKeys.length > 0) {
      const searchedTemplate = [];
      allTemplate.forEach((template) => {
        if (isMatchSearchKey(template, searchKeys)) {
          searchedTemplate.push(template);
        }
      });
      setSearchedTemplate(searchedTemplate);
      setIsSearchMode(true);
    } else {
      setSearchedTemplate(templateData);
      setIsSearchMode(false);
    }
  };
  const onUpdateListTemplate = (newListTemplate) => {
    setTemplateData(newListTemplate);
  };

  return (
    <>
      <FullScreenPopup
        visible={isOpenTemplate}
        onMaskClick={() => handleClose()}
      >
        <div className="bg-white h-full">
          <NavBar
            backArrow={null}
            // onBack={handleClose}
            className="w-full"
          >
            <div className="flex flex-row text-center items-center justify-center gap-[4px] text-[13px]">
              <TemplateIcon />
              <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[70vw] md:max-w-[20vw] text-titleFontSize">
                {t("ss_builder_template_overwrite_title") ?? "Template"}
              </div>
            </div>
          </NavBar>
          <div className="flex w-full bg-white search-block h-[36px] px-4">
            <SearchBar
              icon={() => null}
              placeholder={t("ss_builder_template_search_bar_placeholder")}
              onSearch={handleSearchTemplate}
              onChange={(val) => setValue(val)}
              style={{
                width: "100%",
              }}
              onClear={() => setIsSearchMode(false)}
            />
            <div
              className="bg-[#EE3244] flex h-43 w-43 cursor-pointer"
              style={{
                width: "43px",
                height: "36px",
                borderRadius: "0 6px 6px 0",
              }}
              onClick={() => handleSearchTemplate(value)}
            >
              <SearchIcon className="m-auto" />
            </div>
          </div>
          <Divider style={{ margin: "12px 0 0" }} />
          {isSearchMode && (
            <div className="p-4 pb-0">
              <div className="text-lg leading-6 mb-1 font-normal">
                {t("ss_builder_search_results_theme_store")}
              </div>
            </div>
          )}
          {isLoading ? (
            <div
              className="absolute"
              style={{ left: "calc(50% - 16px)", top: "calc(50% - 16px)" }}
            >
              <SpinLoading
                color="primary"
                style={{ "--size": "32px" }}
              />
            </div>
          ) : (
            <TemplateList
              onSelectTemplate={(data) => handleSelectedTemplate(data)}
              onEditTemplate={(data) => handleEditTemplate(data)}
              onDeleteTemplate={(id) => handleDeleteTemplate(id)}
              onShowHideTemplate={(data) => handleShowHide(data)}
              KEY={TEMPLATE_RENDER_LIST_KEY.TEMPLATE_OVERWRITE_SELECTION}
              templateData={isSearchMode ? searchedTemplate : templateData}
            />
          )}
          <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
            <div className="w-1/2 text-center">
              <Button
                className="w-full"
                onClick={handleClose}
              >
                {t("ss_builder_editor_btn-cancel")}
              </Button>
            </div>
            <div className="w-1/2 text-center">
              <Button
                className="w-full"
                color={"primary"}
                onClick={() => triggerModalConfirm(true)}
                disabled={!templateSelected}
              >
                {t("ss_builder_overwrite_theme_btn_label")}
              </Button>
            </div>
          </div>
        </div>
        {isShowModalConfirm && (
          <Modal
            visible={isShowModalConfirm}
            content={
              <div className="text-center">
                {t("ss_builder_overwrite_description")}
              </div>
            }
            closeOnAction
            onClose={() => {
              triggerModalConfirm(false);
            }}
            header={
              <InformationTriangleIcon
                className="w-11 h-11"
                fillColor="#FF8F1F"
                onClick={() => {}}
              />
            }
            title={<div>{t("ss_builder_overwrite_title")}</div>}
            actions={[
              {
                key: "confirm",
                text: t("ss_builder_confirm_overwrite"),
                className: "create-text-template",
                primary: true,
                onClick: () => setIsAddNewTemplate(true),
              },
              {
                key: "cancel",
                text: t("ss_builder_editor_btn-cancel"),
                className: "cancel-text-template",
                onClick: () => triggerModalConfirm(false),
              },
            ]}
          />
        )}
      </FullScreenPopup>
      <TemplateSaving
        visible={isAddNewTemplate}
        onMaskClick={() => setIsAddNewTemplate(false)}
        KEY={TEMPLATE_KEY.OVERWRITE}
        templateDataSaving={DataTemplateOverWrite}
        handleCancelTemplateSaving={() => setIsAddNewTemplate(false)}
        handleLogicShowModalChooseOptionTemplate={
          handleLogicShowModalChooseOptionTemplate
        }
        onUpdateListTemplate={onUpdateListTemplate}
        handleCloseOverwriteList={handleClose}
        // handleConfirmTemplateSaving={(dataTemplate) =>
        //     handleSaveTemplate(dataTemplate)
        // }
      />
    </>
  );
};
export default TemplateOverWriteComponent;
