import {
  LINK_LAYOUTS,
  LINK_STYLE_DISPLAY,
  LINK_TEMPLATE,
} from "@share/configs/const";
import IconRender from "@share/IconRender";
import { Button, Image } from "antd-mobile";
import React, { useMemo } from "react";
import { ContentAttributesInterface, StyleAttributesInterface } from "..";
import style from "../style.module.scss";

type Props = {
  style_attributes: StyleAttributesInterface;
  content_attributes: ContentAttributesInterface;
};

const TextRightLeftLayout = (props: Props) => {
  const { style_attributes, content_attributes } = props;

  const {
    button_content,
    button_icon,
    use_aff_url,
    target_url,
    origin_url,
    button_description,
    image,
  } = content_attributes || {};

  const {
    button_size,
    button_link_template,
    button_link_layout,
    button_link_style,
  } = style_attributes || {};

  const buttonSizeClass = useMemo(() => {
    let valueClass = null;
    switch (button_size) {
      case "small":
        valueClass = "small-button";
        break;
      case "middle":
        valueClass = "middle-button";
        break;
      case "large":
        valueClass = "large-button";
        break;
    }
    return valueClass;
  }, [button_size]);

  const isShowIconRight = useMemo(
    () =>
      button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_ICON &&
      button_icon &&
      button_link_layout === LINK_LAYOUTS.TEXT_RIGHT,
    [button_link_style, button_icon, button_link_layout]
  );

  const isShowImageRight = useMemo(
    () =>
      button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE &&
      image &&
      button_link_layout === LINK_LAYOUTS.TEXT_RIGHT,
    [button_link_style, image, button_link_layout]
  );

  const isShowIconLeft = useMemo(
    () =>
      button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_ICON &&
      button_icon &&
      button_link_layout === LINK_LAYOUTS.TEXT_LEFT,
    [button_link_style, button_icon, button_link_layout]
  );

  const isShowImageLeft = useMemo(
    () =>
      button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE &&
      image &&
      button_link_layout === LINK_LAYOUTS.TEXT_LEFT,
    [button_link_style, image, button_link_layout]
  );

  const styleButtonLink = useMemo(() => {
    let styleObj = {};
    switch (button_link_template) {
      case LINK_TEMPLATE.TEMPLATE1:
        styleObj = {
          "--background-color": "#FFFFFF",
          "--border-color": "#333333",
        };
        break;
      case LINK_TEMPLATE.TEMPLATE2:
        styleObj = {
          "--background-color": "var(--button-bg-color-6)",
          "--border-color": "var(--button-border-color-6)",
        };
        break;
      case LINK_TEMPLATE.TEMPLATE3:
        styleObj = {
          "--background-color": "var(--button-bg-color-6)",
          "--border-color": "var(--button-border-color-6)",
          boxShadow: "4px 5px 0px #000000",
        };
        break;
    }
    return styleObj;
  }, [button_link_template]);

  const buttonClass = useMemo(() => {
    switch (button_link_template) {
      case LINK_TEMPLATE.TEMPLATE1:
        return style.template1;
      case LINK_TEMPLATE.TEMPLATE2:
        return style.template2;
      case LINK_TEMPLATE.TEMPLATE3:
        return style.template3;
    }
    return "";
  }, [button_link_template]);

  const imageSizeValue = useMemo(() => {
    let valueSize = null;
    switch (button_size) {
      case "small":
        valueSize = 38;
        break;
      case "middle":
        valueSize = 60;
        break;
      case "large":
        valueSize = 72;
        break;
    }
    return valueSize;
  }, [button_size]);

  return (
    <div>
      <Button
        style={{
          "--text-color": "unset",
          // ...styleButtonLink,
        }}
        size={button_size}
        block
        className={`${buttonSizeClass} ${style.buttonStyle} button-border-radius button-border-width button-border-style button-link-wrapper ${button_link_layout} ${buttonClass}`}
      >
        <div className="flex flex-row gap-2 items-center  justify-center">
          {isShowIconRight && (
            <IconRender
              iconName={button_icon}
              fillColor={
                button_link_template === LINK_TEMPLATE.TEMPLATE1
                  ? "var(--heading-color-6)"
                  : "var(--button-text-color)"
              }
            />
          )}
          {isShowImageRight && (
            <div>
              <Image
                fit="cover"
                src={image}
                width={imageSizeValue}
                height={imageSizeValue}
                className="rounded"
              />
            </div>
          )}
          {(button_content || button_description) && (
            <div className="flex flex-col gap-1 text-center grow break-words">
              {button_content && (
                <div
                  className="button-link-content"
                  style={{
                    color:
                      button_link_template === LINK_TEMPLATE.TEMPLATE1
                        ? "var(--heading-color-6)"
                        : "var(--button-text-color)",
                  }}
                >
                  {button_content}
                </div>
              )}
              {button_description && (
                <div
                  className="button-link-description small"
                  style={{
                    color:
                      button_link_template === LINK_TEMPLATE.TEMPLATE1
                        ? "var(--heading-color-5)"
                        : "var(--button-text-color)",
                  }}
                >
                  {button_description}
                </div>
              )}
            </div>
          )}
          {isShowIconLeft && (
            <IconRender
              iconName={button_icon}
              fillColor={
                button_link_template === LINK_TEMPLATE.TEMPLATE1
                  ? "var(--heading-color-6)"
                  : "var(--button-text-color)"
              }
            />
          )}
          {isShowImageLeft && (
            <div>
              <Image
                fit="cover"
                src={image}
                width={imageSizeValue}
                height={imageSizeValue}
                className="rounded"
              />
            </div>
          )}
        </div>
      </Button>
      <div
        className="absolute top-0 left-0 block w-full h-full rounded-lg"
        style={{
          backgroundColor: "transparent",
        }}
      ></div>
    </div>
  );
};

export default TextRightLeftLayout;
