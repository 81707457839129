import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Pagination, EffectFade, Lazy } from "swiper";
import { ImageGalleryInterface } from "@features/main/store/interface";
import cls from "classnames";
type Props = {
  imageGalleryItemArr: ImageGalleryInterface[];
  style_attributes: any;
};

const ImageGalleryPartialVisibleRight = (props: Props) => {
  const { imageGalleryItemArr, style_attributes } = props;
  const { number_items_per_row, ratio } = style_attributes;

  return (
    <div>
      <Swiper
        modules={[Pagination, EffectFade, Lazy]}
        spaceBetween={4}
        slidesPerView={
          number_items_per_row ? parseInt(number_items_per_row) : 1
        }
        pagination={{
          clickable: true,
        }}
        lazy={true}
        effect={"slide"}
        className={cls(`image-gallery-wrapper`)}
      >
        {imageGalleryItemArr &&
          imageGalleryItemArr.map((ele, index) => (
            <SwiperSlide>
              <div className="mb-5">
                <img
                  src={ele.image}
                  className="object-center image-gallery-image"
                  style={{
                    backgroundSize: "cover",
                    aspectRatio: ratio,
                    margin: "0 auto",
                    objectFit: "cover",
                    width: "100%",
                  }}
                  alt=""
                />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default ImageGalleryPartialVisibleRight;
