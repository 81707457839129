import { BLOCK_FILTER_GROUP_TYPE } from "@share/configs/const";
import { Button } from "antd-mobile";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { EarnMoneyIcon } from "../../icons";

const CategoryItemDetail = (props: {
  item: any;
  onSelect: (HTMLSpanElement) => void;
  selected: boolean;
  listItemsDefault: any;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLSpanElement>(null);
  const { item, onSelect, selected } = props;

  const Icon = useMemo(() => {
    let icon = null;
    const type = item?.value;
    switch (type) {
      case BLOCK_FILTER_GROUP_TYPE.MONETIZATION:
        icon = <EarnMoneyIcon />;
        break;
    }
    return icon;
  }, [item.value]);

  return (
    <>
      <span ref={ref}>
        <Button
          style={{
            whiteSpace: "nowrap",
            background: selected ? "#FFFFFF" : "#F5F5F5",
            border: selected ? "1px solid #EE3244" : "none",
            borderRadius: "99px",
            height: "32px",
            padding: "5px 16px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(ref.current);
          }}
          block
          shape="rounded"
        >
          <span className="flex flex-row gap-1 text-center items-center">
            {Icon}
            <h6
              style={{
                color: selected ? "#EE3244" : "#333333",
                lineHeight: "22px",
              }}
            >
              {t(item.label)}
            </h6>
          </span>
        </Button>
      </span>
    </>
  );
};

export default CategoryItemDetail;
