import { Block } from "@features/main/store/interface";
import { PreviewThemeIcon } from "@share/icons";
import React, { useState } from "react";
import PreviewFullscreen from "./AdvancedSetting/PreviewFullscreen";

type Props = {
  blockData: Block;
};

const PreviewSetting = ({ blockData }: Props) => {
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const { block_title, block_description, blocks } =
    blockData?.content_attributes || {};
  const blockSpace = blockData?.style_attributes?.block_space || 12;
  const advancedSettingData =
    blockData?.style_attributes?.advanced_setting || {};
  const isApplyGlobalSettings =
    blockData?.style_attributes?.is_applied_block_global_settings || false;
  const enableBlocks = blocks?.filter((x) => x.enable === 1);

  return (
    <>
      <span
        onClick={() => setIsOpenPreview(true)}
        className="w-8 h-8 flex justify-center items-center cursor-pointer"
      >
        <PreviewThemeIcon className="w-6 h-6" />
      </span>
      <PreviewFullscreen
        isVisible={isOpenPreview}
        onCancel={() => setIsOpenPreview(false)}
        blockTitle={block_title}
        blockDescription={block_description}
        blockSpace={blockSpace}
        enableBlocks={enableBlocks}
        advancedSettingData={advancedSettingData}
        isApplyGlobalSettings={isApplyGlobalSettings}
        blockType={blockData?.block_type}
        blockUid={blockData?.uid}
      />
      
    </>
  );
};

export default PreviewSetting;
