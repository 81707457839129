import { SOCIAL_LINK } from "@share/configs/const";
import {
  BehanceIcon,
  DiscordIcon,
  DribbleIcon,
  EmailIcon,
  FacebookIcon,
  GeneralLinkIcon,
  InstagramIcon,
  Lemon8Icon,
  LineChatIcon,
  LinkedInIcon,
  MessengerIcon,
  PinterestIcon,
  SnapchatIcon,
  TelIcon,
  TiktokIcon,
  TwitchIcon,
  TwitterIcon,
  WhatsappIcon,
  YoutubeIcon,
} from "@share/icons/social-network-icon-svg";
import React from "react";

export const socialLinkIconRender = (data) => {
  const type = data.icon;
  let element = null;
  switch (type) {
    case SOCIAL_LINK.FACEBOOK:
      element = <FacebookIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.MESSENGER:
      element = <MessengerIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.INSTAGRAM:
      element = <InstagramIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.LINKEDIN:
      element = <LinkedInIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.SNAPCHAT:
      element = <SnapchatIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.LINECHAT:
      element = <LineChatIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.YOUTUBE:
      element = <YoutubeIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.TIKTOK:
      element = <TiktokIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.TWITCH:
      element = <TwitchIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.TWITTER:
      element = <TwitterIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.WHATSAPP:
      element = <WhatsappIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.DISCORD:
      element = <DiscordIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.BEHANCE:
      element = <BehanceIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.DRIBBLE:
      element = <DribbleIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.PINTEREST:
      element = <PinterestIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.EMAIL:
      element = <EmailIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.TEL:
      element = <TelIcon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.LEMON8:
      element = <Lemon8Icon className="w-8 h-8 rounded-full" />;
      break;
    case SOCIAL_LINK.OTHER:
      element = <GeneralLinkIcon className="w-8 h-8 rounded-full" />;
      break;
    default:
      element = <GeneralLinkIcon className="w-8 h-8 rounded-full" />;
  }
  return element;
};
