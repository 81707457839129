import { useAppDispatch, useAppSelector } from "@app/hooks";
import AffiliateProductGroupAddNew from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductGroupManagement/AffiliateProductGroupAddNew";
import ProductListLayoutHandler from "@features/block/block-editor/AffiliateProductGroup/ProductListLayoutHandler";
import { requestPostLinkMiddlewareV2 } from "@features/block/block.conf";
import { updateBlock } from "@features/main/store/positionsSlice";
import ButtonSavingGroup from "@share/components/ButtonSavingGroup";
import PopupComponent from "@share/components/PopupComponent";
import { ModalIconConfig, useModal } from "@share/hooks/modal";
import { useToast } from "@share/hooks/toast";
import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import HeaderComponent from "./HeaderComponent";
import { setDynamicParams } from "@features/main/store/dynamicSlice";

const ProductGroupQuickEdit = (props) => {
  const {
    affiliateProductGroupData,
    activeProductGroup,
    productGroupMaximum,
    onCancelProductGroupQuickEdit,
    blockData,
    positionSlug,
    tabUid,
    isCreatedNew,
    onGetLatestBlockData = () => {},
  } = props;

  const { t } = useTranslation();

  const { SuccessToast } = useToast();
  const { ErrorModal, ConfirmModal } = useModal();

  const dispatch = useAppDispatch();
  const siteSettings = useAppSelector((state) => state.siteSettings);

  const extraData = {
    user_id: siteSettings.user_id,
  };

  const stickyRef = useRef<HTMLDivElement>(null);

  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [newProductGroupData, setNewProductGroupData] = useState(
    affiliateProductGroupData
  );
  const [isOpenGroupAddNew, setIsOpenGroupAddNew] = useState(false);

  const affiliateProductGroupListEnable = useMemo(
    () => newProductGroupData?.filter((x) => x.enable === 1),
    [newProductGroupData]
  );

  const activedProductGroupData = useMemo(
    () => newProductGroupData?.find((x) => x.uid === activeProductGroup),
    [newProductGroupData, activeProductGroup]
  );

  useEffect(() => {
    if (affiliateProductGroupData)
      setNewProductGroupData(affiliateProductGroupData);
  }, [affiliateProductGroupData]);

  useEffect(() => {
    const ref = stickyRef.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );
    if (ref) observer.observe(ref);
    return () => observer.unobserve(ref);
  }, [stickyRef]);

  const stickyTop = activedProductGroupData?.enable === 1 ? 45 : 82;

  const updateAndCancelGroup = (data) => {
    if (positionSlug) {
      dispatch(
        updateBlock({
          tabUid,
          positionSlug,
          blockData: data,
        })
      );
    }
    onGetLatestBlockData(data);
    onCancelProductGroupQuickEdit();
  };

  const handleSave = async () => {
    const newDataAffiliateProduct = {
      ...blockData,
      content_attributes: {
        ...blockData.content_attributes,
        items: newProductGroupData,
        is_created_new: isCreatedNew,
      },
    };
    try {
      const newData = await requestPostLinkMiddlewareV2(
        newDataAffiliateProduct,
        extraData
      );
      if (newData) {
        updateAndCancelGroup(newData);
      }
    } catch (e) {
      dispatch(
        setDynamicParams({
          data: { isErrorNetwork: true },
        })
      );
      return;
    }
  };

  const isDisableSaveBtn = useMemo(
    () =>
      !!(
        JSON.stringify(newProductGroupData) ===
        JSON.stringify(affiliateProductGroupData)
      ),
    [affiliateProductGroupData, newProductGroupData]
  );

  const handleRemoveGroup = () => {
    const newGroupData = newProductGroupData?.filter(
      (x) => x?.uid !== activedProductGroupData.uid
    );
    if (
      affiliateProductGroupListEnable.length <= 1 &&
      activedProductGroupData?.enable === 1
    ) {
      ErrorModal({
        title: t("ss_builder_delete_product_group_error_title"),
        icon: ModalIconConfig.INFO_WARNING_TRIANGLE,
        description: t("ss_builder_delete_product_group_error_content", {
          group_name:
            activedProductGroupData?.content_attributes?.title_product_group,
        }),
        confirmText: t(
          "ss_builder_delete_product_group_error_btn_confirm_label"
        ),
      });
    } else {
      const onConfirm = () => {
        const newDataAffiliateProduct = {
          ...blockData,
          content_attributes: {
            ...blockData.content_attributes,
            items: newGroupData,
            is_created_new: isCreatedNew,
          },
        };
        updateAndCancelGroup(newDataAffiliateProduct);
        SuccessToast(t("ss_builder_delete_product_group_successfully_label"));
      };
      ConfirmModal({
        title: t("ss_builder_confirm_delete_product_group_modal_title"),
        description: t(
          "ss_builder_confirm_delete_product_group_modal_description",
          {
            group_name:
              activedProductGroupData?.content_attributes?.title_product_group,
          }
        ),
        confirmText: t("ss_builder_confirm_delete_product_label"),
        cancelText: t("ss_builder_cancel_delete_product_label"),
        onConfirm,
      });
    }
  };

  const handleVisibleGroup = (status) => {
    const newGroupData = newProductGroupData?.map((ele) => {
      return ele?.uid === activedProductGroupData.uid
        ? {
            ...ele,
            enable: status,
          }
        : ele;
    });
    if (activedProductGroupData?.enable !== 1) {
      setNewProductGroupData(newGroupData);
      SuccessToast(t("ss_builder_enable_product_group_successfully_label"));
    } else {
      if (affiliateProductGroupListEnable.length >= 2) {
        const onConfirm = () => {
          setNewProductGroupData(newGroupData);
          SuccessToast(
            t("ss_builder_disable_product_group_successfully_label")
          );
        };
        ConfirmModal({
          title: t("ss_builder_confirm_hide_product_group_modal_title"),
          description: t(
            "ss_builder_confirm_hide_product_group_modal_description",
            {
              group_name:
                activedProductGroupData?.content_attributes
                  ?.title_product_group,
            }
          ),
          confirmText: t("ss_builder_confirm_hide_product_label"),
          cancelText: t("ss_builder_cancel_hide_product_label"),
          onConfirm,
        });
      } else {
        ErrorModal({
          title: t("ss_builder_hide_product_group_error_title"),
          description: t("ss_builder_hide_product_group_error_content", {
            group_name:
              activedProductGroupData?.content_attributes?.title_product_group,
          }),
          confirmText: t(
            "ss_builder_hide_product_group_error_btn_confirm_label"
          ),
        });
      }
    }
  };

  const handleAddNewProductGroup = (data) => {
    const newTitle =
      data?.content_attributes?.title_product_group || data?.title;
    const newGroupData = newProductGroupData?.map((ele) => {
      return ele?.uid === data?.uid
        ? {
            ...ele,
            title: newTitle,
            content_attributes: {
              ...ele.content_attributes,
              title_product_group: newTitle,
            },
          }
        : ele;
    });
    setNewProductGroupData(newGroupData);
    setIsOpenGroupAddNew(false);
    SuccessToast(t("ss_builder_change_name_product_group_successfully_label"));
  };

  return (
    <div className="relative">
      <HeaderComponent
        onCancel={onCancelProductGroupQuickEdit}
        title={activedProductGroupData?.content_attributes?.title_product_group}
        isEnable={activedProductGroupData?.enable}
        groupId={activedProductGroupData.uid}
        onRemoveGroup={handleRemoveGroup}
        onVisibleGroup={handleVisibleGroup}
        onOpenEditGroup={() => setIsOpenGroupAddNew(true)}
      />
      <div
        className="absolute w-full z-[9] top-0 left-0"
        ref={stickyRef}
        style={{
          height: `${stickyTop}px`,
        }}
      ></div>
      <div className="pb-[65px]">
        <ProductListLayoutHandler
          affiliateProductGroupData={newProductGroupData}
          activeProductGroup={activeProductGroup}
          onUpdateProductGroupData={(data) => setNewProductGroupData(data)}
          isSticky={isSticky}
          productGroupMaximum={productGroupMaximum}
          stickyTop={stickyTop}
        />
      </div>
      <ButtonSavingGroup
        onCancel={onCancelProductGroupQuickEdit}
        onSave={handleSave}
        saveLabel={t("ss_builder_editor_btn-save")}
        cancelLabel={t("ss_builder_editor_btn-cancel")}
        disabled={isDisableSaveBtn}
      />
      <PopupComponent
        visible={isOpenGroupAddNew}
        // onMaskClick={() => setIsOpenGroupAddNew(false)}
        title={t("ss_builder_add_new_affiliate_product_group_btn_label")}
        onClose={() => setIsOpenGroupAddNew(false)}
      >
        <AffiliateProductGroupAddNew
          onCancelAffiliateProductGroupAddNew={() =>
            setIsOpenGroupAddNew(false)
          }
          affiliateProductGroupDefault={activedProductGroupData}
          onSaveAffiliateProductGroupAddNew={handleAddNewProductGroup}
          affiliateProductGroupList={affiliateProductGroupData}
        />
      </PopupComponent>
    </div>
  );
};

export default ProductGroupQuickEdit;
