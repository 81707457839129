import React from "react";

const Avatar = (props) => {
  const {
    size,
    url,
    className = "",
    sizeBorder = 1,
    color = "rgba(0, 0, 0, 0.16)",
  } = props;
  const newUrl =
    url ||
    "https://image.passio.eco/page-builder/page-builder/images-default/bio-default.svg";
  return (
    <div
      className={className}
      style={
        {
          width: size,
          height: size,
          borderRadius: "100%",
          /* eslint-disable */
          WebkitBoxShadow: `inset 0px 0px 0px ${sizeBorder}px ${color}`,
          MozBoxShadow: `inset 0px 0px 0px ${sizeBorder}px ${color}`,
          boxShadow: `inset 0px 0px 0px ${sizeBorder}px ${color}`,
          background: `url("${newUrl}") center center / cover`,
        } as any
      }
    >
    </div>
  );
};
export default Avatar;
