import { Button } from "antd-mobile";
import React, { useEffect, useMemo, useRef } from "react";
import TabStyleDisplay from "../../TabStyleDisplay";
import cls from "classnames";
import { TAB_TEMPLATE } from "@share/configs/const";
import { useDoubleTap } from "use-double-tap";

const TemplateStyle1 = (props: {
  item: any;
  onSelect: (HTMLSpanElement) => void;
  selected: boolean;
  style_attributes: any;
  onDoubleTab: () => void;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const { item, onSelect, selected, style_attributes, onDoubleTab } = props;
  const tab_template =
    style_attributes?.tab_template || TAB_TEMPLATE.TEMPLATE_1;
    
  const bindDoubleTapToBlock = useDoubleTap(
    () => {
      onDoubleTab && onDoubleTab();
    },
    300,
    {
      onSingleTap: (e) => {
        e.stopPropagation();
        onSelect(ref.current);
      },
    }
  );

  useEffect(() => {
    if (selected)
      ref.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
        inline: "center",
      });
  }, [selected]);

  return (
    <span
      ref={ref}
      className="h-full"
    >
      <Button
        block
        size="middle"
        style={{
          whiteSpace: "nowrap",
          border: "none",
          color: selected
            ? "var(--adm-color-primary)"
            : "var(--heading-color-6)",
          borderBottom: selected
            ? "2px solid var(--adm-color-primary)"
            : "2px solid var(--block-tab-background-color)",
          borderRadius: "0px",
          opacity: item?.enable !== 1 ? "40%" : "",
          height: "100%",
          "--background-color": "none",
        }}
        {...bindDoubleTapToBlock}
        // onClick={(e) => {

        // }}
        className={cls(`${tab_template}`, selected && "tab-active-class")}
      >
        <TabStyleDisplay
          style_attributes={style_attributes}
          tab={item}
        />
      </Button>
    </span>
  );
};

export default TemplateStyle1;
