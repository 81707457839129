import ListItemScrollHorizontal from "@features/design/components/ListItemScrollHorizontal";
import React, { useEffect, useMemo, useState } from "react";
import TabTemplateStyle from "../../TabTemplateStyle";

const AutoTabWidth = (props) => {
  const {
    tabListBody,
    style_attributes,
    onUpdateTabActive,
    tabActiveDefault,
    onDoubleTab,
  } = props;

  const [activeTab, setActiveTab] = useState(tabActiveDefault || tabListBody[0].uid);

  useEffect(() => {
    if (tabActiveDefault) {
      setActiveTab(tabActiveDefault);
    }
  }, [tabActiveDefault]);

  useEffect(() => {
    if (activeTab) onUpdateTabActive(activeTab);
  }, [activeTab]);
  
  return (
    <div>
      <ListItemScrollHorizontal>
        {tabListBody?.map((item, index) => (
          <TabTemplateStyle
            key={index}
            item={item}
            onSelect={(ele) => {
              setActiveTab(item.uid);
              ele.scrollIntoView({
                block: "nearest",
                behavior: "smooth",
                inline: "center",
              });
            }}
            onDoubleTab={onDoubleTab}
            selected={activeTab === item.uid}
            style_attributes={style_attributes}
          />
        ))}
      </ListItemScrollHorizontal>
    </div>
  );
};

export default AutoTabWidth;
