import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Lazy } from "swiper";
import EffectPanorama from "@features/swiper/uiinitiative-panorama-slider/dist/effect-panorama.esm";

const ImageGalleryPanorama = (props) => {
  const { imageGalleryItemArr, style_attributes } = props;
  const { number_items_per_row, ratio } = style_attributes;

  return (
    <div>
      <Swiper
        modules={[EffectFade, EffectPanorama, Lazy]}
        spaceBetween={4}
        slidesPerView={
          number_items_per_row ? parseInt(number_items_per_row) : 1
        }
        effect="panorama"
        pagination={{
          clickable: true,
        }}
        lazy={true}
      >
        {imageGalleryItemArr?.map((ele, index) => (
          <SwiperSlide key={index}>
            <div className="mb-5">
              <img
                src={ele.image}
                className="object-center image-gallery-image"
                style={{
                  backgroundSize: "cover",
                  aspectRatio: ratio,
                  margin: "0 auto",
                  objectFit: "cover",
                  width: "100%",
                }}
                alt=""
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageGalleryPanorama;
