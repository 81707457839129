import { useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import React from "react";
import BlockWrapper from "../../BlockWrapper";
import BioLayouts from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts";
type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockBio = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;
  const content_attributes = blockData?.content_attributes;
  const style_attributes = blockData?.style_attributes;
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <BioLayouts
        content_attributes={content_attributes}
        style_attributes={style_attributes}
      />
    </BlockWrapper>
  );
};

export default BlockBio;
