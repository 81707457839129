import React from "react";
import PreviewContent from "./PreviewContent";
import FullScreenPopup from "@share/components/full-screen-popup";
import { BackAngelIcon } from "@share/icons";
import { NavBar } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { Block } from "@features/main/store/interface";
import PreviewStyleHOC from "../../PreviewStyleHOC";

const PreviewFullscreen = ({
  isVisible,
  onCancel,
  blockTitle,
  blockDescription,
  blockSpace,
  enableBlocks,
  advancedSettingData,
  isApplyGlobalSettings,
  blockType,
  blockUid,
}: {
  isVisible: boolean;
  onCancel: () => void;
  blockTitle?: string;
  blockDescription?: string;
  blockSpace?: number;
  enableBlocks: Array<Block>;
  advancedSettingData?: any;
  isApplyGlobalSettings?: boolean;
  blockType?: string;
  blockUid?: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <FullScreenPopup
        visible={isVisible}
        onMaskClick={onCancel}
      >
        <NavBar
          className="bg-white sticky top-0 z-[5]"
          backArrow={<BackAngelIcon />}
          onBack={onCancel}
        >
          <span className="flex flex-row gap-2 items-center justify-center">
            {t("ss_builder_preview")}
          </span>
        </NavBar>
        <PreviewStyleHOC
          elementId={`${blockUid}-preview-fullscreen`}
          advancedSetting={advancedSettingData}
          isAppliedBlockGlobalSettings={isApplyGlobalSettings}
          blockUid={blockUid}
        >
          <div id={`${blockUid}-preview-fullscreen`}>
            <PreviewContent
              enableBlocks={enableBlocks}
              blockSpace={blockSpace}
              blockTitle={blockTitle}
              blockDescription={blockDescription}
              advancedSettingData={advancedSettingData}
              isApplyGlobalSettings={isApplyGlobalSettings}
              blockType={blockType}
              blockUid={blockUid}
            />
          </div>
        </PreviewStyleHOC>
      </FullScreenPopup>
    </>
  );
};

export default PreviewFullscreen;
