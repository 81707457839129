import React from "react";
import MappingTypeComponent from "@features/design/components/MappingTypeComponent";
import { Block } from "@features/main/store/interface";

const BlockListsDisplay = ({
  blocks,
  blockSpace,
  onGetLatestBlockData,
  isApplyGlobalSettings,
}: {
  blocks: Array<Block>;
  blockSpace?: number;
  onGetLatestBlockData?: (data: any) => void;
  isApplyGlobalSettings?: boolean;
}) => {
  return (
    <>
      {blocks?.map((block, index) => (
        <>
          <MappingTypeComponent
            blockData={block}
            isApplyBlockSettings={isApplyGlobalSettings}
            onGetLatestBlockData={onGetLatestBlockData}
            isHideMoreActions={true}
          />
          {index !== blocks.length - 1 && (
            <div style={{ height: `${blockSpace}px` }}></div>
          )}
        </>
      ))}
    </>
  );
};

export default BlockListsDisplay;
