import AffiliateProductEdit from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductEdit";
import AffiliateProductMovingPanel from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductMovingPanel";
import { ActionEnum } from "@features/block/block-editor/AffiliateProductGroup/ProductListItems";
import { ProductInterface } from "@features/main/store/interface";
import FullScreenPopup from "@share/components/full-screen-popup";
import PopupComponent from "@share/components/PopupComponent";
import { STATUS } from "@share/configs/const";
import { useModal } from "@share/hooks/modal";
import { useToast } from "@share/hooks/toast";
import {
  DeleteIcon,
  EditTabIcon,
  EyeVisibleIcon,
  FolderArrowRightIcon,
  MoreIcon,
} from "@share/icons";
import { Popover } from "antd-mobile";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onUpdateProductGroupData: (data: any) => void;
  productData: ProductInterface;
  affiliateProductGroupData?: any;
  productGroupMaximum?: number;
  fromSwiperList?: boolean;
};

const ProductMoreActions = ({
  onUpdateProductGroupData,
  productData,
  affiliateProductGroupData,
  productGroupMaximum,
  fromSwiperList = false,
}: Props) => {
  const { t } = useTranslation();
  const { SuccessToast } = useToast();
  const { ConfirmModal } = useModal();

  const [isOpenProductEdit, setIsOpenProductEdit] = useState(false);
  const [isOpenProductMovingPanel, setIsOpenProductMovingPanel] =
    useState(false);

  const onDisableProduct = () => {
    const newData = affiliateProductGroupData?.map((ele) => {
      if (ele.uid === productGroupId) {
        return {
          ...ele,
          content_attributes: {
            ...ele.content_attributes,
            items: ele.content_attributes.items.map((item) => {
              if (item.uid === productData.uid) {
                return {
                  ...item,
                  enable: STATUS.DISABLE,
                };
              }
              return item;
            }),
          },
        };
      }
      return ele;
    });
    onUpdateProductGroupData(newData);
    SuccessToast(t("ss_builder_disable_product_successfully_label"));
  };

  const confirmDeleteProduct = () => {
    const onConfirm = () => {
      const newData = affiliateProductGroupData?.map((ele) => {
        if (ele.uid === productGroupId) {
          return {
            ...ele,
            content_attributes: {
              ...ele.content_attributes,
              items: ele.content_attributes.items.filter(
                (item) => item.uid !== productData.uid
              ),
            },
          };
        }
        return ele;
      });
      onUpdateProductGroupData(newData);
      SuccessToast(t("ss_builder_delete_product_successfully_label"));
    };
    ConfirmModal({
      title: t("ss_builder_confirm_delete_product_modal_title"),
      description: t("ss_builder_confirm_delete_product_modal_content"),
      confirmText: t("ss_builder_confirm_delete_product_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const options = [
    {
      key: ActionEnum.EDIT_PRODUCT,
      icon: (
        <EditTabIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_edit_block_label"),
      onClick: () => setIsOpenProductEdit(true),
    },
    {
      key: ActionEnum.VISIBLE_HIDE_PRODUCT,
      icon: (
        <EyeVisibleIcon
          className="fill-white"
          width={20}
          height={20}
        />
      ),
      text: t("ss_builder_hide_product_label"),
      onClick: onDisableProduct,
    },
    {
      key: ActionEnum.DELETE_PRODUCT,
      icon: (
        <DeleteIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_delete_product_label"),
      onClick: confirmDeleteProduct,
    },
    {
      key: ActionEnum.MOVE_PRODUCT,
      icon: (
        <FolderArrowRightIcon
          className="w-5 h-5"
          fillColor="white"
        />
      ),
      text: t("ss_builder_move_product_label"),
      onClick: () => setIsOpenProductMovingPanel(true),
    },
  ];

  const productGroupId = useMemo(() => {
    let value = null;
    affiliateProductGroupData?.forEach((ele) => {
      const productLists = ele.content_attributes.items;
      productLists.forEach((item) => {
        if (item.uid === productData.uid) {
          value = ele.uid;
        }
      });
    });
    return value;
  }, [productData, affiliateProductGroupData]);

  const handleConfirmAffiliateProductMovingPanel = useCallback(
    (newProductGroupId) => {
      let productDataAddNew = null;
      const affiliateProductGroupDataClone = cloneDeep(
        affiliateProductGroupData
      );
      affiliateProductGroupDataClone.forEach((element) => {
        if (element.uid === productGroupId) {
          const productList = element?.content_attributes?.items;
          productDataAddNew = productList.find(
            (x) => x.uid === productData.uid
          );
        }
      });
      //Add more product into the new product group
      const productGroupDataAfterAddingProductId =
        affiliateProductGroupDataClone.map((element) => {
          if (element.uid === newProductGroupId) {
            const productList = element?.content_attributes?.items;
            productDataAddNew && productList.unshift(productDataAddNew);

            const newProductListSortOrder = productList.map((ele, index) => ({
              ...ele,
              sort_order: index + 1,
            }));
            return {
              ...element,
              content_attributes: {
                ...element.content_attributes,
                items: newProductListSortOrder,
              },
            };
          } else return element;
        });

      //Remove product from the old product group
      const productGroupDataAfterRemovingProductId =
        productGroupDataAfterAddingProductId.map((element) => {
          if (element.uid === productGroupId) {
            const productList = element?.content_attributes?.items;
            const productListRemovedProductId = productList.filter(
              (x) => x.uid !== productData.uid
            );
            const productListRemovedProductIdSortOrder =
              productListRemovedProductId.map((ele, index) => ({
                ...ele,
                sort_order: index + 1,
              }));
            return {
              ...element,
              content_attributes: {
                ...element.content_attributes,
                items: productListRemovedProductIdSortOrder,
              },
            };
          } else return element;
        });
      onUpdateProductGroupData(productGroupDataAfterRemovingProductId);
      SuccessToast(t("ss_builder_move_product_successfully_label"));
    },
    [affiliateProductGroupData, productData, productGroupId]
  );

  const optionsFilter = useMemo(() => {
    let optionsClone = Array.from(options);
    const isHideMovingProductOption = affiliateProductGroupData?.length <= 1;
    if (isHideMovingProductOption) {
      optionsClone = optionsClone.filter(
        (option) => option.key !== ActionEnum.MOVE_PRODUCT
      );
    }
    return optionsClone;
  }, [options, affiliateProductGroupData]);

  return (
    <>
      <Popover.Menu
        mode="dark"
        actions={optionsFilter}
        placement="bottom-end"
        trigger="click"
        stopPropagation={["click"]}
        getContainer={() =>
          fromSwiperList
            ? document.getElementById("passio-aff-swiper-list")
            : document.getElementById("passio-design-class")
        }
      >
        <div className="w-8 h-8 p-1">
          <div
            className="cursor-pointer w-full h-full flex items-center justify-center rounded-full"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              // backdropFilter: "blur(15px)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
            }}
          >
            <MoreIcon
              className="w-4 h-4"
              fillColor="#FFFFFF"
            />
          </div>
        </div>
      </Popover.Menu>
      {isOpenProductEdit && (
        <FullScreenPopup
          visible={isOpenProductEdit}
          onMaskClick={() => setIsOpenProductEdit(false)}
        >
          <AffiliateProductEdit
            productData={productData}
            activeProductGroup={productGroupId}
            callBackCancelAffiliateProductEdit={() =>
              setIsOpenProductEdit(false)
            }
            callBackSaveAffiliateProductEdit={(val) =>
              onUpdateProductGroupData(val)
            }
            affiliateProductGroupData={affiliateProductGroupData}
            callbackAddNewAffiliateProductGroup={(val) =>
              onUpdateProductGroupData(val)
            }
            productGroupMaximum={productGroupMaximum}
          />
        </FullScreenPopup>
      )}
      <PopupComponent
        visible={isOpenProductMovingPanel}
        onMaskClick={() => setIsOpenProductMovingPanel(false)}
        title={t("ss_builder_affiliate_product_moving_panel_title")}
        onClose={() => setIsOpenProductMovingPanel(false)}
      >
        <AffiliateProductMovingPanel
          callbackCancelAffiliateProductMovingPanel={() =>
            setIsOpenProductMovingPanel(false)
          }
          callbackConfirmAffiliateProductMovingPanel={
            handleConfirmAffiliateProductMovingPanel
          }
          allowedMovingAffiliateProductGroup={affiliateProductGroupData?.filter(
            (x) => x.uid !== productGroupId
          )}
          productMovingQuantity={1}
        />
      </PopupComponent>
    </>
  );
};

export default ProductMoreActions;
