import { FavouriteIcon, HotIcon, SaleIcon } from "@share/icons";
import React from "react";
import LazyImage from "@share/components/lazy-image";
import { LABEL_PRODUCT, PRODUCT_ON_ROW } from "@share/configs/const";
import { useTranslation } from "react-i18next";
import ProductMoreActions from "../../ProductMoreActions";
import { useAffiliateContext } from "@features/block/block-editor/AffiliateProductGroup/context";
import { getHorizontalResizeUrl } from "@share/helper/image";

const ProductDetailStyle1 = (props) => {
  const { t } = useTranslation();
  const {
    product,
    styleAttributes,
    affiliateProductGroupData,
    productGroupMaximum,
    onUpdateProductGroupData,
    fromSwiperList = false,
  } = props;
  const { frameData, usingFrame } = useAffiliateContext();

  const threeItemOnRows =
    styleAttributes.item_on_row === PRODUCT_ON_ROW.THREE ||
    styleAttributes.style_display === "grid-3";
  const isHot = product?.label_product?.includes(LABEL_PRODUCT.HOT);
  const isFavourite = product?.label_product?.includes(LABEL_PRODUCT.FAVOURITE);
  const isSale = product?.label_product?.includes(LABEL_PRODUCT.SALE);

  return (
    <div>
      <div
        className="relative"
        id={`affiliate-product-item-${product.uid}`}
      >
        <div className="absolute top-0 right-0 z-[12]">
          <ProductMoreActions
            productData={product}
            affiliateProductGroupData={affiliateProductGroupData}
            productGroupMaximum={productGroupMaximum}
            onUpdateProductGroupData={onUpdateProductGroupData}
            fromSwiperList={fromSwiperList}
          />
        </div>
        <LazyImage
          key={product.uid}
          src={product.image}
          // fit="cover"
          width={512}
          height={512}
          className="object-center affiliate-product-image"
          style={{
            backgroundSize: "cover",
            aspectRatio: "1/1",
            borderRadius: "4px",
            margin: "0 auto",
            objectFit: "cover",
            width: "100%",
            outline: "solid rgba(0, 0, 0, 0.16) 1px",
            outlineOffset: "-1px",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              "https://i.ecomobi.com/ssp/passio-page/image-default.png";
          }}
        />
        {threeItemOnRows && (
          <div
            className="gap-0.5 absolute flex z-[11]"
            style={{
              top: "8px",
              left: "8px",
            }}
          >
            {isSale && (
              <SaleIcon
                width={16}
                height={16}
              />
            )}
            {isFavourite && (
              <FavouriteIcon
                width={16}
                height={16}
              />
            )}
          </div>
        )}
        {!threeItemOnRows && (
          <>
            {isFavourite && (
              <div
                className="absolute"
                style={{ top: "8px", left: "-3px", zIndex: 11 }}
              >
                <div className="favourite ">
                  {/* <FavouriteLabelIcon /> */}
                  <span className="text-label default-font-family px-1 ">
                    {t("ss_builder_affiliate_product_label_product_like")}
                  </span>
                </div>
              </div>
            )}
            {isSale && (
              <div
                className="absolute "
                style={{ top: "8px", right: "8px", zIndex: 11 }}
              >
                <div className="sale ">
                  <span className="text-label  default-font-family px-1">
                    {t("ss_builder_affiliate_product_label_product_discount")}
                  </span>
                </div>
              </div>
            )}
          </>
        )}
        {usingFrame && frameData && (
          <div
            className="absolute top-0 left-0 z-10"
            style={{
              background: `url("${getHorizontalResizeUrl(
                frameData.urlEU,
                512
              )}") no-repeat left bottom/ contain`,
              aspectRatio: "1/1",
              borderRadius: "4px",
              width: "100%",
              height: "auto",
            }}
          ></div>
        )}
      </div>
      <div
        className="ellipsis-text affiliate-product-name"
        style={{ marginTop: "7.5px", color: "var(--text-color-6)" }}
      >
        {isHot && (
          <span style={{ marginRight: "2px" }}>
            {threeItemOnRows && (
              <HotIcon
                className="inline mb-1"
                width={16}
                height={16}
              />
            )}
            {!threeItemOnRows && (
              <span className="hot  mb-1">
                <span className="text-label  default-font-family px-1">
                  {t("ss_builder_affiliate_product_label_product_hot")}
                </span>
              </span>
            )}
          </span>
        )}
        <span>{product.title}</span>
      </div>
    </div>
  );
};

export default ProductDetailStyle1;
