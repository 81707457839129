import {
  NoShadowCustomIcon,
  NoShadowRoundedIcon,
  NoShadowSquareIcon,
} from "@features/setting/components/Icons";
import { Selector, Slider, Switch, Divider } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BLOCK_SETTING_DEFAULT_VALUE } from "@share/configs/const";

const RADIUS_STYLE_SQUARE = "square";
const RADIUS_STYLE_ROUNDED = "rounded";
const RADIUS_STYLE_CUSTOM = "custom";
const MAX_INNER_SPACE_NUMBER = 24;

const masks = {
  0: 0,
  2: "",
  4: "",
  6: "",
  8: "",
  10: "",
  12: "",
  14: "",
  16: "",
  18: "",
  20: "",
  22: "",
  24: "",
  26: "",
  28: "",
  30: "",
  32: "",
  34: "",
  36: "",
  38: "",
  40: "",
  42: "",
  44: "",
  46: "",
  48: 48,
};

const masksInnerSpace = {
  0: 0,
  12: 12,
  16: 16,
  20: 20,
  24: 24,
};

export default function BlockStyle(props: {
  defaultValue: any;
  onUpdateRadius: (data) => void;
}) {
  const { onUpdateRadius, defaultValue } = props;
  const { t } = useTranslation();
  const [radiusType, setRadiusType] = useState(
    defaultValue?.radius_type ?? RADIUS_STYLE_SQUARE
  );
  const [radiusValue, setRadiusValue] = useState<number>(
    defaultValue?.radius_value ?? 0
  );
  const [innerSpace, setInnerSpace] = useState<boolean>(
    defaultValue?.inner_space ?? false
  );
  const [blockSpace, setBlockSpace] = useState<number>(
    defaultValue?.block_space ?? BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE
  );
  const [innerSpaceNumber, setInnerSpaceNumber] = useState<number>(
    defaultValue?.inner_space_number ??
      (innerSpace ? BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER : 0)
  );

  const [outsideSpace, setOutsideSpace] = useState<boolean>(
    defaultValue?.outside_space ?? BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE
  );

  useEffect(() => {
    onUpdateRadius({
      radius_type: radiusType,
      radius_value: radiusValue,
      inner_space: innerSpace,
      block_space: blockSpace,
      inner_space_number: innerSpaceNumber,
      outside_space: outsideSpace,
    });
  }, [
    radiusType,
    radiusValue,
    innerSpace,
    blockSpace,
    innerSpaceNumber,
    outsideSpace,
  ]);
  return (
    <>
      <div className="text-base mb-4">
        {t("ss_builder_theme_setting_block_style")}
      </div>
      <Selector
        className="mb-3"
        columns={3}
        onChange={(val) => {
          if (val.length) {
            setRadiusType(val[0]);
          }
        }}
        options={[
          {
            label: <NoShadowSquareIcon className={"m-auto"} />,
            description: t("ss_builder_square"),
            value: RADIUS_STYLE_SQUARE,
          },
          {
            label: <NoShadowRoundedIcon className={"m-auto"} />,
            description: t("ss_builder_rounded"),
            value: RADIUS_STYLE_ROUNDED,
          },
          {
            label: <NoShadowCustomIcon className={"m-auto"} />,
            description: t("ss_builder_custom"),
            value: RADIUS_STYLE_CUSTOM,
          },
        ]}
        value={[radiusType]}
        style={{
          "--checked-color": "#fff",
        }}
      />
      {radiusType == RADIUS_STYLE_CUSTOM && (
        <div className="mb-3">
          <div className="text-base mb-4 flex justify-between">
            <span>{t("ss_builder_border_radius_label")}</span>
            <span className="primary-color text-sm">{radiusValue} px</span>
          </div>
          <Slider
            onChange={(val) => setRadiusValue(parseInt(val.toString()))}
            // ticks={true}
            marks={masks}
            min={0}
            step={2}
            max={48}
            defaultValue={radiusValue}
            popover={true}
          />
        </div>
      )}
      <div>
        <div className="text-base mb-3 flex justify-between">
          <span>{t("ss_builder_inner_space")}</span>
          {/* <span className="primary-color text-sm">{innerSpaceNumber} px</span> */}
        </div>
        <Slider
          onChange={(val) => setInnerSpaceNumber(parseInt(val.toString()))}
          marks={masksInnerSpace}
          min={0}
          step={4}
          max={MAX_INNER_SPACE_NUMBER}
          defaultValue={innerSpaceNumber}
          popover={true}
          ticks={true}
        />
      </div>
      <Divider />
      <div className="mb-4">
        <div className="text-base mb-1">
          {t("ss_builder_block_outside_space")}
        </div>
        <Switch
          defaultChecked={outsideSpace}
          uncheckedText={t("ss_builder_off")}
          checkedText={t("ss_builder_on")}
          onChange={setOutsideSpace}
        />
      </div>
      <div className="mt-3 pt-3 border-t-gray-200 border-t-[1px]">
        <div className="text-base mb-1">{t("ss_builder_block_space")}</div>
        <Slider
          onChange={(val) =>
            setBlockSpace(
              parseInt(val.toString()) ||
                BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE
            )
          }
          marks={{
            12: 12,
            16: 16,
            20: 20,
            24: 24,
          }}
          min={12}
          max={24}
          step={4}
          value={blockSpace ?? BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE}
          ticks={true}
        />
      </div>
    </>
  );
}
