import React, {
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CheckCircleIcon,
  InformationTriangleIcon,
  PreviewThemeIcon,
  SaveIcon,
  UploadIcon,
  InformationIcon,
} from "@share/icons";
import {
  Button,
  Divider,
  Image,
  ImageUploader,
  Input,
  Toast,
} from "antd-mobile";
import { mockUpload } from "@share/lib";
import CreatableSelect from "react-select/creatable";
import style from "@features/design/components/ThemeTemplateHandler/ThemeTemplateSaving/style.module.scss";
import FullScreenPopup from "@share/components/full-screen-popup";
import ThemeTemplatePreviewByImage from "@features/design/components/ThemeTemplateHandler/ThemeTemplatePreviewByImage";
import { TEMPLATE_KEY, THEME_PREVIEW_BY_IMAGE_KEY } from "@share/configs/const";
import { useTranslation } from "react-i18next";
import {
  createManyTagThemeTemplate,
  requestSearchTag,
  requestSearchTemplate,
  requestUpdateTemplate,
  requestUploadImage,
} from "@share/api";
import { useAppSelector } from "@app/hooks";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import { useDebounce } from "usehooks-ts";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { useToast } from "@share/hooks/toast";
import { ModalIconConfig, useModal } from "@share/hooks/modal";

const TEMPLATE_NAME_MAXIMUM = 25;
const regexPattern = /\s+/g;
const KEY_COMMA = ",";
const KEY_ENTER = "Enter";
interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});
const TemplateSaving = (props) => {
  const { t } = useTranslation();
  const { ConfirmModal } = useModal();
  const {
    KEY,
    handleCancelTemplateSaving,
    templateDataSaving,
    visible,
    onMaskClick,
    handleLogicShowModalChooseOptionTemplate,
    onUpdateListTemplate,
    handleCloseOverwriteList = ()=> null
  } = props;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const [templateDataDefault, setTemplateDataDefault] = useState<any>({});
  const [valueUrlImage, setValueUrlImage] = useState("");
  const [templateNameValue, setTemplateNameValue] = useState("");
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [valueNameImage, setValueNameImage] = useState("");
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [listTemplate, setListTemplate] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValueTag, setInputValueTag] = useState("");
  const [valueTag, setValueTag] = React.useState<Option[]>();
  const [tagThemeTemplate, setTagThemeTemplate] = useState([]);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { SuccessToast } = useToast();

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creator_role = passioPageProfile?.creator_role;
  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );
  const user = useAppSelector((state) => state.user);
  const userId = user?.kol?._id;

  useEffect(() => {
    setValueUrlImage(templateDataSaving?.image);
    setTemplateNameValue(templateDataSaving?.title);
    setTagThemeTemplate(templateDataSaving?.tags);
    setTemplateDataDefault(templateDataSaving);
    const tags = templateDataSaving?.tags?.map((ele) => {
      return {
        label: ele,
        value: ele,
      };
    });
    if (tags) {
      setValueTag(tags);
    }
  }, [templateDataSaving, visible]);

  useEffect(() => {
    const requestTemplateList = async () => {
      let listTemplateResponse = null;
      const dataRequest = {
        per_page: 100,
        keyword: "",
        is_admin: isAdminRole,
        block_type: BLOCK_TYPE.COMBINED,
      };
      try {
        listTemplateResponse = await requestSearchTemplate(dataRequest);
      } catch (error) {
        console.log(error);
      }
      const listTemplateArr = listTemplateResponse?.data;
      if (listTemplateArr) setListTemplate(listTemplateArr);
    };
    requestTemplateList();
  }, [isAdminRole]);

  const requestTagList = async (keyword = "") => {
    let listTagResponse = null;
    const dataRequest = {
      per_page: 12,
      type: "block-template",
      keyword: keyword
        ? keyword.trim().replaceAll(regexPattern, " ").replaceAll(KEY_COMMA, "")
        : "",
    };
    try {
      listTagResponse = await requestSearchTag(dataRequest);
    } catch (error) {
      console.log(error);
    }
    const options = listTagResponse?.data.map((ele) => {
      return {
        value: ele.title,
        label: ele.title,
      };
    });
    setSuggestions(options ?? []);
  };
  const useDebounceSearch = useDebounce(inputValueTag, 500);
  useEffect(() => {
    if (useDebounceSearch) {
      requestTagList(inputValueTag);
    }
  }, [useDebounceSearch]);

  const checkDuplicateNameTheme = useMemo(() => {
    let themeDuplicatedName = null;
    if (KEY === TEMPLATE_KEY.SAVE_NEW) {
      themeDuplicatedName = listTemplate?.find(
        (x) =>
          x?.title?.trim()?.toLowerCase() ===
          templateNameValue?.trim()?.toLowerCase()
      );
    } else if (KEY === TEMPLATE_KEY.EDIT || KEY === TEMPLATE_KEY.OVERWRITE) {
      themeDuplicatedName = listTemplate
        ?.filter((a) => a.id !== templateDataSaving?.id)
        ?.find(
          (x) =>
            x?.title?.trim()?.toLowerCase() ===
            templateNameValue?.trim()?.toLowerCase()
        );
    }
    if (themeDuplicatedName) {
      return true;
    } else return false;
  }, [listTemplate, templateNameValue]);

  const titlePageRender = useMemo(() => {
    let titleValue = "";
    switch (KEY) {
      case TEMPLATE_KEY.SAVE_NEW:
        titleValue = t("ss_builder_save_new_template_title");
        break;
      case TEMPLATE_KEY.OVERWRITE:
        titleValue = t("ss_builder_overwrite_template_title");
        break;
      case TEMPLATE_KEY.EDIT:
        titleValue = t("ss_builder_edit_template_title");
        break;
    }
    return titleValue;
  }, [KEY]);

  const buttonSaveLabelRender = useMemo(() => {
    let btnLabel = "";
    switch (KEY) {
      case TEMPLATE_KEY.SAVE_NEW:
        btnLabel = t("ss_builder_add_new_template_btn_label");
        break;
      case TEMPLATE_KEY.OVERWRITE:
        btnLabel = t("ss_builder_overwrite_template_btn_label");
        break;
      case TEMPLATE_KEY.EDIT:
        btnLabel = t("ss_builder_edit_template_btn_label");
        break;
    }
    return btnLabel;
  }, [KEY]);

  const handleOpenTemplatePreview = () => {
    setIsOpenPreview(true);
  };

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
    }
  };

  const handleChangeTemplateName = (val) => {
    if (val.length <= TEMPLATE_NAME_MAXIMUM) {
      setTemplateNameValue(val);
    }
  };

  const handeSetValueTag = (value) => {
    const newValue = value.map((ele) => {
      return {
        ...ele,
        value: ele.value
          .trim()
          .replaceAll(regexPattern, " ")
          .replaceAll(KEY_COMMA, ""),
        label: ele.label
          .trim()
          .replaceAll(regexPattern, " ")
          .replaceAll(KEY_COMMA, ""),
      };
    });
    setValueTag(newValue);
    const tagTheme = newValue.map((ele) => {
      return ele.value;
    });
    setTagThemeTemplate(tagTheme);
  };
  const handeInputChange = (options, { action }) => {
    setInputValueTag(options);
    setIsOpenMenu(options.length > 1);
  };
  const handleKeyDown: KeyboardEventHandler = async (event) => {
    if (!inputValueTag) return;

    if (event.key === KEY_ENTER || event.key === KEY_COMMA) {
      const inputValueTagTrim = inputValueTag
        .trim()
        .replaceAll(regexPattern, " ")
        .replaceAll(KEY_COMMA, "");
      if (!suggestions.some((x) => x.value === inputValueTagTrim)) {
        handeSetValueTag([...valueTag, createOption(inputValueTagTrim)]);
        setInputValueTag("");
      } else {
        if (!valueTag.some((x) => x.value === inputValueTagTrim)) {
          handeSetValueTag([...valueTag, createOption(inputValueTagTrim)]);
          setInputValueTag("");
        }
      }
      event.preventDefault();
      return;
    }
  };

  const isDisableSaveBtn = useMemo(() => {
    if (
      KEY === TEMPLATE_KEY.SAVE_NEW &&
      valueUrlImage &&
      templateNameValue &&
      !checkDuplicateNameTheme
    )
      return false;
    if (
      KEY === TEMPLATE_KEY.EDIT &&
      !checkDuplicateNameTheme &&
      ((!!valueTag?.length &&
        JSON.stringify(valueTag?.map((a) => a.value)) !==
          JSON.stringify(templateDataDefault?.tags)) ||
        templateNameValue !== templateDataDefault?.title ||
        valueImageBase64)
    ) {
      return false;
    }
    if (KEY === TEMPLATE_KEY.OVERWRITE) return false;
    return true;
  }, [
    valueUrlImage,
    templateNameValue,
    KEY,
    checkDuplicateNameTheme,
    templateDataDefault,
    valueTag,
  ]);

  const createManyTag = async (value) => {
    let createTagResponse = null;
    const dataRequest = {
      user_id: userId,
      list_tags: value,
      type: "block-template",
    };
    try {
      createTagResponse = await createManyTagThemeTemplate(dataRequest);
    } catch (error) {
      console.log(error);
    }
    const listTagArr = createTagResponse?.data;
    if (!listTagArr.error) {
      return true;
    }

    return false;
  };

  const handleConfirmSaveTemplate = async () => {
    const castWindow = window as any;
    let responseImage = null;
    let reponse = null;
    if (valueImageBase64) {
      const dataResquestUploadImage = {
        id: siteSettings.id,
        user_id: siteSettings.user_id,
        name: valueNameImage,
        content: valueImageBase64,
      };
      try {
        responseImage = await requestUploadImage(dataResquestUploadImage);
      } catch (error) {
        console.log(error);
      }
      if (responseImage) {
        const dataRequest = {
          ...templateDataSaving,
          title: templateNameValue,
          image: responseImage.url,
          tags: valueTag?.map((tag) => tag.value) || [],
        };
        try {
          reponse = await requestUpdateTemplate(dataRequest);
          createManyTag(valueTag?.map((tag) => tag.value));
        } catch (error) {
          console.log(error);
        }
        if (/^20[0-9]$/.test(reponse?.status.toString())) {
          if (KEY !== TEMPLATE_KEY.SAVE_NEW) {
            const newList = listTemplate.map((item) => {
              if (item.id === templateDataSaving.id)
                return {
                  ...templateDataSaving,
                  title: templateNameValue,
                  image: responseImage.url,
                  tags: valueTag?.map((tag) => tag.value) || [],
                };
              return item;
            });
            onUpdateListTemplate(newList);
          }
          handleCancelTemplateSaving();
          handleLogicShowModalChooseOptionTemplate(false);
          if(!!handleCloseOverwriteList) {
            handleCloseOverwriteList()
          }
          castWindow.functionHandleOpenCloseTemplateList(true);
          SuccessToast(
            KEY === TEMPLATE_KEY.OVERWRITE
              ? t("ss_builder_overwrite_template_successfully_label")
              : t("ss_builder_save_new_theme_template_successfully_label")
          );
        }
      }
    } else {
      const dataRequest = {
        ...templateDataSaving,
        title: templateNameValue,
        tags: valueTag?.map((tag) => tag.value) || [],
      };
      try {
        reponse = await requestUpdateTemplate(dataRequest);
        createManyTag(valueTag?.map((tag) => tag.value));
      } catch (error) {
        console.log(error);
      }
      if (/^20[0-9]$/.test(reponse?.status.toString())) {
        if (KEY !== TEMPLATE_KEY.SAVE_NEW) {
          const newList = listTemplate.map((item) => {
            if (item.id === templateDataSaving.id)
              return {
                ...templateDataSaving,
                title: templateNameValue,
                tags: valueTag?.map((tag) => tag.value) || [],
              };
            return item;
          });
          onUpdateListTemplate(newList);
        }
        handleCancelTemplateSaving();
        handleLogicShowModalChooseOptionTemplate(false);
        if(!!handleCloseOverwriteList) {
          handleCloseOverwriteList()
        }
        castWindow.functionHandleOpenCloseTemplateList(true);
        SuccessToast(
            KEY === TEMPLATE_KEY.OVERWRITE
                ? t("ss_builder_overwrite_template_successfully_label")
                : t("ss_builder_save_new_theme_template_successfully_label")
        );
      }
    }
  };

  const handleCancelAction = () => {
    if (isDisableSaveBtn) {
      handleCancelTemplateSaving();
    } else {
      ConfirmModal({
        icon: ModalIconConfig.INFO_WARNING_CIRCLE,
        title: t("ss_builder_cancel_edit_block_title"),
        description: t("ss_builder_cancel_edit_block_content"),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => null,
        onCancel: handleCancelTemplateSaving,
      });
    }
  };

  return (
    <FullScreenPopup
      visible={visible}
      onMaskClick={() => {
        onMaskClick(false);
      }}
    >
      <div className="pb-[60px]">
        <div
          className="flex flex-row text-center items-center justify-center py-3 gap-[10px] relative bg-white"
          style={{
            boxShadow: "inset 0px -1px 0px #EEEEEE",
          }}
        >
          <SaveIcon className="w-[22px] h-[22px]" />
          <div className="text-[18px] leading-6">{titlePageRender}</div>
        </div>
        <div className="px-4 py-3 bg-white">
          <div className="text-[15px] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_add_new_template_image_title")}
          </div>
          <Image
            src={
              valueUrlImage ||
              "https://image.passio.eco/page-builder/page-builder/images-default/image-validate-default-alert.png"
            }
            fit="cover"
            style={{
              backgroundSize: "cover",
              aspectRatio: "16/9",
              border: "1px solid #D9D9D9",
            }}
          />
          {isValidFileFormat && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_format_invalid_content")}
              </div>
            </div>
          )}
          {isValidFileSize && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_size_invalid_content")}
              </div>
            </div>
          )}
          <div className="flex gap-2 mt-4">
            <Button
              size="middle"
              color="primary"
              fill="outline"
              className=""
              onClick={valueUrlImage ? handleOpenTemplatePreview : () => {}}
              style={{
                opacity: !valueUrlImage ? "0.4" : "",
              }}
            >
              <PreviewThemeIcon
                className="w-[17px] h-[17px]"
                fillColor="#EE3244"
              />
            </Button>
            <ImageUploader
              onChange={handleImageUrl}
              upload={(file) => mockUpload(file, 514)}
              className={`image-slider-upload ${
                uploadedImageArr.length > 0 ? "image-uploaded-hidden" : ""
              }`}
              value={uploadedImageArr}
              // disableUpload={isDisableUploadBtn}
              children={
                <Button
                  block
                  color="primary"
                  fill="solid"
                  size="middle"
                  className="w-full grow"
                  //   onClick={handleSaveBlockText}
                  // disabled={isDisableUploadBtn}
                >
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <UploadIcon
                      className="w-[17px] h-[17px]"
                      fillColor="white"
                    />
                    {t("ss_builder_upload_button_label")}
                  </div>
                </Button>
              }
            />
          </div>
          <Divider />
          <div>
            <div className="text-[15px] text-[#666666]">
              <span className="text-red-600">*</span>
              {t("ss_builder_add_new_template_name_title")}
            </div>
            <Input
              placeholder={t("ss_builder_add_new_template_name_placeholder")}
              onChange={handleChangeTemplateName}
              value={templateNameValue}
              maxLength={TEMPLATE_NAME_MAXIMUM}
            />
            {checkDuplicateNameTheme && (
              <div className="text-[13px] leading-[17px] text-[#FF3141]">
                {t("ss_builder_duplicated_theme_template_name_warning")}
              </div>
            )}
            <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
              templateNameValue ? templateNameValue.length : 0
            }/${TEMPLATE_NAME_MAXIMUM}`}</div>
          </div>
          <Divider />
          <div>
            <div className="text-[15px] text-[#666666] leading-[150%]">
              {t("ss_builder_theme_template_label_tag")}
            </div>
            <div>
              <CreatableSelect
                isClearable
                isMulti
                options={suggestions}
                inputValue={inputValueTag}
                onChange={(newValue) => handeSetValueTag(newValue)}
                onInputChange={(newValue, { action }) =>
                  handeInputChange(newValue, { action })
                }
                onKeyDown={handleKeyDown}
                value={valueTag}
                placeholder={t(
                  "ss_builder_theme_template_label_tag_placeholder"
                )}
                classNames={{
                  control: (state) => `${style.borderTag}`,
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                menuIsOpen={isOpenMenu}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                formatCreateLabel={(userInput) =>
                  `${t(
                    "ss_builder_theme_template_format_create_label"
                  )} "${userInput}"`
                }
                noOptionsMessage={() => null}
              />
            </div>
          </div>
        </div>

        <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              onClick={handleCancelAction}
            >
              {t("ss_builder_editor_btn-cancel")}
            </Button>
          </div>
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              color={"primary"}
              onClick={() => handleConfirmSaveTemplate()}
              disabled={isDisableSaveBtn}
            >
              {buttonSaveLabelRender}
            </Button>
          </div>
        </div>
        {isOpenPreview && (
          <FullScreenPopup
            visible={isOpenPreview}
            onMaskClick={() => {
              setIsOpenPreview(false);
            }}
          >
            <ThemeTemplatePreviewByImage
              handleCancelThemeTemplatePreview={() => setIsOpenPreview(false)}
              imageSrc={valueUrlImage}
              KEY={THEME_PREVIEW_BY_IMAGE_KEY.PREVIEW}
            />
          </FullScreenPopup>
        )}
      </div>
    </FullScreenPopup>
  );
};
export default TemplateSaving;
