import { LABEL_PRODUCT, PRODUCT_ON_ROW } from "@share/configs/const";
import { Ellipsis } from "antd-mobile";
import React, { useMemo } from "react";
import LazyImage from "@share/components/lazy-image";
import { useTranslation } from "react-i18next";
import { FavouriteIcon, HotIcon, SaleIcon } from "@share/icons";
import ProductMoreActions from "../../ProductMoreActions";
import { useAffiliateContext } from "@features/block/block-editor/AffiliateProductGroup/context";
import cls from "classnames";
import { getHorizontalResizeUrl } from "@share/helper/image";

const ProductDetailStyle2 = (props) => {
  const {
    product,
    item_on_row,
    affiliateProductGroupData,
    productGroupMaximum,
    onUpdateProductGroupData,
  } = props;

  const { frameData, usingFrame } = useAffiliateContext();
  const { t } = useTranslation();
  const isHot = product?.label_product?.includes(LABEL_PRODUCT.HOT);
  const isFavourite = product?.label_product?.includes(LABEL_PRODUCT.FAVOURITE);
  const isSale = product?.label_product?.includes(LABEL_PRODUCT.SALE);
  const threeItemOnRows = item_on_row === PRODUCT_ON_ROW.THREE;
  const rowEllipsis = useMemo(() => {
    switch (item_on_row) {
      case PRODUCT_ON_ROW.ONE:
        return 6;
      case PRODUCT_ON_ROW.TWO:
        return 3;
      case PRODUCT_ON_ROW.THREE:
        return 3;
    }
  }, [item_on_row]);

  const paddingText = useMemo(() => {
    switch (item_on_row) {
      case PRODUCT_ON_ROW.ONE:
        return 6;
      case PRODUCT_ON_ROW.TWO:
        return 3;
      case PRODUCT_ON_ROW.THREE:
        return 2;
    }
  }, [item_on_row]);

  return (
    <div
      className="relative"
      id={`affiliate-product-item-${product.uid}`}
    >
      {usingFrame && frameData && (
        <div
          className="absolute top-0 left-0 z-[12]"
          style={{
            background: `url("${getHorizontalResizeUrl(
              frameData.urlEU,
              512
            )}") no-repeat left bottom/ contain`,
            aspectRatio: "1/1",
            borderRadius: "4px",
            width: "100%",
            height: "auto",
          }}
        ></div>
      )}
      <div className="absolute top-0 right-0 z-[14]">
        <ProductMoreActions
          productData={product}
          affiliateProductGroupData={affiliateProductGroupData}
          productGroupMaximum={productGroupMaximum}
          onUpdateProductGroupData={onUpdateProductGroupData}
        />
      </div>
      <LazyImage
        key={product.uid}
        src={product.image}
        // fit="cover"
        width={512}
        height={512}
        className="object-center affiliate-product-image"
        style={{
          backgroundSize: "cover",
          aspectRatio: "1/1",
          borderRadius: "4px",
          margin: "0 auto",
          objectFit: "cover",
          width: "100%",
          outline: "solid rgba(0, 0, 0, 0.16) 1px",
          outlineOffset: "-1px",
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src =
            "https://i.ecomobi.com/ssp/passio-page/image-default.png";
        }}
      />
      <div
        className="absolute top-0 left-0 block w-full h-full text-center z-[11]"
        style={{
          backgroundColor: "#000000",
          opacity: "0.6",
          borderRadius: "4px",
        }}
      ></div>
      <div
        className={cls("absolute w-full z-[13]", `px-${paddingText}`)}
        style={{
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          top: "80%",
          left: "80%",
          transform: "translate(-80%, -80%)",
        }}
      >
        <Ellipsis
          direction="end"
          content={product.title}
          className="lead-1 text-neutral-50 text-center affiliate-product-name"
          rows={rowEllipsis}
          style={{
            lineHeight: "150%",
          }}
        />
      </div>
      {threeItemOnRows && (
        <div
          className="absolute  flex w-full justify-center gap-1 z-[13]"
          style={{
            top: "8%",
            left: "8%",
            transform: "translate(-8%, -8%)",
          }}
        >
          {isHot && (
            <HotIcon
              className="inline"
              width={16}
              height={16}
            />
          )}
          {isSale && (
            <SaleIcon
              width={16}
              height={16}
            />
          )}
          {isFavourite && (
            <FavouriteIcon
              width={16}
              height={16}
            />
          )}
        </div>
      )}
      {!threeItemOnRows && (
        <>
          <div className="absolute top-2 left-[-3px] z-[13]">
            {isFavourite && (
              <div className="favourite-label">
                <span className="text-label default-font-family px-1  ">
                  {t("ss_builder_affiliate_product_label_product_like")}
                </span>
              </div>
            )}
            {isHot && (
              <div className="hot-label">
                <span className="text-label default-font-family px-1  ">
                  {t("ss_builder_affiliate_product_label_product_hot")}
                </span>
              </div>
            )}
          </div>
          {isSale && (
            <div className="absolute top-2 right-2 z-[13]">
              <div className="sale">
                <span className="text-label default-font-family px-1  ">
                  {t("ss_builder_affiliate_product_label_product_discount")}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductDetailStyle2;
