export const EarnMoneyIcon = ({ className = "", fillColor = "#FBB523" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_38484_650603)">
        <path
          d="M8.29323 5.05676C8.30589 5.11058 8.36032 5.16496 8.41414 5.17767L13.4307 6.36112C13.4845 6.37378 13.4845 6.39458 13.4307 6.40725L8.41414 7.59044C8.36032 7.6031 8.30594 7.65753 8.29323 7.71135L7.10988 12.7279C7.09722 12.7817 7.07642 12.7817 7.06375 12.7279L5.88066 7.71135C5.868 7.65753 5.81357 7.60315 5.75975 7.59044L0.743002 6.40725C0.689182 6.39458 0.689182 6.37378 0.743002 6.36112L5.75975 5.17767C5.81357 5.16501 5.86795 5.11058 5.88066 5.05676L7.06375 0.0403653C7.07642 -0.0134551 7.09722 -0.0134551 7.10988 0.0403653L8.29323 5.05676ZM11.6332 15.9349C11.6458 15.9887 11.7002 16.0431 11.754 16.0558L15.652 16.9754C15.7058 16.988 15.7058 17.0089 15.652 17.0215L11.754 17.941C11.7002 17.9536 11.6458 18.0081 11.6332 18.0618L10.7137 21.9597C10.7011 22.0134 10.6803 22.0134 10.6676 21.9597L9.74829 18.0618C9.73562 18.0081 9.6812 17.9536 9.62738 17.941L5.72925 17.0215C5.67543 17.0089 5.67543 16.988 5.72925 16.9754L9.62738 16.0558C9.6812 16.0431 9.73557 15.9887 9.74829 15.9349L10.6676 12.037C10.6803 11.9833 10.7011 11.9833 10.7137 12.037L11.6332 15.9349ZM18.1655 9.0055C18.1781 9.05932 18.2326 9.11369 18.2863 9.12641L21.2569 9.82723C21.3107 9.83989 21.3107 9.8607 21.2569 9.87336L18.2863 10.5741C18.2326 10.5867 18.1781 10.6412 18.1655 10.695L17.4647 13.6655C17.452 13.7193 17.4312 13.7193 17.4186 13.6655L16.718 10.695C16.7053 10.6412 16.6509 10.5868 16.5971 10.5741L13.6264 9.87336C13.5726 9.8607 13.5726 9.83989 13.6264 9.82723L16.5971 9.12641C16.6509 9.11374 16.7053 9.05932 16.718 9.0055L17.4186 6.03498C17.4312 5.98116 17.452 5.98116 17.4647 6.03498L18.1655 9.0055Z"
          fill={fillColor}
        />
        <g opacity="0.02">
          <path
            d="M13.4313 6.36133C13.4851 6.37399 13.4851 6.3948 13.4313 6.40746L8.41477 7.5905C8.36095 7.60317 8.30658 7.65759 8.29387 7.71141L7.11037 12.728C7.09766 12.7817 7.0769 12.7817 7.06424 12.728L6.92795 12.1502C6.91529 12.0964 6.91529 12.0084 6.92795 11.9546L7.98431 7.47673C7.99697 7.42291 8.0514 7.36854 8.10522 7.35582L12.779 6.25364C12.8328 6.24097 12.9208 6.24097 12.9746 6.25364L13.4313 6.36133ZM15.6525 16.9756C15.7063 16.9883 15.7063 17.0091 15.6525 17.0217L11.7546 17.941C11.7008 17.9537 11.6464 18.0081 11.6337 18.0619L10.714 21.9597C10.7013 22.0135 10.6805 22.0135 10.6678 21.9597L10.5316 21.383C10.5189 21.3292 10.5188 21.2412 10.5316 21.1874L11.3241 17.8271C11.3368 17.7734 11.3912 17.7189 11.445 17.7063L15.0003 16.868C15.054 16.8553 15.1421 16.8552 15.1959 16.868L15.6525 16.9756ZM21.2571 9.82724C21.3109 9.8399 21.3109 9.86071 21.2571 9.87337L18.2869 10.574C18.2331 10.5867 18.1787 10.6411 18.166 10.6949L17.4654 13.6657C17.4527 13.7194 17.4319 13.7194 17.4193 13.6657L17.283 13.0879C17.2703 13.0341 17.2703 12.9461 17.283 12.8923L17.8565 10.4607C17.8691 10.4069 17.9235 10.3525 17.9773 10.3398L20.6054 9.71965C20.6591 9.70694 20.7472 9.70694 20.801 9.71965L21.2571 9.82724Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_38484_650603">
          <rect
            width="22"
            height="22"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
