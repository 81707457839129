import React, { useRef } from "react";
import { Image } from "antd-mobile";
import orderBy from "lodash/orderBy";
import cls from "classnames";
import { BIO_DISPLAY_STYLES } from "@share/configs/const";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Navigation, Pagination, Swiper as SwiperType, Autoplay } from "swiper";
import "swiper/css/autoplay";

const CoverImage = (props) => {
  const { content_attributes, style_attributes, paramsURL } = props;
  const { bio_cover_image_list, bio_image_ratio, bio_auto_switch } =
    content_attributes || {};
  const { style_display } = style_attributes;
  const swiperRef = useRef<SwiperType>(null);
  const dataImageSliderSortOrder =
    bio_cover_image_list &&
    orderBy(bio_cover_image_list, ["sort_order"], ["asc"]);

  const imageCoverEnable = dataImageSliderSortOrder?.filter(
    (x) => x.enable === 1
  );

  const avatarAboveCoverImage =
    style_display === BIO_DISPLAY_STYLES.STYLE5 ||
    style_display === BIO_DISPLAY_STYLES.STYLE6 ||
    style_display === BIO_DISPLAY_STYLES.STYLE7;

  return (
    <div className="relative mb-3 cursor-pointer">
      <Swiper
        className={cls(
          avatarAboveCoverImage && "custom-swiper-indicator",
          imageCoverEnable.length === 1 && "disable-swiper-indicator"
        )}
        modules={[Pagination, Navigation, Autoplay]}
        setWrapperSize={false}
        spaceBetween={12}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        pagination={{ clickable: true }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        loop={imageCoverEnable?.length > 1}
        // speed={50}
        autoplay={content_attributes?.bio_auto_switch ? { delay: 5000 } : false}
      >
        {imageCoverEnable?.map((ele, index) => (
          <SwiperSlide key={ele?.uid}>
            <Image
              src={ele.image}
              fit="cover"
              style={{
                backgroundSize: "cover",
                aspectRatio: bio_image_ratio,
                pointerEvents: paramsURL?.isDisableLink ? "none" : "unset",
              }}
              className="image-border-radius"
              // onClick={() => {
              //   handleClick(ele, true);
              // }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default CoverImage;
