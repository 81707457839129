import { useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import {
  LINK_LAYOUTS,
  LINK_STYLE_DISPLAY,
  LINK_TEMPLATE,
} from "@share/configs/const";
import React from "react";
import BlockWrapper from "../../BlockWrapper";
import LinkLayouts from "./LinkLayouts";
import BlockHeading from "../../BlockHeadingComponent";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isMultiLink: boolean;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const setDefaultValueStyleAttributes = (styleAttributes) => {
  const newStyleAttributes = {
    ...styleAttributes,
  };
  if (!newStyleAttributes.button_link_template) {
    newStyleAttributes.button_link_template = LINK_TEMPLATE.TEMPLATE2;
  }
  if (!newStyleAttributes.button_link_layout) {
    newStyleAttributes.button_link_layout = LINK_LAYOUTS.TEXT_RIGHT;
  }
  if (!newStyleAttributes.button_link_style) {
    newStyleAttributes.button_link_style = LINK_STYLE_DISPLAY.TEXT_AND_ICON;
  }
  return newStyleAttributes;
};

const BlockButtonLink = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const content_attributes =
    blockData && blockData.content_attributes && blockData.content_attributes;

  const style_attributes = setDefaultValueStyleAttributes(
    blockData.style_attributes
  );

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <BlockHeading
        title={blockData?.content_attributes?.block_title}
        blockType={blockData.block_type}
        subtitle={blockData?.content_attributes?.block_description}
      />
      <LinkLayouts
        content_attributes={content_attributes}
        style_attributes={style_attributes}
        isMultiLink={false}
      />
    </BlockWrapper>
  );
};

export default BlockButtonLink;
