import { CardInterface } from "@features/main/store/interface";
import { SLIDE_STYLE } from "@share/configs/const";
import React, { useCallback } from "react";
import { StyleAttributesInterface } from "../../CardDetail";
import CardNotPartialVisible from "./CardNotPartialVisible";
import CardPanorama from "./CardPanorama/index";
import CardPartialVisibleRight from "./CardPartialVisibleRight";

type Props = {
  cardItemArr: CardInterface[];
  style_attibutes: StyleAttributesInterface;
  see_more_btn_content: string;
};

export const CARD_SLIDE_TYPE_COMPONENT = {
  [SLIDE_STYLE.NOT_PARTIAL_VISIBLE]: CardNotPartialVisible,
  [SLIDE_STYLE.PANORAMA]: CardPanorama,
  [SLIDE_STYLE.PARTIAL_VISIBLE_RIGHT]: CardPartialVisibleRight,
};

const CardStyleSlide = (props: Props) => {
  const { cardItemArr, style_attibutes, see_more_btn_content } = props;
  const { card_slide_type } = style_attibutes || {};

  const CardSlideTypeRender = useCallback((props) => {
    let Element = <></>;
    if (CARD_SLIDE_TYPE_COMPONENT[props?.card_slide_type]) {
      const RenderComponent = CARD_SLIDE_TYPE_COMPONENT[props?.card_slide_type];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);
  return (
    <CardSlideTypeRender
      card_slide_type={card_slide_type}
      cardItemArr={cardItemArr}
      style_attibutes={style_attibutes}
      see_more_btn_content={see_more_btn_content}
    />
  );
};

export default CardStyleSlide;
