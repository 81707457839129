import React, { useEffect, useState } from "react";
import ListItemScrollHorizontal from "@features/design/components/ListItemScrollHorizontal";
import ProductGroupItemDetail from "./ProductGroupItemDetail";
import GroupAddNewButton from "./GroupAddNewButton";
import { useAppDispatch } from "@app/hooks";
import { updateBlock } from "@features/main/store/positionsSlice";
import { Button } from "antd-mobile";
import cls from "classnames";
import {
  AngleDownIcon,
  DownIcon,
  DropDownIcon1,
  DropDownIcon2,
} from "@share/icons";
import PopupComponent from "@share/components/PopupComponent";
import ProductGroupListSelector from "@features/design/components/CommonBlockComponent/BlockAffiliateProductGroup/ProductGroupListSelector";

import { useTranslation } from "react-i18next";
import { STATUS } from "@share/configs/const";
import { Block } from "@features/main/store/interface";

interface Props {
  productGroupId: string;
  onSelectGroupId: (val) => void;
  // productGroupListData: any;
  affiliateProductGroupData: any;
  blockData: any;
  isCreatedNew: boolean;
  tabUid: string;
  positionSlug: string;
  productGroupMaximum: number;
  onGetLatestBlockData?: (block: Block) => void;
}

const ProductGroupHandler = ({
  productGroupId,
  onSelectGroupId,
  // productGroupListData,
  affiliateProductGroupData,
  blockData,
  isCreatedNew,
  tabUid,
  positionSlug,
  productGroupMaximum,
  onGetLatestBlockData = () => {},
}: Props) => {
  const dispatch = useAppDispatch();
  const [newGroupId, setNewGroupId] = useState(null);
  const isDisplayProductGroupListSelectorBtn =
    affiliateProductGroupData?.length >= 6;
  const [isOpenProductGroupListSelector, setIsOpenProductGroupListSelector] =
    useState(false);
  const { t } = useTranslation();

  const optionData = affiliateProductGroupData?.map((ele) => {
    const productQuantity =
      ele?.content_attributes?.items?.filter((y) => y.enable === 1)?.length ||
      0;
    return {
      label:
        ele?.enable === STATUS.ENABLE ? (
          `${ele?.title} (${productQuantity})`
        ) : (
          <span className="opacity-40">{`${ele?.title} (${productQuantity})`}</span>
        ),
      value: ele?.uid,
    };
  });

  useEffect(() => {
    setNewGroupId(null);
  }, [productGroupId]);

  useEffect(() => {
    if (newGroupId) {
      onSelectGroupId(newGroupId);
    } else if (productGroupId) {
      onSelectGroupId(productGroupId);
    } else onSelectGroupId(null);
  }, [newGroupId, affiliateProductGroupData]);

  const handleAddNewProductGroup = (data) => {
    const newAffiliateProductGroupData = [...affiliateProductGroupData, data];
    const newAffiliateProductGroupDataSortOrder =
      newAffiliateProductGroupData.map((ele, idx) => ({
        ...ele,
        sort_order: idx + 1,
      }));
    const newDataAffiliateProduct = {
      ...blockData,
      content_attributes: {
        ...blockData.content_attributes,
        items: newAffiliateProductGroupDataSortOrder,
        is_created_new: isCreatedNew,
      },
    };
    if (positionSlug) {
      dispatch(
        updateBlock({
          tabUid,
          positionSlug,
          blockData: newDataAffiliateProduct,
        })
      );
    }
    onGetLatestBlockData(newDataAffiliateProduct);
    setNewGroupId(data?.uid);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between relative">
        <ListItemScrollHorizontal
          className={cls(isDisplayProductGroupListSelectorBtn && "mr-[32px]")}
        >
          {affiliateProductGroupData.map((item, index) => (
            <ProductGroupItemDetail
              key={index}
              item={item}
              onSelect={(ele) => {
                onSelectGroupId(
                  productGroupId && productGroupId === item.uid
                    ? null
                    : item.uid
                );
              }}
              // listItemsDefault={affiliateProductGroupData}
              selected={productGroupId === item?.uid}
            />
          ))}
        </ListItemScrollHorizontal>
        {isDisplayProductGroupListSelectorBtn && (
          <Button
            style={{
              padding: "4px 8px",
              borderRadius: "0px 1000px 1000px 0px",
              position: "absolute",
              right: "40px",
              // top: "0px",
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              height: "full",
              border: "none",
              overflow: "hidden",
            }}
            onClick={() => {
              setIsOpenProductGroupListSelector(true);
            }}
          >
            <AngleDownIcon height={23} />
          </Button>
        )}

        <GroupAddNewButton
          affiliateProductGroupData={affiliateProductGroupData}
          onAddNewProductGroup={handleAddNewProductGroup}
          productGroupMaximum={productGroupMaximum}
        />
      </div>

      <PopupComponent
        visible={isOpenProductGroupListSelector}
        onMaskClick={() => setIsOpenProductGroupListSelector(false)}
        title={t("ss_builder_affiliate_product_group_list_selector_label")}
        onClose={() => setIsOpenProductGroupListSelector(false)}
      >
        <ProductGroupListSelector
          options={optionData}
          columns={3}
          valueSelectItem={productGroupId}
          onSelectItem={onSelectGroupId}
          onClose={() => setIsOpenProductGroupListSelector(false)}
        />
      </PopupComponent>
    </>
  );
};

export default ProductGroupHandler;
