import { useEffect, useState } from "react";
import { getMeta } from "../libs";

export const useGetSizeImageByUrl = (url: string) => {
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  useEffect(() => {
    const getBackgorundImageWidth = async () => {
      const imageUrl = url;
      const img = (await getMeta(imageUrl)) as any;
      setImageWidth(img?.width);
      setImageHeight(img?.height);
    };
    getBackgorundImageWidth();
  }, [url]);
  return { width: imageWidth, height: imageHeight };
};
