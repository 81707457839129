import { useAppDispatch, useAppSelector } from "@app/hooks";
import {
  Button,
  Divider,
  NavBar,
  NoticeBar,
  SearchBar,
} from "antd-mobile";
import React, { useMemo, useState } from "react";
import Categories from "./components/Categories";
import { BackAngelIcon, SearchIcon } from "@share/icons";
import {
  BlockSelectionInterface,
  BLOCK_SELECTION_GROUP,
} from "@features/block/config/block-selection";
import { POSITION_TYPE } from "@features/block/config/interface";
import { openPopup } from "@features/block/store/edittingBlockSlice";
import { useAppTranslation } from "@features/mini-store/share/hook";
import { BLOCK_FILTER_GROUP_TYPE, KEY_BLOCK } from "@share/configs/const";
import { } from "@share/icons";
import { handleCapitalizeFirstLetter } from "@share/lib";
import gaEvents, {
  ACTION_CONFIG,
  BLOCK_TYPE_EVENT_CONFIG,
  CATEGORY_CONFIG,
  LABEL_CONFIG,
} from "@share/lib/ga-events";
import BlockGroup from "./components/BlockGroup";
import { EXPAND_POSITION } from "@features/design/design.config";
import BlockHighlight from "./components/BlockHighlight";

type Props = {
  cancleFP: any;
  positionSlug: string;
  tabUid: string;
  tabListBody: any;
  backFP: any;
  sortOrder?: number;
};

export const OutLineInfoIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49968 0.83252C6.18114 0.83252 4.8922 1.22351 3.79588 1.95606C2.69955 2.6886 1.84506 3.72979 1.34048 4.94796C0.835896 6.16614 0.703874 7.50658 0.961109 8.79979C1.21834 10.093 1.85328 11.2809 2.78563 12.2132C3.71798 13.1456 4.90587 13.7805 6.19908 14.0378C7.49228 14.295 8.83273 14.163 10.0509 13.6584C11.2691 13.1538 12.3103 12.2993 13.0428 11.203C13.7754 10.1067 14.1663 8.81773 14.1663 7.49919C14.1663 6.62371 13.9939 5.7568 13.6589 4.94796C13.3238 4.13913 12.8328 3.4042 12.2137 2.78514C11.5947 2.16608 10.8597 1.67502 10.0509 1.33999C9.24206 1.00496 8.37516 0.83252 7.49968 0.83252ZM8.16634 9.83252C8.16634 10.0093 8.09611 10.1789 7.97108 10.3039C7.84606 10.4289 7.67649 10.4992 7.49968 10.4992C7.32287 10.4992 7.1533 10.4289 7.02827 10.3039C6.90325 10.1789 6.83301 10.0093 6.83301 9.83252V7.83252C6.83301 7.65571 6.90325 7.48614 7.02827 7.36112C7.1533 7.23609 7.32287 7.16585 7.49968 7.16585C7.67649 7.16585 7.84606 7.23609 7.97108 7.36112C8.09611 7.48614 8.16634 7.65571 8.16634 7.83252V9.83252ZM7.49968 6.16585C7.33486 6.16585 7.17374 6.11698 7.0367 6.02541C6.89966 5.93384 6.79285 5.80369 6.72978 5.65142C6.66671 5.49915 6.6502 5.33159 6.68236 5.16994C6.71451 5.00829 6.79388 4.85981 6.91042 4.74326C7.02697 4.62672 7.17545 4.54735 7.3371 4.5152C7.49875 4.48304 7.66631 4.49955 7.81858 4.56262C7.97085 4.62569 8.101 4.7325 8.19257 4.86954C8.28414 5.00659 8.33301 5.1677 8.33301 5.33252C8.33301 5.55353 8.24521 5.76549 8.08893 5.92178C7.93265 6.07806 7.72069 6.16585 7.49968 6.16585Z"
      fill="#1890FF"
    />
  </svg>
);

export const EmptySearch = ({ className }) => {
  return (
    <svg
      width="260"
      height="128"
      viewBox="0 0 432 431"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M316.151 309.04L286.911 335.49L259.151 304.19C264.518 300.387 269.664 296.28 274.561 291.89C279.44 287.457 284.051 282.738 288.371 277.76L316.151 309.04Z"
        fill="#EDEDED"
      />
      <path
        d="M391.491 422.66C388.44 425.423 384.873 427.557 380.995 428.941C377.118 430.324 373.006 430.93 368.894 430.723C364.782 430.516 360.752 429.5 357.033 427.734C353.314 425.968 349.98 423.485 347.221 420.43L279.911 345.14C279.048 344.185 278.38 343.07 277.948 341.858C277.516 340.646 277.326 339.36 277.391 338.074C277.456 336.789 277.774 335.529 278.327 334.366C278.879 333.204 279.656 332.162 280.611 331.3L312.611 302.38C313.564 301.514 314.679 300.845 315.891 300.411C317.103 299.977 318.389 299.786 319.675 299.851C320.961 299.915 322.221 300.232 323.384 300.784C324.547 301.337 325.589 302.113 326.451 303.07L393.761 378.4C396.522 381.453 398.653 385.021 400.033 388.899C401.413 392.777 402.015 396.889 401.804 401C401.594 405.111 400.574 409.14 398.805 412.857C397.035 416.573 394.55 419.904 391.491 422.66V422.66Z"
        fill="#CBCBCB"
      />
      <path
        d="M291.402 60.8595C229.492 -7.58048 123.402 -12.8505 54.9517 49.0695C29.9193 71.7961 12.3443 101.561 4.534 134.457C-3.27628 167.352 -0.95673 201.841 11.1881 233.394C23.333 264.948 44.7354 292.091 72.5856 311.261C100.436 330.431 133.432 340.731 167.242 340.81C259.312 340.81 334.902 265.24 334.902 173.23C334.877 131.667 319.369 91.6048 291.402 60.8595V60.8595ZM250.322 265.02C232.154 281.444 209.525 292.113 185.295 295.678C161.065 299.244 136.321 295.545 114.193 285.051C92.0643 274.556 73.5442 257.736 60.9736 236.717C48.403 215.698 42.3464 191.424 43.5694 166.963C44.7924 142.503 53.2402 118.954 67.8447 99.2936C82.4493 79.6335 102.555 64.7447 125.62 56.5095C148.685 48.2743 173.674 47.0624 197.427 53.027C221.181 58.9916 242.633 71.8649 259.072 90.0195C281.117 114.386 292.581 146.513 290.94 179.332C289.299 212.15 274.688 242.973 250.322 265.02V265.02Z"
        fill="#CBCBCB"
      />
      <path
        d="M250.312 265.02C232.144 281.439 209.516 292.104 185.289 295.667C161.061 299.229 136.321 295.528 114.196 285.032C92.071 274.536 73.5544 257.716 60.9869 236.699C48.4194 215.682 42.3652 191.41 43.5895 166.952C44.8138 142.495 53.2616 118.949 67.8652 99.2918C82.4687 79.6345 102.572 64.748 125.635 56.5141C148.697 48.2802 173.683 47.0686 197.434 53.0323C221.185 58.9961 242.635 71.8675 259.072 90.0196C269.994 102.082 278.433 116.179 283.904 131.504C289.376 146.829 291.773 163.083 290.96 179.335C290.146 195.587 286.138 211.52 279.163 226.223C272.188 240.925 262.384 254.108 250.312 265.02V265.02Z"
        fill="#EDEDED"
      />
      <path
        d="M278.151 175.399L273.851 198.039C273.65 199.097 273.727 200.189 274.075 201.208C274.424 202.227 275.032 203.137 275.839 203.85C276.646 204.562 277.626 205.052 278.68 205.271C279.734 205.49 280.827 205.431 281.851 205.099L306.591 197.229C317.667 202.285 329.755 204.731 341.924 204.38C354.093 204.029 366.02 200.891 376.786 195.206C387.552 189.521 396.869 181.442 404.022 171.59C411.174 161.738 415.97 150.376 418.041 138.379C420.112 126.382 419.403 114.069 415.967 102.389C412.532 90.7098 406.462 79.9741 398.225 71.009C389.988 62.0439 379.804 55.0884 368.456 50.678C357.109 46.2676 344.901 44.5199 332.771 45.5694C294.561 48.7794 263.601 79.6794 260.371 117.889C258.508 138.651 264.895 159.312 278.151 175.399V175.399Z"
        fill="#B4B4B4"
      />
      <path
        d="M284.621 123.359C284.621 134.237 287.847 144.871 293.89 153.916C299.934 162.96 308.524 170.01 318.574 174.173C328.623 178.336 339.682 179.425 350.351 177.303C361.02 175.18 370.82 169.942 378.512 162.25C386.204 154.558 391.442 144.758 393.564 134.089C395.687 123.42 394.597 112.362 390.434 102.312C386.272 92.2619 379.222 83.672 370.178 77.6286C361.133 71.5851 350.499 68.3594 339.621 68.3594C332.396 68.3515 325.241 69.7688 318.564 72.53C311.888 75.2912 305.821 79.3421 300.713 84.4509C295.604 89.5597 291.553 95.626 288.792 102.302C286.03 108.979 284.613 116.134 284.621 123.359V123.359Z"
        fill="#EDEDED"
      />
      <path
        d="M387.151 35.0095C386.295 34.6135 385.631 33.8948 385.303 33.0104C384.975 32.126 385.01 31.1478 385.401 30.2895L397.241 4.38946C397.438 3.96398 397.717 3.58147 398.062 3.26376C398.407 2.94604 398.811 2.69935 399.251 2.53777C399.691 2.3762 400.159 2.30291 400.627 2.32208C401.096 2.34124 401.556 2.45249 401.981 2.64947C402.407 2.84645 402.789 3.1253 403.107 3.47012C403.425 3.81493 403.671 4.21895 403.833 4.6591C403.994 5.09925 404.068 5.56692 404.049 6.03539C404.029 6.50387 403.918 6.96398 403.721 7.38946L391.871 33.2895C391.472 34.142 390.751 34.8017 389.866 35.124C388.982 35.4463 388.006 35.4052 387.151 35.0095V35.0095Z"
        fill="#C7C7C7"
      />
      <path
        d="M363.071 31.4894L353.151 4.83942C352.982 4.40027 352.901 3.93192 352.914 3.46151C352.927 2.99109 353.034 2.52792 353.227 2.09879C353.42 1.66966 353.696 1.28309 354.04 0.961431C354.383 0.639769 354.787 0.389399 355.228 0.224813C355.669 0.0602273 356.138 -0.0153041 356.608 0.0025688C357.079 0.0204417 357.541 0.131364 357.968 0.328939C358.395 0.526514 358.778 0.806809 359.097 1.15362C359.415 1.50042 359.661 1.90686 359.821 2.34942L369.761 29.0394C370.091 29.9252 370.056 30.9059 369.663 31.7658C369.27 32.6257 368.552 33.2942 367.666 33.6244C366.78 33.9546 365.799 33.9194 364.939 33.5265C364.08 33.1336 363.411 32.4152 363.081 31.5294L363.071 31.4894Z"
        fill="#C7C7C7"
      />
      <path
        d="M397.552 47.2996C397.389 46.861 397.313 46.3945 397.331 45.9269C397.348 45.4592 397.457 44.9996 397.652 44.5742C397.847 44.1488 398.124 43.766 398.467 43.4478C398.81 43.1296 399.213 42.8821 399.652 42.7196L426.332 32.7896C426.773 32.6086 427.247 32.5182 427.724 32.5239C428.202 32.5297 428.673 32.6313 429.11 32.8229C429.548 33.0145 429.942 33.2921 430.27 33.6391C430.597 33.9861 430.852 34.3956 431.019 34.843C431.185 35.2905 431.26 35.7668 431.238 36.2437C431.217 36.7206 431.1 37.1883 430.894 37.619C430.688 38.0498 430.397 38.4346 430.04 38.7508C429.682 39.067 429.264 39.3081 428.812 39.4596L402.152 49.3996C401.712 49.5659 401.244 49.6434 400.774 49.6277C400.304 49.6121 399.842 49.5035 399.414 49.3082C398.986 49.113 398.602 48.8349 398.282 48.4902C397.962 48.1454 397.714 47.7408 397.552 47.2996V47.2996Z"
        fill="#C7C7C7"
      />
      <path
        d="M339.601 131.969C339.133 131.971 338.669 131.88 338.236 131.701C337.803 131.523 337.409 131.261 337.078 130.93C336.746 130.6 336.483 130.207 336.303 129.774C336.124 129.342 336.031 128.878 336.031 128.409C336.211 127.649 334.621 119.239 342.561 115.929C344.017 115.32 345.253 114.282 346.103 112.953C346.954 111.623 347.379 110.067 347.321 108.489C347.218 106.555 346.403 104.727 345.033 103.358C343.664 101.988 341.836 101.173 339.901 101.069C338.864 101.035 337.83 101.209 336.862 101.581C335.893 101.954 335.009 102.518 334.263 103.239C333.517 103.96 332.923 104.824 332.517 105.779C332.112 106.735 331.902 107.762 331.901 108.799C331.901 109.267 331.809 109.73 331.63 110.162C331.451 110.594 331.189 110.986 330.859 111.317C330.528 111.647 330.136 111.91 329.704 112.088C329.272 112.267 328.809 112.359 328.341 112.359C327.874 112.359 327.411 112.267 326.979 112.088C326.547 111.91 326.155 111.647 325.824 111.317C325.493 110.986 325.231 110.594 325.052 110.162C324.873 109.73 324.781 109.267 324.781 108.799C324.772 106.105 325.496 103.459 326.875 101.144C328.254 98.8297 330.236 96.9335 332.61 95.6585C334.983 94.3836 337.659 93.778 340.35 93.9066C343.041 94.0351 345.647 94.8929 347.888 96.3882C350.13 97.8835 351.922 99.96 353.074 102.396C354.226 104.831 354.695 107.534 354.429 110.215C354.163 112.896 353.173 115.455 351.566 117.617C349.958 119.779 347.793 121.463 345.301 122.489C342.301 123.719 343.361 127.109 343.161 128.389C343.164 128.859 343.074 129.324 342.896 129.758C342.718 130.192 342.457 130.587 342.126 130.92C341.795 131.252 341.402 131.516 340.968 131.697C340.535 131.877 340.07 131.97 339.601 131.969V131.969Z"
        fill="#B4B4B4"
      />
      <path
        d="M345.151 143.6C345.153 144.704 344.827 145.784 344.215 146.703C343.603 147.623 342.731 148.339 341.711 148.763C340.691 149.186 339.568 149.298 338.485 149.083C337.402 148.868 336.407 148.336 335.626 147.555C334.845 146.774 334.313 145.779 334.098 144.696C333.883 143.612 333.994 142.49 334.418 141.47C334.841 140.45 335.558 139.578 336.477 138.966C337.396 138.353 338.477 138.028 339.581 138.03C341.058 138.032 342.473 138.62 343.517 139.664C344.561 140.708 345.149 142.123 345.151 143.6Z"
        fill="#B4B4B4"
      />
    </svg>
  );
};

export default function BlockSelectionV2(props: Props) {
  const { backFP, cancleFP, positionSlug, tabUid, sortOrder, tabListBody } =
    props;
  const tabname = tabListBody?.find((item) => item.uid === tabUid)?.name;
  const { t } = useAppTranslation();

  const [selectedGroup, setSelectedGroup] = useState(
    BLOCK_FILTER_GROUP_TYPE.ALL
  );

  const [keyWord, setKeyWord] = useState("");
  const [value, setValue] = useState("");
  const dispatch = useAppDispatch();
  const positions = useAppSelector((state) => state.positions);
  const selectedPositionId = useMemo(() => {
    const position = positions.find((item) => item.slug == positionSlug);
    return position.id;
  }, [positionSlug, positions]);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleSelectItem = (block: BlockSelectionInterface) => {
    const blockType =
      BLOCK_TYPE_EVENT_CONFIG[block?.block_type?.toUpperCase()] ||
      handleCapitalizeFirstLetter(block.block_type);
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.BLOCK_TYPE,
      `BlockType_Select_${blockType}`,
      blockType,
      ACTION_CONFIG.SELECT
    );
    dispatch(
      openPopup({
        block: { block_type: block.block_type },
        position_uid: positionSlug,
        tab_uid: tabUid,
        KEY: KEY_BLOCK.ADD_NEW,
        position_id: selectedPositionId,
        sort_order: sortOrder,
      })
    );
  };

  const groupData = useMemo(() => {
    const allCategory = {
      value: BLOCK_FILTER_GROUP_TYPE.ALL,
      label: t("ss_builder_category_all"),
    };
    return [
      allCategory,
      ...BLOCK_SELECTION_GROUP.map((group) => ({
        value: group.slug,
        label: t(group.name_key),
      })),
    ];
  }, [BLOCK_SELECTION_GROUP, t]);

  const blockGroups = useMemo(() => {
    if (Object.is(positionSlug, POSITION_TYPE.EXPAND)) {
      const filteredBlockGroups = BLOCK_SELECTION_GROUP.filter((group) => {
        if (
          !Object.is(BLOCK_FILTER_GROUP_TYPE.ALL, selectedGroup) &&
          !Object.is(selectedGroup, group.slug)
        ) {
          return false;
        }

        const isIncludeExpandBlock = group.block_list.find((e) =>
          EXPAND_POSITION.includes(e.block_type)
        );

        return !!isIncludeExpandBlock;
      });

      let expandBlockGroups = filteredBlockGroups.map((group) => {
        const blocks = group.block_list.filter((block) =>
          EXPAND_POSITION.includes(block.block_type)
        );
        return {
          ...group,
          block_list: blocks,
        };
      });

      return expandBlockGroups;
    }

    const groups = BLOCK_SELECTION_GROUP.map((group) => {
      const blocks = group.block_list.filter(
        (block) => !EXPAND_POSITION.includes(block.block_type)
      );
      return {
        ...group,
        block_list: blocks,
      };
    });

    if (selectedGroup && selectedGroup !== BLOCK_FILTER_GROUP_TYPE.ALL) {
      return groups.filter((group) => group.slug == selectedGroup);
    }
    return groups;
  }, [BLOCK_SELECTION_GROUP, selectedGroup]);

  const searchedGroups = useMemo(() => {
    if (!keyWord) {
      return blockGroups;
    }
    return blockGroups
      .map((group) => {
        const blockList = group.block_list.filter((block) => {
          if (
            t(block.name_key)
              .toLowerCase()
              .includes(keyWord.toLowerCase().trim())
          ) {
            return true;
          }
          if (
            t(block.description_key)
              .toLowerCase()
              .includes(keyWord.toLowerCase().trim())
          ) {
            return true;
          }
          return false;
        });
        return { ...group, block_list: blockList };
      })
      .filter((group) => group.block_list.length > 0);
  }, [blockGroups, keyWord, t]);

  const getPositionText = (slug: string) => {
    let content = "";
    switch (slug) {
      case POSITION_TYPE.HEADER:
      case POSITION_TYPE.FOOTER:
      case POSITION_TYPE.EXPAND:
        content = ` ${t(`ss_builder_add_block_${slug}_title`)}`;
        break;
      case POSITION_TYPE.BODY:
        content =
          tabListBody.length > 1
            ? ` ${tabname}`
            : ` ${t("ss_builder_body_title")}`;
        break;
    }
    return content;
  };

  const noticeBarContent = useMemo(() => {
    return (
      <div
        className="text-center w-full px-2"
        style={{
          lineHeight: 1.31,
          color: "#333",
          fontWeight: 400,
          fontSize: "13px",
        }}
      >
        <div className="flex items-center justify-center">
          <span className="mr-2">
            <OutLineInfoIcon />
          </span>
          <span>
            {t("ss_builder_add_block_to")}
            <span
              className="text-bold"
              style={{ fontWeight: "bolder", lineHeight: "17px" }}
            >
              {getPositionText(props.positionSlug)}
            </span>
          </span>
        </div>
      </div>
    );
  }, [t]);

  const handleClearKeyWord = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.BLOCK_TYPE,
      BLOCK_TYPE_EVENT_CONFIG.BLOCK_TYPE_SEARCH_CLEAR,
      LABEL_CONFIG.CLEAR_SEARCH,
      ACTION_CONFIG.SEARCH
    );
  };

  const isDisplayBlockHighlight =
    selectedGroup === BLOCK_FILTER_GROUP_TYPE.ALL &&
    !keyWord &&
    positionSlug !== POSITION_TYPE.EXPAND;

  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 99,
        }}
      >
        <NavBar
          className="bg-white sticky"
          backArrow={<BackAngelIcon />}
          right={
            <Button
              onClick={cancleFP}
              type="button"
              color="primary"
              fill="none"
            >
              {t("ss_builder_cancel")}
            </Button>
          }
          onBack={backFP}
        >
          {t("ss_builder_panel_title")}
        </NavBar>

        <div className="flex w-full bg-white search-block h-[36px] p-3 pb-0">
          <SearchBar
            icon={() => null}
            placeholder={t("ss_builder_search_type_block")}
            onChange={(val) => {
              setValue(val);
            }}
            onSearch={(val)=> {
              setKeyWord(val)
              gaEvents.submitEventSearch(
                  window.location.origin,
                  "addBlock",
                  val
              );
            }}
            style={{
              width: "100%",
            }}
            onClear={handleClearKeyWord}
          />
          <div
            className="bg-[#EE3244] flex h-43 w-43 cursor-pointer"
            style={{
              width: "43px",
              height: "36px",
              borderRadius: "0 6px 6px 0",
            }}
            onClick={() => {
              setKeyWord(value);
              gaEvents.submitEventSearch(
                window.location.origin,
                "addBlock",
                value
              );
            }}
          >
            <SearchIcon className="m-auto" />
          </div>
        </div>
        <div className="bg-white p-3">
          <Categories
            items={groupData}
            onChange={setSelectedGroup}
            selectedGroup={selectedGroup}
          />
        </div>
        <Divider style={{ margin: 0 }} />
      </div>
      <div className="bg-white p-3">
        {/*<Divider style={{ margin: "0px 0px 12px 0px" }} />*/}
        <NoticeBar
          style={{
            border: "1px dashed #91D5FF",
            borderRadius: "8px",
            backgroundColor: " #E6F7FF",
          }}
          className="block-selection-tab-bar"
          icon={<></>}
          color="info"
          content={noticeBarContent}
        />
        {isDisplayBlockHighlight && (
          <>
            <div className="mt-5"></div>
            <BlockHighlight onSelectItem={handleSelectItem} />
          </>
        )}
      </div>
      <div>
        {searchedGroups.map((group) => (
          <BlockGroup
            key={group.slug}
            group={group}
            onSelectBlock={handleSelectItem}
            useAppSelector={useAppSelector}
            positionSlug={positionSlug}
            tabUid={tabUid}
          />
        ))}
        {searchedGroups.length === 0 && (
          <div>
            {/* <Image
              className="items-center flex mx-auto"
              style={{ width: "260px", height: "128px" }}
              fit="cover"
              src="https://image.passio.eco/page-builder/page-builder/images-default/empty-search.png"
            /> */}
            <EmptySearch className="items-center flex mx-auto mt-6 mb-2" />

            <div
              className="text-base text-center text-black text-neutral-900 font-medium"
              style={{ fontSize: "15.6px" }}
            >
              {t("ss_builder_empty_search")}
            </div>
            <div
              className="text-center text-sm font-normal text-neutral-500"
              style={{ fontSize: "13px" }}
            >
              {t("ss_builder_try_new_key")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
