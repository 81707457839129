import React from "react";
import { useTranslation } from "react-i18next";

const SeeMoreIcon = ({
  className = "",
  width = 25,
  height = 25,
  fillColor = "#FFF",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 25 25"
      className={className}
    >
      <g clipPath="url(#clip0_5885_756968)">
        <path
          fill="#fff"
          fillOpacity="0.01"
          d="M0 0H24V24H0z"
          transform="translate(.6 .499)"
        ></path>
        <rect
          width="24"
          height="24"
          x="0.601"
          y="0.499"
          fill={fillColor}
          rx="12"
        ></rect>
        <path
          fill="#fff"
          d="M16.14 11.789l-5.66-5.65a.999.999 0 00-1.42 0 1 1 0 000 1.41l4.95 5-4.95 4.95a1 1 0 000 1.41 1 1 0 00.71.3 1.001 1.001 0 00.71-.3l5.66-5.65a1 1 0 000-1.47z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5885_756968">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(.6 .499)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};
const SeeAll = ({ height, handleClickShowAll }) => {
  const { t } = useTranslation();

  return (
    <div
      className="h-full flex items-center flex-col justify-center cursor-pointer"
      style={{ height: height }}
      onClick={handleClickShowAll}
    >
      <SeeMoreIcon
        fillColor="var(--adm-color-primary)"
        className="cursor-pointer"
      />
      <div
        className="mt-2 text-[13px] font-normal leading-[131%] cursor-pointer"
        style={{ color: "var(--adm-color-primary)" }}
      >
        {t("ss_builder_product_swiper_see_all")}
      </div>
    </div>
  );
};

export default SeeAll;
