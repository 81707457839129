import { TAB_WIDTH_DISPLAY } from "@share/configs/const";
import React, { useCallback } from "react";
import AutoTabWidth from "./AutoTabWidth";
import EqualTabWidth from "./EqualTabWidth";

export interface StyleAttributesInterface {
  display_style: string;
  ghim_style: string;
  position_style: string;
  tab_layout: string;
  tab_width_display: string;
}

type Props = {
  style_attributes: StyleAttributesInterface;
  tabListBody: any;
  onUpdateTabActive: any;
  tabActiveDefault: string;
  onDoubleTab: () => void;
};

export const TAB_WIDTH_COMPONENT = {
  [TAB_WIDTH_DISPLAY.AUTO]: AutoTabWidth,
  [TAB_WIDTH_DISPLAY.EQUAL]: EqualTabWidth,
};

const TabWidthStyleDisplay = (props: Props) => {
  const TabWidthLayoutRender = useCallback((props) => {
    let Element = <></>;
    if (TAB_WIDTH_COMPONENT[props?.style_attributes?.tab_width_display]) {
      const RenderComponent =
        TAB_WIDTH_COMPONENT[props?.style_attributes?.tab_width_display];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);

  return <TabWidthLayoutRender {...props} />;
};

export default TabWidthStyleDisplay;
