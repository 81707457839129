import { InformationIcon } from "@share/icons";
import { handleCapitalizeFirstLetter } from "@share/lib";
import gaEvents, { CATEGORY_CONFIG, LABEL_CONFIG } from "@share/lib/ga-events";
import { Modal } from "antd-mobile";
import isEqual from "lodash/isEqual";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {useAppSelector} from "@app/hooks";

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useHasChange = (dependencies: any[]) => {
  const myPreviousState = usePrevious(dependencies);
  const [hasChange, setHasChange] = useState(false);
  useEffect(() => {
    if (myPreviousState && !isEqual(myPreviousState, dependencies)) {
      setHasChange(true);
    }
  }, dependencies);
  useEffect(() => {
    setHasChange(false);
  }, []);
  return hasChange;
};

// export function useHasChange(dependencies: any[]) {
//     const [hasChange, setHasChange] = useState(false);
//     useEffect(() => {
//         setHasChange(true);
//     }, dependencies);

//     useEffect(() => {
//         setHasChange(false);
//     }, []);
//     return hasChange;
// }

export function useOnCancel(isEdit, onCancel, type) {
  const { t } = useTranslation();
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const handleCancelBlock = () => {
    if (isEdit) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_edit_block_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_edit_block_content")}
          </div>
        ),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          onCancel();
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.THEME_SETTINGS,
            `ThemeSettings_${handleCapitalizeFirstLetter(type)}_${
              LABEL_CONFIG.CANCEL
            }`,
            LABEL_CONFIG.CANCEL,
            handleCapitalizeFirstLetter(type)
          );
        },
      });
    } else {
      onCancel();
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.THEME_SETTINGS,
        `ThemeSettings_${handleCapitalizeFirstLetter(type)}_${
          LABEL_CONFIG.CANCEL
        }`,
        LABEL_CONFIG.CANCEL,
        handleCapitalizeFirstLetter(type)
      );
    }
  };
  return handleCancelBlock;
}
