import { useAppDispatch, useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import {
  AFFILIATE_LAYOUT_MAX_HEIGHT,
  PRODUCT_GROUP_MAXIMUM,
  STATUS,
} from "@share/configs/const";
import { SearchBar } from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";
import ProductListOfGroupDisplay from "./ProductListOfGroupDisplay";
import AddNewProductButton from "./AddNewProductButton";
import ProductInfiniteScroll from "./ProductInfiniteScroll";
import ProductGroupHandler from "./ProductGroupHandler";
import ExpandBlockHandler from "../../ExpandBlockHandler";
import { dataService } from "@share/configs/rxjs";
import {
  updateBlock,
  updateTabsBody,
} from "@features/main/store/positionsSlice";
import { AffiliateContext } from "@features/block/block-editor/AffiliateProductGroup/context";
import { runMiddleware } from "@features/block/components/BlockEditV2";
import cloneDeep from "lodash/cloneDeep";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
  onGetLatestBlockData?: (data: Block) => void;
};

const BlockAffiliateProductGroup = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
    onGetLatestBlockData = () => {},
  } = props;

  const enable = blockData && blockData.enable;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const content_attributes = blockData && blockData.content_attributes;

  const {
    title_product_group,
    items: dataProductListGroupArr,
    block_description,
    is_created_new,
    product_group_maximum,
    frame_of_aff: frameData,
    using_frame: usingFrame,
  } = content_attributes;

  const productGroupIdDefault = useMemo(
    () =>
      dataProductListGroupArr?.length <= 1
        ? dataProductListGroupArr[0]?.uid
        : null,
    [dataProductListGroupArr]
  );

  const [productGroupId, setProductGroupId] = useState(productGroupIdDefault);
  const [affiliateProductGroupData, setAffiliateProductGroupData] = useState(
    dataProductListGroupArr
  );
  const [isCreatedNew, setIsCreatedNew] = useState<boolean>(
    is_created_new ?? false
  );
  const [limit, setLimit] = useState(3);
  const [expandOrCollapseStatus, setExpandOrCollapseStatus] = useState(null);

  const productGroupQuantityDefaultRef = useRef<number>(
    affiliateProductGroupData?.length
  );

  const displayGroups = useMemo(() => {
    if (productGroupId) {
      return [
        affiliateProductGroupData?.find((x) => x?.uid === productGroupId),
      ];
    }
    return affiliateProductGroupData.slice(0, limit);
  }, [affiliateProductGroupData, limit, productGroupId]);

  const productGroupMaximumRef = useRef<number>(product_group_maximum);

  const extraData = {
    user_id: siteSettings.user_id,
  };

  const handleUpdateTabsBody = (data) => {
    dispatch(updateTabsBody({ data }));
  };

  const extraFunction = {
    handleUpdateTabsBody,
  };

  const productGroupMaximum = useMemo(() => {
    const productGroupQuantity = affiliateProductGroupData?.length;
    const PRODUCT_GROUP_QUANTITY_CHECKING = 25;
    if (productGroupMaximumRef.current) {
      if (productGroupMaximumRef.current === PRODUCT_GROUP_MAXIMUM) {
        return productGroupMaximumRef.current;
      } else {
        if (productGroupQuantity >= productGroupQuantityDefaultRef.current) {
          productGroupQuantityDefaultRef.current = productGroupQuantity;
          return productGroupMaximumRef.current;
        } else {
          productGroupQuantityDefaultRef.current = productGroupQuantity;
          if (productGroupMaximumRef.current - productGroupQuantity >= 5) {
            if (productGroupQuantity + 5 > PRODUCT_GROUP_MAXIMUM) {
              productGroupMaximumRef.current = productGroupQuantity + 5;
              return productGroupQuantity + 5;
            } else {
              productGroupMaximumRef.current = PRODUCT_GROUP_MAXIMUM;
              return PRODUCT_GROUP_MAXIMUM;
            }
          } else {
            return productGroupMaximumRef.current;
          }
        }
      }
    } else {
      if (isCreatedNew) {
        return PRODUCT_GROUP_MAXIMUM;
      } else {
        if (productGroupQuantity > PRODUCT_GROUP_MAXIMUM) {
          if (productGroupQuantity > productGroupQuantityDefaultRef.current) {
            return productGroupQuantityDefaultRef.current + 5;
          } else {
            productGroupQuantityDefaultRef.current = productGroupQuantity;
            return productGroupQuantity + 5;
          }
        } else {
          if (productGroupQuantity > PRODUCT_GROUP_QUANTITY_CHECKING) {
            if (productGroupQuantity > productGroupQuantityDefaultRef.current) {
              return productGroupQuantityDefaultRef.current + 5;
            } else {
              productGroupQuantityDefaultRef.current = productGroupQuantity;
              return productGroupQuantity + 5;
            }
          } else {
            setIsCreatedNew(true);
            return PRODUCT_GROUP_MAXIMUM;
          }
        }
      }
    }
  }, [isCreatedNew, affiliateProductGroupData, product_group_maximum]);

  useEffect(() => {
    dataService.publish({
      [blockData?.uid]: productGroupId,
    });
  }, [productGroupId, blockData?.uid]);

  useEffect(() => {
    if (dataProductListGroupArr)
      setAffiliateProductGroupData(dataProductListGroupArr);
  }, [dataProductListGroupArr]);

  useEffect(() => {
    if (
      productGroupId &&
      !affiliateProductGroupData.some((x) => x?.uid === productGroupId)
    ) {
      setProductGroupId(null);
    }
  }, [affiliateProductGroupData, productGroupId]);

  const isDisplayAddNewBtn = !productGroupId;

  const handleUpdateBlock = async (data) => {
    const newDataAffiliateProduct = {
      ...blockData,
      content_attributes: {
        ...blockData.content_attributes,
        items: data,
        is_created_new: isCreatedNew,
      },
    };
    try {
      var dataMiđle = await runMiddleware(
        blockData?.block_type,
        newDataAffiliateProduct,
        extraData,
        extraFunction
      );
    } catch (e) {
      return;
    }
    if (positionSlug) {
      dispatch(
        updateBlock({
          tabUid,
          positionSlug,
          blockData: dataMiđle,
        })
      );
    }
    onGetLatestBlockData(dataMiđle);
  };

  const isShowSearchBar = useMemo(() => {
    const dataProductListGroupArrClone = cloneDeep(dataProductListGroupArr);
    let allProductLists = [];
    dataProductListGroupArrClone
      ?.filter((item) => item?.enable === STATUS.ENABLE)
      ?.forEach((ele) => {
        const productListsEnable =
          ele?.content_attributes?.items?.filter(
            (x) => x.enable === STATUS.ENABLE
          ) || [];
        allProductLists = [...allProductLists, ...productListsEnable];
      });
    return allProductLists.length > 0;
  }, [affiliateProductGroupData]);

  return (
    <AffiliateContext.Provider value={{ frameData, usingFrame }}>
      <BlockWrapper
        blockType={blockData?.block_type}
        isEnable={enable}
        positionSlug={positionSlug}
        tabUid={tabUid}
        blockUid={blockData?.uid}
        index={index}
        listBlocksSortOrder={listBlocksSortOrder}
        outsideSpace={outside_space}
        insideSpace={inner_space_number}
        isFirstItem={isFirstItem}
        isApplyBlockSettings={isApplyBlockSettings}
        isHideMoreActions={isHideMoreActions}
      >
        <ExpandBlockHandler
          maxHeight={AFFILIATE_LAYOUT_MAX_HEIGHT}
          onUpdateStatus={(status) => setExpandOrCollapseStatus(status)}
        >
          <BlockHeading
            title={title_product_group}
            blockType={blockData.block_type}
            subtitle={block_description}
          />
          {isShowSearchBar && (
            <div
              style={{
                position: "relative",
              }}
            >
              <SearchBar
                placeholder={t(
                  "ss_builder_affiliate_product_searching_placeholder"
                )}
                style={{
                  marginBottom: "16px",
                }}
                value={""}
              />
              <div
                className="absolute top-0 left-0 block w-full h-full cursor-pointer"
                style={{
                  backgroundColor: "transparent",
                }}
              ></div>
            </div>
          )}

          <ProductGroupHandler
            productGroupId={productGroupId}
            onSelectGroupId={(val) => setProductGroupId(val)}
            affiliateProductGroupData={affiliateProductGroupData}
            blockData={blockData}
            isCreatedNew={isCreatedNew}
            tabUid={tabUid}
            positionSlug={positionSlug}
            productGroupMaximum={productGroupMaximum}
            onGetLatestBlockData={onGetLatestBlockData}
          />
          {isDisplayAddNewBtn && (
            <AddNewProductButton
              productGroupId={productGroupId}
              affiliateProductGroupData={affiliateProductGroupData}
              blockData={blockData}
              positionSlug={positionSlug}
              tabUid={tabUid}
              productGroupMaximum={productGroupMaximum}
              isCreatedNew={isCreatedNew}
              onUpdateProductGroupId={(val) => setProductGroupId(val)}
              onGetLatestBlockData={onGetLatestBlockData}
            />
          )}
          <>
            {displayGroups.map((ele, index) => (
              <ProductListOfGroupDisplay
                id={`product-group-${index + 1}`}
                item={ele}
                key={index}
                affiliateProductGroupData={affiliateProductGroupData}
                blockData={blockData}
                positionSlug={positionSlug}
                tabUid={tabUid}
                isCreatedNew={isCreatedNew}
                productGroupId={productGroupId}
                productGroupMaximum={productGroupMaximum}
                onUpdateProductGroupId={(val) => setProductGroupId(val)}
                onUpdateProductGroupData={(data) => {
                  setAffiliateProductGroupData(data);
                  handleUpdateBlock(data);
                }}
                onGetLatestBlockData={onGetLatestBlockData}
              />
            ))}
            <ProductInfiniteScroll
              limit={limit}
              onUpdateGroupLimitation={(count) =>
                setLimit((state) => state + count)
              }
              affiliateProductGroupData={affiliateProductGroupData}
              productGroupId={productGroupId}
              expandOrCollapseStatus={expandOrCollapseStatus}
            />
          </>
        </ExpandBlockHandler>
      </BlockWrapper>
    </AffiliateContext.Provider>
  );
};

export default BlockAffiliateProductGroup;
