import { BLOCK_TYPE } from "@features/block/block.conf";
import digitalProductExtraFunc from "./block/digital-product";
import { Block, Position } from "@features/main/store/interface";

export const BLOCK_EXTRA_FUNCTION = {
  [BLOCK_TYPE.DIGITAL_PRODUCT]: digitalProductExtraFunc,
};

//Handling logics after saving or exiting the block editor screen
export const runExtraFunction = async (
  blockData: Block,
  positions?: Position[],
  dispatch?: any,
  actionType?: string
) => {
  const blockType = blockData?.block_type;
  const extraFunc = BLOCK_EXTRA_FUNCTION[blockType];
  if (extraFunc) {
    return await extraFunc(blockData, positions, dispatch, actionType).catch((e) => {
      console.log(e);
      throw e;
    });
  }
};
