import React from "react";
import ProductDetailStyle3 from "../../ProductDetail/ProductDetailStyle3";

const ProductStyleZigzag = (props) => {
  const {
    products,
    productGroupId,
    affiliateProductGroupData,
    onUpdateProductGroupData,
    productGroupMaximum,
  } = props;

  const productListEnable = products?.filter((x) => x.enable === 1);

  return (
    <div>
      <div className="grid grid-cols-1 gap-3 affiliate-product-wrapper">
        {productListEnable?.map((ele, index) => (
          <ProductDetailStyle3
            product={ele}
            key={index}
            productIndex={index}
            productGroupId={productGroupId}
            affiliateProductGroupData={affiliateProductGroupData}
            onUpdateProductGroupData={onUpdateProductGroupData}
            productGroupMaximum={productGroupMaximum}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductStyleZigzag;
