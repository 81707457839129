import { useAppDispatch, useAppSelector } from "@app/hooks";
import AffiliateProductAddNew from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductAddNew";
import { requestPostLinkMiddlewareV2 } from "@features/block/block.conf";
import { Block } from "@features/main/store/interface";
import { updateBlock } from "@features/main/store/positionsSlice";
import FullScreenPopup from "@share/components/full-screen-popup";
import React, { useState } from "react";
import ProductListsDisplay from "../ProductListsDisplay";
import GroupTitleComponent from "./GroupTitleComponent";
import { setDynamicParams } from "@features/main/store/dynamicSlice";

interface Props {
  id: string;
  item: any;
  affiliateProductGroupData: any;
  blockData: Block;
  tabUid: string;
  positionSlug: string;
  isCreatedNew: boolean;
  productGroupId: string;
  productGroupMaximum: number;
  onUpdateProductGroupId: (val: string) => void;
  onUpdateProductGroupData: (data: any) => void;
  onGetLatestBlockData?: (block: Block) => void;
}

const ProductListOfGroupDisplay = (props: Props) => {
  const {
    id,
    item,
    affiliateProductGroupData,
    blockData,
    tabUid,
    positionSlug,
    isCreatedNew,
    productGroupId,
    productGroupMaximum,
    onUpdateProductGroupId,
    onUpdateProductGroupData,
    onGetLatestBlockData = () => {},
  } = props;

  const dispatch = useAppDispatch();

  const siteSettings = useAppSelector((state) => state.siteSettings);

  const extraData = {
    user_id: siteSettings.user_id,
  };

  const [isOpenAddNewProduct, setIsOpenAddNewProduct] = useState(false);
  const [activeProductGroup, setActiveProductGroup] = useState(null);

  const content_attributes = blockData?.content_attributes;
  const style_attributes = blockData?.style_attributes;
  const { see_more_btn_content } = content_attributes;

  const handleOpenAddAffiliateProduct = (e) => {
    e.stopPropagation();
    setActiveProductGroup(() => item?.uid);
    setIsOpenAddNewProduct(() => true);
  };

  const handleSaveAffiliateProductAddNew = async (
    data,
    activedProductGroup
  ) => {
    const newDataAffiliateProduct = {
      ...blockData,
      content_attributes: {
        ...blockData.content_attributes,
        items: data,
        is_created_new: isCreatedNew,
      },
    };
    try {
      const newData = await requestPostLinkMiddlewareV2(
        newDataAffiliateProduct,
        extraData
      );
      if (newData) {
        activedProductGroup && onUpdateProductGroupId(activedProductGroup);
        if (positionSlug) {
          dispatch(
            updateBlock({
              tabUid,
              positionSlug,
              blockData: newData,
            })
          );
        }
        onGetLatestBlockData(newData);
        setIsOpenAddNewProduct(false);
      }
    } catch (e) {
      dispatch(
        setDynamicParams({
          data: { isErrorNetwork: true },
        })
      );
      return;
    }
  };

  const handleAddNewAffiliateProductGroup = (data, productGroupId) => {
    const newDataAffiliateProduct = {
      ...blockData,
      content_attributes: {
        ...blockData.content_attributes,
        items: data,
        is_created_new: isCreatedNew,
      },
    };
    onUpdateProductGroupId(productGroupId);
    if (positionSlug) {
      dispatch(
        updateBlock({
          tabUid,
          positionSlug,
          blockData: newDataAffiliateProduct,
        })
      );
    }
    onGetLatestBlockData(newDataAffiliateProduct);
  };

  return (
    <div
      id={id}
      className="mt-5"
    >
      <GroupTitleComponent
        groupItem={item}
        onAddNewProduct={handleOpenAddAffiliateProduct}
        productGroupId={productGroupId}
        affiliateProductGroupData={affiliateProductGroupData}
        productGroupMaximum={productGroupMaximum}
        blockData={blockData}
        positionSlug={positionSlug}
        tabUid={tabUid}
        isCreatedNew={isCreatedNew}
        onGetLatestBlockData={onGetLatestBlockData}
      />
      <ProductListsDisplay
        productGroupItem={item}
        style_attributes={style_attributes}
        see_more_btn_content={see_more_btn_content}
        affiliateProductGroupData={affiliateProductGroupData}
        onUpdateProductGroupData={onUpdateProductGroupData}
        productGroupMaximum={productGroupMaximum}
      />
      {isOpenAddNewProduct && (
        <FullScreenPopup
          visible={isOpenAddNewProduct}
          onMaskClick={() => setIsOpenAddNewProduct(false)}
        >
          <AffiliateProductAddNew
            callbackCancelAffiliateProductAddNew={() =>
              setIsOpenAddNewProduct(false)
            }
            callbackSaveAffiliateProductAddNew={
              handleSaveAffiliateProductAddNew
            }
            affiliateProductGroupData={affiliateProductGroupData}
            activeProductGroup={activeProductGroup}
            callbackAddNewAffiliateProductGroup={
              handleAddNewAffiliateProductGroup
            }
            productGroupMaximum={productGroupMaximum}
          />
        </FullScreenPopup>
      )}
    </div>
  );
};

export default ProductListOfGroupDisplay;
