import { TAB_TEMPLATE } from "@share/configs/const";
import React, { useCallback } from "react";
import TemplateStyle1 from "./TemplateStyle1";
import TemplateStyle2 from "./TemplateStyle2";

export const TAB_TEMPLATE_COMPONENT = {
  [TAB_TEMPLATE.TEMPLATE_1]: TemplateStyle1,
  [TAB_TEMPLATE.TEMPLATE_2]: TemplateStyle2,
};
const TabTemplateStyle = (props) => {
  const { item, onSelect, selected, style_attributes, onDoubleTab } = props;
  const TabTemplateRender = useCallback((props) => {
    let Element = <></>;
    const tab_template =
      props?.style_attributes?.tab_template ?? TAB_TEMPLATE.TEMPLATE_1;
    if (TAB_TEMPLATE_COMPONENT[tab_template]) {
      const RenderComponent = TAB_TEMPLATE_COMPONENT[tab_template];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);
  return (
    <TabTemplateRender
      style_attributes={style_attributes}
      item={item}
      onSelect={onSelect}
      selected={selected}
      onDoubleTab={onDoubleTab}
    />
  );
};

export default TabTemplateStyle;
