import { CARD_LAYOUTS } from "@share/configs/const";
import { RightArrowIcon } from "@share/icons";
import { Image } from "antd-mobile";
import React from "react";

type Props = {
  card_title: string;
  card_subtitle: string;
  card_description: string;
  card_image: string;
  card_label: string;
  card_btn_see_more: string;
  card_layout: string;
  card_image_ratio: string;
  card_url: string;
};

const CardDetailLayout3 = (props: Props) => {
  const {
    card_title,
    card_subtitle,
    card_image,
    card_layout,
    card_image_ratio,
  } = props;

  const isDisplayCardText = card_title || card_subtitle;

  return (
    <div
      className={`card-detail-layout relative ${card_layout} flex flex-row gap-2 items-center`}
    >
      {card_layout === CARD_LAYOUTS.LAYOUT_5 && (
        <>
          <Image
            src={card_image}
            style={{
              aspectRatio: card_image_ratio,
              borderRadius: "4px",
            }}
            fit="cover"
            height={60}
          />
          <div className="card-text w-[30vw] md:w-[10vw] grow text-left">
            {card_title && <h5 className="card-title">{card_title}</h5>}
            {card_subtitle && (
              <div className="card-subtitle">{card_subtitle}</div>
            )}
          </div>
          <RightArrowIcon className="w-5 h-5" />
        </>
      )}
      {card_layout === CARD_LAYOUTS.LAYOUT_6 && (
        <>
          {isDisplayCardText && (
            <div className="card-text w-[30vw] md:w-[10vw] grow text-left">
              {card_title && <h5 className="card-title">{card_title}</h5>}
              {card_subtitle && (
                <div className="card-subtitle">{card_subtitle}</div>
              )}
            </div>
          )}
          <Image
            src={card_image}
            style={{
              aspectRatio: card_image_ratio,
              borderRadius: "4px",
            }}
            fit="cover"
            height={60}
          />
        </>
      )}
    </div>
  );
};

export default CardDetailLayout3;
