import { useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import { NextImageSliderIcon, PreviousImageSliderIcon } from "@share/icons";
import { Button, Image, Swiper } from "antd-mobile";
import { SwiperRef } from "antd-mobile/es/components/swiper";
import orderBy from "lodash/orderBy";
import React, { useMemo, useRef, useState } from "react";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockImageSlider = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;

  const enable = blockData && blockData.enable;
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};
  const style_attibutes = blockData?.style_attributes;
  const content_attributes = blockData?.content_attributes;
  const { enable_dot, ratio, enable_arrow, enable_auto_play } =
    style_attibutes || {};
  const {
    block_heading,
    block_description,
    items: dataImageSliderArr,
  } = content_attributes || {};

  const dataImageSliderSortOrder =
    dataImageSliderArr &&
    orderBy(dataImageSliderArr, ["sort_order"], ["asc"]);

  const imageSliderEnable = dataImageSliderSortOrder?.filter(
    (x) => x.enable === 1
  );

  const isDisplayArrowSlideBtn = useMemo(
    () => enable_arrow && imageSliderEnable?.length > 1,
    [enable_arrow, imageSliderEnable]
  );

  const ref = useRef<SwiperRef>(null);
  const [imageIndex, setImageIndex] = useState(0);

  const handlePreviousImage = (event) => {
    event.preventDefault();
    ref.current?.swipePrev();
  };

  const handleNextImage = (event) => {
    event.preventDefault();
    ref.current?.swipeNext();
  };

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <BlockHeading
        blockType={blockData.block_type}
        title={block_heading}
        subtitle={block_description}
      />
      <div className="relative">
        <Swiper
          allowTouchMove={false}
          ref={ref}
          autoplay={enable_auto_play}
          loop
          {...(enable_dot && imageSliderEnable.length > 1
            ? {}
            : { indicator: () => null })}
          indicatorProps={{
            color: "primary",
          }}
          onIndexChange={(val) => setImageIndex(val)}
        >
          {imageSliderEnable &&
            Array.isArray(imageSliderEnable) &&
            imageSliderEnable.map((ele, index) => (
              <Swiper.Item key={index}>
                <Image
                  src={ele.image}
                  fit="cover"
                  style={{
                    backgroundSize: "cover",
                    aspectRatio: ratio,
                  }}
                  className="image-border-radius"
                />
              </Swiper.Item>
            ))}
        </Swiper>
        {isDisplayArrowSlideBtn && (
          <div className="flex flex-row justify-between absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full">
            <Button
              style={{
                // opacity: "40%",
                padding: "2px",
                borderRadius: "100%",
                border: "none",
                boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.16)",
              }}
              onClick={handlePreviousImage}
              className="button-background-color"
            >
              <PreviousImageSliderIcon fillColor="var(--button-text-color)" />
            </Button>
            <Button
              style={{
                // opacity: "40%",
                padding: "2px",
                borderRadius: "100%",
                border: "none",
                boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.16)",
              }}
              onClick={handleNextImage}
              className="button-background-color"
            >
              <NextImageSliderIcon fillColor="var(--button-text-color)" />
            </Button>
          </div>
        )}
      </div>
      <div
        className="text-[13px] leading-4 py-1 text-center"
        style={{
          color: "var(--text-color-6)",
        }}
      >
        {imageSliderEnable[imageIndex]?.image_description}
      </div>
    </BlockWrapper>
  );
};

export default BlockImageSlider;
