import { CardInterface } from "@features/main/store/interface";
import { CARD_LAYOUTS } from "@share/configs/const";
import React, { useCallback } from "react";
import CardLayout1 from "./CardDetailLayout1";
import CardLayout2 from "./CardDetailLayout2";
import CardLayout3 from "./CardDetailLayout3";
import CardLayout4 from "./CardDetailLayout4";

export interface StyleAttributesInterface {
  card_layout: string;
  card_slide_type: string;
  card_style: string;
  image_orientation: string;
  item_on_row: string;
  ratio: string;
}

type Props = {
  style_attributes: StyleAttributesInterface;
  card_attributes: CardInterface;
  card_see_more_btn: string;
};

export const CARD_LAYOUT_COMPONENT = {
  [CARD_LAYOUTS.LAYOUT_1]: CardLayout1,
  [CARD_LAYOUTS.LAYOUT_2]: CardLayout1,
  [CARD_LAYOUTS.LAYOUT_3]: CardLayout2,
  [CARD_LAYOUTS.LAYOUT_4]: CardLayout2,
  [CARD_LAYOUTS.LAYOUT_5]: CardLayout3,
  [CARD_LAYOUTS.LAYOUT_6]: CardLayout3,
  [CARD_LAYOUTS.LAYOUT_7]: CardLayout4,
  [CARD_LAYOUTS.LAYOUT_8]: CardLayout4,
};

const CardDetail = (props: Props) => {
  const { style_attributes, card_attributes, card_see_more_btn } = props;
  const { card_layout, card_slide_type, card_style, item_on_row, ratio } =
    style_attributes || {};
  const {
    image,
    card_description,
    card_label,
    card_subtitle,
    card_title,
    card_url,
  } = card_attributes || {};

  const CardDetailLayoutRender = useCallback((props) => {
    let Element = <></>;
    if (CARD_LAYOUT_COMPONENT[props?.card_layout]) {
      const RenderComponent = CARD_LAYOUT_COMPONENT[props?.card_layout];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);

  return (
    <CardDetailLayoutRender
      card_title={card_title}
      card_subtitle={card_subtitle}
      card_description={card_description}
      card_image={image}
      card_label={card_label}
      card_btn_see_more={card_see_more_btn}
      card_layout={card_layout}
      card_image_ratio={ratio}
      card_url={card_url}
      item_on_row={item_on_row}
      card_style={card_style}
      card_slide_type={card_slide_type}
    />
  );
};

export default CardDetail;
