import { TAB_DISPLAY, TAB_LAYOUT, TAB_TEMPLATE } from "@share/configs/const";
import { Button } from "antd-mobile";
import React, { useEffect, useMemo, useRef } from "react";
import TabStyleDisplay from "../../TabStyleDisplay";
import cls from "classnames";
import { useDoubleTap } from "use-double-tap";

const TemplateStyle2 = (props: {
  item: any;
  onSelect: (HTMLSpanElement) => void;
  selected: boolean;
  style_attributes: any;
  onDoubleTab: () => void;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const { item, onSelect, selected, style_attributes, onDoubleTab } = props;
  const tab_layout = style_attributes?.tab_layout;
  const display_style = style_attributes?.display_style;
  const tab_template =
    style_attributes?.tab_template || TAB_TEMPLATE.TEMPLATE_2;
  useEffect(() => {
    if (selected)
      ref.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
        inline: "center",
      });
  }, [selected]);
  let border = "none";
  let textColor = "var(--heading-color-6)";
  let backgroundColor = "unset";
  if (tab_layout !== TAB_LAYOUT.TEXT_UNDER) {
    border = selected
      ? "1px solid var(--button-bg-color-6)"
      : "1px solid var(--heading-color-2)";
    textColor = selected
      ? "var(--button-text-color)"
      : "var(--heading-color-6)";
    backgroundColor = selected ? "var(--button-bg-color-6)" : "unset";
  }

  const bindDoubleTapToBlock = useDoubleTap(
    () => {
      onDoubleTab && onDoubleTab();
    },
    300,
    {
      onSingleTap: (e) => {
        e.stopPropagation();
        onSelect(ref.current);
      },
    }
  );

  return (
    <span
      ref={ref}
      className="h-full"
    >
      <Button
        block
        size="middle"
        style={{
          whiteSpace: "nowrap",
          "--background-color": backgroundColor,
          "--text-color": textColor,
          "--border-radius": "20px",
          backgroundColor: backgroundColor,
          border: border,
          opacity: item?.enable !== 1 ? "40%" : "",
          height: "100%",
        }}
        {...bindDoubleTapToBlock}
        // onClick={(e) => {
        //   e.stopPropagation();
        //   onSelect(ref.current);
        // }}
        className={cls(`${tab_template}`, selected && "tab-active-class")}
      >
        <TabStyleDisplay
          style_attributes={style_attributes}
          tab={item}
        />
      </Button>
    </span>
  );
};

export default TemplateStyle2;
