import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { placeHolderImageIcon } from "@share/icons/product-tour-icon";
import {
  AFFILIATE_SETTING_TOUR_CLASS_NAME,
  steps,
  STEP_INDEX,
} from "./setting-tour-config";
import ProductTourComponent from "@share/components/ProductTourComponent";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { setParamsAffiliateProductTours } from "@features/main/store/dynamicSlice";
import { KEY_BLOCK } from "@share/configs/const";

const ProductSettingTour = (props) => {
  const { handleHideSettingTour } = props;
  const { t } = useTranslation();
  const productTourRef = useRef(null);
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const edittingBlock = useAppSelector((state) => state.edittingBlock);
  const { KEY: keyBlock } = edittingBlock || {};
  const { hiddenSteps } = dynamicConfig?.affiliateSettingTour || {};
  const dispatch = useAppDispatch();

  const setRef = (ref) => {
    productTourRef.current = ref;
    const fullscreenContentElement = document.getElementsByClassName(
      "full-screen-popup-content"
    );
    if (productTourRef.current) {
      productTourRef.current.introJs._targetElement =
        fullscreenContentElement[fullscreenContentElement?.length - 1];
    }
  };

  const isHideStep1 = useMemo(
    () => hiddenSteps?.includes(STEP_INDEX.STEP_1),
    [hiddenSteps]
  );
  const isHideStep7 = useMemo(
    () => hiddenSteps?.includes(STEP_INDEX.STEP_7),
    [hiddenSteps]
  );

  const stepsDefault = steps.map((ele) => ({
    title:
      `<div class="wrapper-image">` +
      placeHolderImageIcon +
      `
        <img class="img-product-tour" src=${ele.image_header} /> </div>
        <div class="title">${t(ele.title_key)}<div/>`,
    element: ele.using_selector
      ? document.querySelector(`.${ele.element_class}`)
      : `.${ele.element_class}`,
    intro: `<div> ${t(ele.intro_key)}<div/>`,
    tooltipClass: `tooltip-product-tour ${ele.tooltip_class}`,
    position: ele?.position || "auto",
  }));

  const onExit = () => {
    handleHideSettingTour();
  };

  const onBeforeExit = (stepIndex) => {
    const castWindow = window as any;
    if (stepIndex === STEP_INDEX.STEP_1 && !isHideStep1) {
      castWindow.triggerVisiblePopoverMoreElement(false);
    }
    if (stepIndex === STEP_INDEX.STEP_7 && !isHideStep7) {
      castWindow.triggerVisibleAffiliatePopoverProductDetail(false);
    }
  };

  const onChangeProductTour = async (nextStepIndex, nextElement) => {
    const castWindow = window as any;
    const fullScreenPopupContent = document.querySelectorAll(
      ".full-screen-popup-content"
    ) as any;
    const popupConentScrollHeight =
      fullScreenPopupContent[fullScreenPopupContent?.length - 1].scrollTop;
    const tooltipElement = document.querySelectorAll(
      ".introjs-fixedTooltip"
    ) as any;

    if (tooltipElement) {
      if (
        (keyBlock === KEY_BLOCK.EDIT &&
          nextStepIndex !== STEP_INDEX.STEP_1 &&
          nextStepIndex !== STEP_INDEX.STEP_8) ||
        (keyBlock === KEY_BLOCK.ADD_NEW && nextStepIndex !== STEP_INDEX.STEP_6)
      ) {
        for (let i = 0; i < tooltipElement?.length; i++) {
          tooltipElement[i].style.cssText = `top:${
            nextElement.getBoundingClientRect().top +
            popupConentScrollHeight -
            5
          }px !important`;
          tooltipElement[i].style.position = "absolute";
          tooltipElement[i].style.background = "#FFFFFF";
        }
      } else {
        for (let i = 0; i < tooltipElement?.length; i++) {
          tooltipElement[i].style.cssText = "";
          tooltipElement[i].style.position = "";
          tooltipElement[i].style.background = "";
        }
      }
    }
    if (nextStepIndex === STEP_INDEX.STEP_1) {
      if (!isHideStep1) {
        castWindow.triggerVisiblePopoverMoreElement(true);
        await new Promise((resolve, reject) => {
          productTourRef.current?.updateStepElement(STEP_INDEX.STEP_1);
          setTimeout(() => resolve({}), 100);
        });
      }
    }
    if (nextStepIndex === STEP_INDEX.STEP_7) {
      if (!isHideStep7) {
        castWindow.triggerVisibleAffiliatePopoverProductDetail(true);
        await new Promise((resolve, reject) => {
          productTourRef.current?.updateStepElement(STEP_INDEX.STEP_7);
          setTimeout(() => resolve({}), 100);
        });
      }
    }
  };

  const onCompleteProductTour = () => {};

  const onBeforeChange = async (nextStepIndex, nextElement) => {
    const castWindow = window as any;
    if (nextStepIndex === STEP_INDEX.STEP_1) {
      if (!isHideStep1) {
        castWindow.triggerVisiblePopoverMoreElement(true);
        await new Promise((resolve, reject) => {
          productTourRef.current?.updateStepElement(STEP_INDEX.STEP_1);
          setTimeout(() => resolve({}), 100);
        });
      } else {
        nextElement.scrollIntoView();
      }
    }
    if (nextStepIndex === STEP_INDEX.STEP_2) {
      castWindow.triggerVisiblePopoverMoreElement(false);
      nextElement.scrollIntoView();
    }
    if (nextStepIndex === STEP_INDEX.STEP_3) {
      nextElement.scrollIntoView();
    }
    if (nextStepIndex === STEP_INDEX.STEP_4) {
      nextElement.scrollIntoView();
    }
    if (nextStepIndex === STEP_INDEX.STEP_5) {
      nextElement.scrollIntoView();
    }
    if (nextStepIndex === STEP_INDEX.STEP_6) {
      nextElement.scrollIntoView();
      if (!isHideStep7) {
        castWindow.triggerVisibleAffiliatePopoverProductDetail(false);
      }
    }
    if (nextStepIndex === STEP_INDEX.STEP_7) {
      if (!isHideStep7) {
        castWindow.triggerVisibleAffiliatePopoverProductDetail(true);
        await new Promise((resolve, reject) => {
          productTourRef.current.updateStepElement(STEP_INDEX.STEP_7);
          setTimeout(() => resolve({}), 100);
        });
      } else {
        nextElement.scrollIntoView();
      }
    }
    if (nextStepIndex === STEP_INDEX.STEP_8) {
      if (!isHideStep7) {
        castWindow.triggerVisibleAffiliatePopoverProductDetail(false);
      }
    }
  };

  const stepsFilter = useMemo(
    () => stepsDefault?.filter((ele, idx) => !hiddenSteps?.includes(idx)),
    [stepsDefault, hiddenSteps]
  );

  return (
    <ProductTourComponent
      steps={stepsFilter}
      onExit={onExit}
      onBeforeExit={onBeforeExit}
      onChange={onChangeProductTour}
      onComplete={onCompleteProductTour}
      onBeforeChange={onBeforeChange}
      setRef={setRef}
    />
  );
};

export default ProductSettingTour;
