import React, { useState } from "react";
import cls from "classnames";
import { useAppSelector } from "@app/hooks";
import { BOOKING_PROFILE_PATH } from "@share/configs/booking-profile";
import styleModule from "./style.module.scss";
import { ExportIcon } from "@share/icons/line-icon";
import { SpinLoading } from "antd-mobile";

interface Props {
  open_new_tab: boolean;
  apply_block_settings: boolean;
}

const EmbedCodeStyle = ({ open_new_tab, apply_block_settings }: Props) => {
  const userInfo = useAppSelector((state) => state.user.kol);
  const domain = `https://${userInfo?.domain}${BOOKING_PROFILE_PATH}`;

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <>
      <div
        className="relative"
        style={{
          //Đợi khi iframe load được cả trang mới cho hiện lên
          opacity: !iframeLoaded ? 0 : 1,
        }}
      >
        <iframe
          className={styleModule.iframe}
          src={domain}
          style={{
            borderRadius: apply_block_settings
              ? "calc(var(--block-border-radius)/2)"
              : "4px",
          }}
          onLoad={handleIframeLoad}
        />
        {open_new_tab && (
          <span
            className={cls(
              `flex items-center justify-center absolute w-[32px] h-[32px] right-6 top-[60px] rounded-full`
            )}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
            }}
          >
            <ExportIcon
              width={16}
              height={16}
              fillColor="#FFFFFF"
            />
          </span>
        )}
        <div
          className="absolute top-0 left-0 block w-full h-full rounded-lg z-[1]"
          style={{
            backgroundColor: "transparent",
          }}
        ></div>
      </div>
      {!iframeLoaded && (
        <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[2]">
          <SpinLoading
            color="primary"
            style={{ "--size": "40px", margin: "auto" }}
          />
        </span>
      )}
    </>
  );
};

export default EmbedCodeStyle;
