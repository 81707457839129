import type { AppState } from "@app/store";
import {
  BLOCK_TYPE,
  BLOCK_TYPE_LANGUAGE_KEY,
} from "@features/block/block.conf";
import {
  POSITION_TYPE,
  SelectionStateEnum,
} from "@features/block/config/interface";
import { BLOCK_FILTER_GROUP_TYPE } from "@share/configs/const";
import {
  BioIconColor,
  CardIconColor,
  EmbedIconColor,
  ImageGalleryIconColor,
  ImagePopupIcon,
  ImageSliderIconColor,
  MultiLinksIconColor,
  SpacerAndDividerIcon,
  SpotLightIconColor,
  CombinedBlockIcon,
} from "@share/icons/block-icons";

export const ADVANCE_GROUP = {
  name_key: "ss_builder_advanced_title",
  description_key: "ss_builder_advanced_group_description",
  slug: BLOCK_FILTER_GROUP_TYPE.ADVANCED,
  block_list: [
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.BIO],
      block_type: BLOCK_TYPE.BIO,
      description_key: "ss_builder_block_bio_description",
      Icon: (width, height) => (
        <BioIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.STORY],
      block_type: BLOCK_TYPE.STORY,
      description_key: "ss_builder_block_button_story_description",
      Icon: (width, height) => (
        <SpotLightIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.IMAGE_SLIDER],
      block_type: BLOCK_TYPE.IMAGE_SLIDER,
      description_key: "ss_builder_block_image_slider_description",
      Icon: (width, height) => (
        <ImageSliderIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.BUTTON_MULTI_LINK],
      block_type: BLOCK_TYPE.BUTTON_MULTI_LINK,
      description_key: "ss_builder_block_button_multi_description",
      Icon: (width, height) => (
        <MultiLinksIconColor
          width={width}
          height={height}
        />
      ),
    },
    // {
    //     name_key: "ss_builder_title_block_story",
    //     block_type: BLOCK_TYPE.STORY,
    //     description_key: "ss_builder_block_button_story_description",
    //     Icon: () => <StoryIcon className="align-middle inline-block w-12 h-12" />
    // },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.CARD],
      block_type: BLOCK_TYPE.CARD,
      description_key: "ss_builder_block_block_card_description",
      Icon: (width, height) => (
        <CardIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.EMBED],
      block_type: BLOCK_TYPE.EMBED,
      description_key: "ss_builder_block_image_embed_description",
      Icon: (width, height) => (
        <EmbedIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.IMAGE_GALLERY],
      block_type: BLOCK_TYPE.IMAGE_GALLERY,
      description_key: "ss_builder_block_image_gallery_description",
      Icon: (width, height) => (
        <ImageGalleryIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: "ss_builder_title_block_image_popup",
      block_type: BLOCK_TYPE.IMAGE_POPUP,
      description_key: "ss_builder_block_image_popup_description",
      Icon: (width, height) => (
        <ImagePopupIcon
          className="align-middle inline-block"
          width={width}
          height={height}
        />
      ),
      defaultState: SelectionStateEnum.DISABLE,
      onLoad: (
        setDisplayState: (state: number) => void,
        setWarningKey: (key: string) => void,
        positionSlug: string,
        tabUid: string,
        state: AppState
      ) => {
        const { positions } = state;
        const expand = positions.find(
          (position) => position.slug == POSITION_TYPE.EXPAND
        );

        const includeInExpand =
          expand &&
          expand.block_list.find(
            (block) => block.block_type == BLOCK_TYPE.IMAGE_POPUP
          );

        if (!includeInExpand) {
          setDisplayState(SelectionStateEnum.ENABLE);
        } else {
          setWarningKey("ss_builder_image_popup_warning");
        }
      },
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.COMBINED],
      block_type: BLOCK_TYPE.COMBINED,
      description_key: "ss_builder_block_combined_description",
      Icon: (width, height) => (
        <CombinedBlockIcon
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.SPACER_AND_DIVIDER],
      block_type: BLOCK_TYPE.SPACER_AND_DIVIDER,
      description_key: "ss_builder_block_spacer_and_divider_description",
      Icon: (width, height) => (
        <SpacerAndDividerIcon
          width={width}
          height={height}
        />
      ),
    },
    // {
    //     name_key: "ss_builder_title_block_embed",
    //     block_type: BLOCK_TYPE.EMBED,
    //     description_key: "ss_builder_block_image_embed_description",
    //     Icon: () => <EmbedIcon className="align-middle inline-block w-12 h-12" />
    // },
    // {
    //     name_key: "ss_builder_title_block_card",
    //     block_type: BLOCK_TYPE.CARD,
    //     description_key: "ss_builder_block_block_card_description",
    //     Icon: () => <CardIconV2 className="align-middle inline-block w-12 h-12" />
    // },
  ],
};
