import React, { useMemo, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DEFAULT_IMAGE =
  "https://image.passio.eco/page-builder/page-builder/images-default/product-default.jpeg";

export const ResizeHelpers = {
  square: [32, 64, 128, 256, 320, 400, 512, 1024, 2048],
  width: [32, 64, 128, 256, 320, 400, 512, 900, 1200, 1920],
  _findSize: (arr, size) => {
    let result;
    for (let i = 0; i < arr.length; i++) {
      if (!result && arr[i] >= size) {
        result = arr[i];
        break;
      }
    }
    return result;
  },
  getSquaredSize: (size) => ResizeHelpers._findSize(ResizeHelpers.square, size),
  createThumbImageSrc: (
    src: string,
    width: number = null,
    height = null,
    isThumbnail
  ) => {
    if (src.toLowerCase().includes(".svg")) {
      return src;
    }
    if (
      !width ||
      (!src.includes("/ssp/") &&
        !src.includes("/mini-store/") &&
        !src.includes("/ministore-catalog/")) ||
      !isThumbnail
    )
      return src;
    const size = ResizeHelpers.getSquaredSize(width);

    const position = src.indexOf("/", 8);
    return [
      src.slice(0, position),
      `/${size}x${size}`,
      src.slice(position),
    ].join("");
  },
};

const BUILDER_IMAGE_HOST =
  "https://passio-prod.s3-ap-southeast-1.amazonaws.com";
const PASSIO_IMAGE_HOST = "https://image.passio.eco";
export const getPassioPageResizedUrl = (
  url: string,
  width = 500,
  height = 500
) => {
  if (!url) {
    return url;
  }
  if (
    url.startsWith(BUILDER_IMAGE_HOST) &&
    url.indexOf("passio-prod/builder") !== -1
  ) {
    return url
      .replace(BUILDER_IMAGE_HOST, PASSIO_IMAGE_HOST)
      .replace("passio-prod/builder", `passio-prod/${width}x${height}/builder`);
  } else {
    return url;
  }
};

export default function LazyImage(props: any) {
  const { src, width, height, alt, isThumbnail = false, ...rest } = props;
  const ref = useRef<HTMLImageElement>();
  const [isOriginLoad, setIsOriginLoad] = useState(false);
  const [isError, setIsError] = useState(false);
  const imageSrc = useMemo(() => {
    if (isError) {
      return DEFAULT_IMAGE;
    }
    if (isOriginLoad) {
      return src;
    }
    const imageWidth =
      width || ref.current?.getBoundingClientRect()?.width || 200;
    const passioPageUrl = getPassioPageResizedUrl(src, imageWidth, height);
    if (passioPageUrl != imageSrc) {
      return passioPageUrl;
    }
    return ResizeHelpers.createThumbImageSrc(
      src || "/images/placeholder-square.png",
      imageWidth,
      height,
      isThumbnail
    );
  }, [isOriginLoad, isError, src]);
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <LazyLoadImage
      ref={ref}
      src={imageSrc}
      alt={alt}
      {...rest}
      onError={() => {
        if (!ref.current) return;
        if (isOriginLoad || imageSrc == src) {
          setIsError(true);
        } else {
          setIsOriginLoad(true);
        }
        // @ts-ignore
      }}
    />
  );
}
