import React, { useEffect, useMemo, useState } from "react";
import { BackAngelIcon } from "@share/icons";
import { DotLoading, InfiniteScroll, NavBar } from "antd-mobile";
import { useTranslation } from "react-i18next";
import ProductDetailStyle1 from "@features/design/components/CommonBlockComponent/BlockAffiliateProductGroup/ProductDetail/ProductDetailStyle1";
import flatten from "lodash/flatten";
import chunk from "lodash/chunk"

const ListProduct = ({
  onCancel,
  listProduct,
  styleAttributes,
  affiliateProductGroupData,
  onUpdateProductGroupData,
  productGroupMaximum,
  allProductLists,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState<number>(0);
  const listProductChunk = useMemo(() => {
    return chunk(allProductLists, 12);
  }, [allProductLists]);

  useEffect(() => {
    setData(flatten(listProductChunk.slice(0, count + 1)));
  }, [allProductLists]);
  const loadMore = async () => {
    setHasMore(data.length < allProductLists.length);

    if (listProductChunk[count]) {
      // setData((val) => [...val, ...listProductChunk[count]]);
      setData(flatten(listProductChunk.slice(0, count + 1)));
      setCount((val) => val + 1);
    }
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span
              style={{
                color: "#999999",
              }}
            >
              {t("ss_builder_loading_label")}
            </span>
            <DotLoading
              style={{
                color: "#999999",
              }}
            />
          </>
        ) : null}
      </>
    );
  };

  return (
    <div>
      <NavBar
        backArrow={<BackAngelIcon />}
        onBack={onCancel}
        className="max-width-content-class w-full bg-[#FFFFFF] fixed top-0 z-[99]"
        style={{ borderBottom: "1px solid #EEE" }}
      >
        <div className="text-neutral-900 font-normal text-[18.72px] leading-[130%]">
          {t("ss_builder_digital_product_list_title")}
        </div>
      </NavBar>
      <div
        className="pb-5 px-3 pt-[45px] bg-[#FFFFFF] h-full  "
        id="passio-aff-swiper-list"
      >
        <div className="grid grid-cols-2 gap-3 pt-3">
          {data?.map((ele, idx) => (
            <ProductDetailStyle1
              product={ele}
              key={idx}
              styleAttributes={styleAttributes}
              affiliateProductGroupData={affiliateProductGroupData}
              onUpdateProductGroupData={onUpdateProductGroupData}
              productGroupMaximum={productGroupMaximum}
              fromSwiperList={true}
            />
          ))}
        </div>
        <div id="aff-swiper-infinite-scroll">
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
          >
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default ListProduct;
