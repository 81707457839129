import { useAppDispatch, useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import { updateBlock } from "@features/main/store/positionsSlice";
import React from "react";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";
import AdvancedSettingWrapper from "./AdvancedSettingWrapper";
import BlockListsDisplay from "./BlockListsDisplay";
import PreviewStyleHOC from "@features/block/block-editor/Combined/components/PreviewStyleHOC";

type Props = {
  blockData: Block;
  positionSlug?: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
};

const BlockCombined = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
  } = props;

  const dispatch = useAppDispatch();
  const enable = blockData && blockData.enable;
  const { blocks, block_title, block_description } =
    blockData?.content_attributes || {};
  const {
    block_space = 12,
    advanced_setting,
    is_applied_block_global_settings = false,
  } = blockData?.style_attributes || {};

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const enableBlocks = blocks?.filter((x) => x.enable === 1);

  const handleUpdateCombinedBlockData = (data: Block) => {
    if (data) {
      const newBlocks = blocks.map((x) => {
        if (x.uid === data.uid) {
          return data;
        }
        return x;
      });
      dispatch(
        updateBlock({
          tabUid,
          positionSlug,
          blockData: {
            ...blockData,
            content_attributes: {
              ...blockData.content_attributes,
              blocks: newBlocks,
            },
          },
        })
      );
    }
  };

  return (
    <PreviewStyleHOC
      elementId={blockData?.uid}
      advancedSetting={advanced_setting}
      isAppliedBlockGlobalSettings={is_applied_block_global_settings}
      blockUid={blockData?.uid}
    >
      <BlockWrapper
        blockType={blockData?.block_type}
        isEnable={enable}
        positionSlug={positionSlug}
        tabUid={tabUid}
        blockUid={blockData?.uid}
        index={index}
        listBlocksSortOrder={listBlocksSortOrder}
        outsideSpace={outside_space}
        insideSpace={inner_space_number}
        isFirstItem={isFirstItem}
        isApplyBlockSettings={isApplyBlockSettings}
        // useAdvancedSetting={advanced_setting?.use_advanced_setting}
        advancedSetting={advanced_setting}
      >
        {advanced_setting?.use_advanced_setting ? (
          <AdvancedSettingWrapper advancedSetting={advanced_setting}>
            <BlockHeading
              title={block_title}
              blockType={blockData.block_type}
              subtitle={block_description}
              blockUid={blockData.uid}
            />
            <div
              style={{
                marginLeft:
                  is_applied_block_global_settings && outside_space
                    ? "-12px"
                    : "0px",
                marginRight:
                  is_applied_block_global_settings && outside_space
                    ? "-12px"
                    : "0px",
              }}
            >
              <BlockListsDisplay
                blocks={enableBlocks}
                blockSpace={block_space}
                onGetLatestBlockData={(data) =>
                  handleUpdateCombinedBlockData(data)
                }
                isApplyGlobalSettings={is_applied_block_global_settings}
              />
            </div>
          </AdvancedSettingWrapper>
        ) : (
          <>
            <BlockHeading
              title={block_title}
              blockType={blockData.block_type}
              subtitle={block_description}
            />
            <div
              style={{
                marginLeft:
                  is_applied_block_global_settings && outside_space
                    ? "-12px"
                    : "0px",
                marginRight:
                  is_applied_block_global_settings && outside_space
                    ? "-12px"
                    : "0px",
              }}
            >
              <BlockListsDisplay
                blocks={enableBlocks}
                blockSpace={block_space}
                onGetLatestBlockData={(data) =>
                  handleUpdateCombinedBlockData(data)
                }
                isApplyGlobalSettings={is_applied_block_global_settings}
              />
            </div>
          </>
        )}
      </BlockWrapper>
    </PreviewStyleHOC>
  );
};

export default BlockCombined;
