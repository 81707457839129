import React, { useMemo } from "react";
import { socialLinkIconRender } from "@features/design/components/CommonBlockComponent/BlockBio/const";
import draftToHtml from "draftjs-to-html";
import * as DOMPurify from "dompurify";
import CoverImage from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts/CoverImage";
import cls from "classnames";
import Avatar from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts/Avatar";
import BioContent from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts/BioContent";
import BookingProfile from "../BookingProfile";

const BioLayout3 = (props) => {
  // Layout 7
  const { content_attributes, style_attributes } = props;
  const {
    social_link_items,
    bio_image,
    bio_name,
    bio_subtitle,
    bio_content,
    bio_show_cover_image,
    bio_cover_image_list,
    bio_booking_profile_title,
    bio_booking_profile_description,
    bio_booking_profile_button_label,
    bio_booking_profile_display,
  } = content_attributes || {};

  const socialLinkItemsEnable = useMemo(
    () => social_link_items?.filter((x) => x.enable === 1),
    [social_link_items]
  );

  const coverImageList = useMemo(
    () => bio_cover_image_list?.filter((x) => x.enable === 1),
    [bio_cover_image_list]
  );

  return (
    <>
      {bio_show_cover_image && coverImageList.length > 0 && (
        <CoverImage
          content_attributes={content_attributes}
          style_attributes={style_attributes}
        />
      )}
      <div
        className={cls(
          "flex flex-col text-left bio-wrapper-style-2 relative",
          bio_show_cover_image && coverImageList.length > 0 && "mt-[-40px] mx-3"
        )}
      >
        <div className="flex relative z-[999]">
          <div>
            <Avatar
              className="relative"
              size={100}
              url={bio_image}
              sizeBorder={3}
              color={"rgba(255, 255, 255, 0.75)"}
            />
          </div>

          <div className="bio-title-wrapper pt-[40px] pl-[12px]">
            <h4
              className="bio-name"
              style={{
                color: "var(--heading-color-6)",
              }}
            >
              {bio_name}
            </h4>
            {bio_subtitle && (
              <div
                className="bio-subtitle"
                style={{
                  color: "var(--text-color-6)",
                }}
              >
                {bio_subtitle}
              </div>
            )}
          </div>
        </div>
        {!!bio_content && (
          <BioContent
            data={bio_content}
            className=" mt-[10px]"
          />
        )}
        {socialLinkItemsEnable?.length > 0 && (
          <div className="flex flex-row gap-3 bio-social-network flex-wrap mt-3">
            {socialLinkItemsEnable?.map((ele, index) => (
              <div key={index}>{socialLinkIconRender(ele)}</div>
            ))}
          </div>
        )}
        {
          bio_booking_profile_display &&
          <BookingProfile
            bioBookingProfileTitle={bio_booking_profile_title}
            bioBookingProfileDescription={bio_booking_profile_description}
            bioBookingProfileButtonLabel={bio_booking_profile_button_label}
          />
        }
      </div>
    </>
  );
};
export default BioLayout3;
