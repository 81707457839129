import {
  BLOCK_TYPE,
  BLOCK_TYPE_LANGUAGE_KEY,
} from "@features/block/block.conf";
import { BLOCK_FILTER_GROUP_TYPE } from "@share/configs/const";
import {
  BioIconColor,
  ImageSliderIconColor,
  AffiliateIconV2,
  ButtonLinkIconColor,
  SpotLightIconColor,
  DigitalProducticonV2,
} from "@share/icons/block-icons";

export const HIGHLIGHT_GROUP = {
  name_key: "ss_builder_highlight_block_label",
  description_key: "",
  slug: BLOCK_FILTER_GROUP_TYPE.HIGHLIGHT,
  block_list: [
    {
      name_key: "ss_builder_title_block_affiliate_product",
      block_type: BLOCK_TYPE.AFF_PRODUCT_GROUP,
      description_key: "ss_builder_block_affiliate_description",
      Icon: (width, height) => (
        <AffiliateIconV2
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.DIGITAL_PRODUCT],
      block_type: BLOCK_TYPE.DIGITAL_PRODUCT,
      description_key: "ss_builder_block_digital_product_description",
      Icon: (width, height) => (
        <DigitalProducticonV2
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.BIO],
      block_type: BLOCK_TYPE.BIO,
      description_key: "ss_builder_block_bio_description",
      Icon: (width, height) => (
        <BioIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.STORY],
      block_type: BLOCK_TYPE.STORY,
      description_key: "ss_builder_block_button_story_description",
      Icon: (width, height) => (
        <SpotLightIconColor
          width={width}
          height={height}
        />
      ),
    },
  ],
};
