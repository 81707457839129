import { useAppSelector } from "@app/hooks";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import { TEMPLATE_KEY, TEMPLATE_RENDER_LIST_KEY } from "@share/configs/const";
import {
  CheckCircleIconV2,
  CommentInformationIcon,
  DeleteIcon,
  InformationTriangleIcon,
  MoreIcon,
  EyeVisibleIcon,
  EyeHiddenIcon,
} from "@share/icons";
import { Modal, Popover, Image } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getHorizontalResizeUrl } from "@features/share/helper/image";
import cls from "classnames";

export interface templateInterface {
  id: string;
  user_id?: string;
  title: string;
  image: string;
  status?: any;
  tags?: Array<string>;
}

type Props = {
  templateData?: templateInterface[];
  onSelectTemplate: any;
  onEditTemplate?: any;
  onDeleteTemplate?: any;
  KEY?: string;
  onShowHideTemplate?: any;
};

const TemplateList = (props: Props) => {
  const {
    templateData,
    onSelectTemplate,
    onEditTemplate,
    onDeleteTemplate,
    KEY,
    onShowHideTemplate,
  } = props;
  const popoverRef = useRef(null);
  const { t } = useTranslation();
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const creator_role = passioPageProfile?.creator_role;

  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );

  const getAction = (item) => {
    return [
      {
        key: "edit-template",
        icon: (
          <CommentInformationIcon
            className="w-5 h-5"
            fillColor="white"
          />
        ),
        text: t("ss_builder_edit_theme_template_label"),
      },
      {
        key: item?.status === 1 ? "hide-template" : "show-template",
        icon:
          item?.status === 1 ? (
            <EyeVisibleIcon className="w-5 h-5" />
          ) : (
            <EyeHiddenIcon className="w-5 h-5" />
          ),
        text:
          item?.status === 1
            ? t("ss_builder_hide_theme_template_label")
            : t("ss_builder_show_theme_template_label"),
      },
      {
        key: "delete-template",
        icon: <DeleteIcon className="w-5 h-5" />,
        text: t("ss_builder_delete_theme_template_label"),
      },
    ];
  };
  const [selectedTemplateData, setSelectedTemplateData] = useState(null);
  const handleSelectTemplate = (data) => {
    if (KEY === TEMPLATE_RENDER_LIST_KEY.TEMPLATE_OVERWRITE_SELECTION) {
      if (data.id === selectedTemplateData?.id) {
        setSelectedTemplateData(null);
        onSelectTemplate(null);
      } else {
        setSelectedTemplateData(data);
        onSelectTemplate(data);
      }
    } else {
      onSelectTemplate(data);
    }
  };

  useEffect(() => {
    if (!!selectedTemplateData) onSelectTemplate(selectedTemplateData);
  }, [selectedTemplateData]);

  const handleClickMoreIcon = (event) => {
    event.preventDefault();
    // event.stopPropagation();
  };

  const handleClickPopoverItem = (item: Action, id: string) => {
    const key = item && item.key;
    const data = templateData?.find((x) => x.id === id);
    switch (key) {
      case "edit-template":
        handleEditTemplate(data);
        break;
      case "delete-template":
        handleDeleteTemplate(id);
        break;
      case "hide-template":
        handleShowHideTemplate(data);
        break;
      case "show-template":
        handleShowHideTemplate(data);
        break;
      default:
      // to do
    }
  };

  const handleEditTemplate = (templateData) => {
    onEditTemplate(templateData);
  };

  const handleShowHideTemplate = (templateData) => {
    onShowHideTemplate(templateData);
  };

  const handleDeleteTemplate = (templateId) => {
    Modal.confirm({
      header: (
        <InformationTriangleIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_confirm_delete_template_modal_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm_delete_template_modal_content")}
        </div>
      ),
      confirmText: t(
        "ss_builder_confirm_delete_theme_template_modal_btn_label"
      ),
      cancelText: t("ss_builder_cancel_delete_theme_template_modal_btn_label"),
      onConfirm: () => {
        onDeleteTemplate(templateId);
      },
      onCancel: () => {
        return;
      },
    });
  };

  return !!templateData?.length ? (
    <div
      className="px-4 pt-4 pb-[65px] bg-white"
      ref={popoverRef}
    >
      <div className="grid grid-cols-3 gap-3 bg-[#fff] mb-3">
        {templateData?.map((ele, index) => {
          const listActions = getAction(ele);
          return (
            <div key={ele.id}>
              <div
                className={cls(
                  "relative cursor-pointer",
                  ele.status !== 1 && "opacity-40"
                )}
              >
                <div
                  // src={getHorizontalResizeUrl(ele.image)}
                  // fit="cover"
                  style={{
                    background: `url("${getHorizontalResizeUrl(
                      ele.image
                    )}") center top/cover`,
                    aspectRatio: "9/16",
                    borderRadius: "4px",
                    border:
                      ele.id === selectedTemplateData?.id
                        ? "2px solid #EE3244"
                        : "2px solid #00000029",
                    backgroundPosition: "center top",
                    backgroundSize: "cover",
                  }}
                  onClick={() => handleSelectTemplate(ele)}
                ></div>
                {ele.id === selectedTemplateData?.id && (
                  <div className="absolute top-2 right-2 rounded-full bg-[#EE3244]">
                    <CheckCircleIconV2
                      className="w-5 h-5 "
                      fillColor="white"
                    />
                  </div>
                )}
                {KEY !==
                  TEMPLATE_RENDER_LIST_KEY.TEMPLATE_OVERWRITE_SELECTION &&
                  isAdminRole && (
                    <div
                      className="absolute top-2 right-2 rounded-[4px] bg-[#00000099] p-[2px]"
                      onClick={handleClickMoreIcon}
                    >
                      <Popover.Menu
                        mode="dark"
                        actions={listActions}
                        placement="bottom"
                        trigger="click"
                        stopPropagation={["click"]}
                        getContainer={() => popoverRef.current}
                        onAction={(item: Action) =>
                          handleClickPopoverItem(item, ele.id)
                        }
                      >
                        <MoreIcon
                          className="w-4 h-4 "
                          fillColor="white"
                        />
                      </Popover.Menu>
                    </div>
                  )}
              </div>
              <div className="text-xs leading-4 text-[#8C8C8C] mt-2 text-ellipsis overflow-hidden whitespace-nowrap">
                {ele.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="mt-2">
      <Image
        className="m-auto"
        src="https://image.passio.eco/page-builder/ssp-landing-page/product-searching-empty-image.png"
        width={260}
        height={128}
      />
      <div className="text-center mt-2">
        <div className="font-medium text-[15.6px] leading-[20.28px] text-neutral-900">
          {t("ss_builder_empty_search")}
        </div>
        <div className="mt-1 font-normal text-[13px] leading-[16.9px] text-neutral-500">
          {t("ss_builder_try_new_key")}
        </div>
      </div>
    </div>
  );
};

export default TemplateList;
