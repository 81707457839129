import { ArrowRightIcon } from "@share/icons";
import { Button } from "antd-mobile";
import cls from "classnames";

type BookingProfileProps = {
  bioBookingProfileTitle?: string;
  bioBookingProfileDescription?: string;
  bioBookingProfileButtonLabel?: string;
  className?: string;
}

const BookingProfile = ({
  bioBookingProfileTitle,
  bioBookingProfileDescription,
  bioBookingProfileButtonLabel,
  className = "",
}: BookingProfileProps) => {

  return (
    <>
      {
        bioBookingProfileButtonLabel && (
          <div className={cls(`${className}`)}>
            {
              bioBookingProfileTitle &&
              <h5
                style={{
                  color: "var(--text-color-6)",
                  margin: 0,
                }}
              >
                {bioBookingProfileTitle}
              </h5>
            }
            {
              bioBookingProfileDescription &&
              <div
                className="mb-2"
                style={{
                  color: "var(--text-color-6)"
                }}
              >
                {bioBookingProfileDescription}
              </div>
            }
            {bioBookingProfileButtonLabel &&
              <Button
                fill="solid"
                size="small"
                className="pp-button"
              >
                <div className="flex items-center gap-x-2">
                  {bioBookingProfileButtonLabel}
                  <ArrowRightIcon 
                    fillColor="var(--button-text-color)" 
                    style={{
                      width: "var(--middle-button-font-size)",
                      height: "var(--middle-button-font-size)",
                    }} 
                  />
                </div>
              </Button>}
          </div>
        )
      }

    </>
  )
}

export default BookingProfile;