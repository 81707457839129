import React from "react";
import cls from "classnames";
import { Button } from "antd-mobile";

interface Props {
  onCancel: (val) => void;
  onSave: (val) => void;
  cancelLabel: string;
  saveLabel: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

const ButtonSavingGroup = (props: Props) => {
  const {
    onCancel,
    onSave,
    cancelLabel,
    saveLabel,
    disabled,
    loading,
    className,
  } = props;
  return (
    <div
      className={cls(
        "max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]",
        className
      )}
    >
      <div className="w-1/2 text-center">
        <Button
          className="w-full"
          onClick={onCancel}
        >
          {cancelLabel}
        </Button>
      </div>
      <div className="w-1/2 text-center">
        <Button
          className="w-full"
          color={"primary"}
          onClick={onSave}
          disabled={disabled}
          loading={loading}
        >
          {saveLabel}
        </Button>
      </div>
    </div>
  );
};

export default ButtonSavingGroup;
