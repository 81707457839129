import { TAB_TEMPLATE } from "@share/configs/const";
import { TabBar } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import TabTemplateStyle from "../../TabTemplateStyle";

const EqualTabWidth = (props) => {
  const {
    tabListBody,
    style_attributes,
    onUpdateTabActive,
    tabActiveDefault,
    onDoubleTab,
  } = props;
  // const activedTabDefault = useMemo(
  //   () => tabListBody?.find((x) => x.isDefault === true)?.uid,
  //   [tabListBody]
  // );
  const [activeTab, setActiveTab] = useState(tabActiveDefault || tabListBody[0].uid);

  useEffect(() => {
    if (tabActiveDefault) {
      setActiveTab(tabActiveDefault);
    }
  }, [tabActiveDefault]);

  useEffect(() => {
    if (activeTab) onUpdateTabActive(activeTab);
  }, [activeTab]);

  return (
    <div className="max-width-content-class flex flex-row gap-2">
      {tabListBody.map((item, index) => (
        <div
          key={index}
          style={{
            width: `${500 / tabListBody.length}px`,
          }}
          // className={
          //   style_attributes?.tab_template === TAB_TEMPLATE.TEMPLATE_2
          //     ? "h-full"
          //     : ""
          // }
        >
          <TabTemplateStyle
            key={index}
            item={item}
            onSelect={(ele) => {
              setActiveTab(item.uid);
            }}
            onDoubleTab={onDoubleTab}
            selected={activeTab === item.uid}
            style_attributes={style_attributes}
          />
        </div>
      ))}
    </div>
  );
};

export default EqualTabWidth;
