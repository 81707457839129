import AffiliateProductGroupAddNew from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductGroupManagement/AffiliateProductGroupAddNew";
import PopupComponent from "@share/components/PopupComponent";
import { AddIcon, InformationTriangleIcon } from "@share/icons";
import { Modal } from "antd-mobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const GroupAddNewButton = ({
  affiliateProductGroupData,
  onAddNewProductGroup,
  productGroupMaximum,
}) => {
  const { t } = useTranslation();
  const [isOpenGroupAddNew, setIsOpenGroupAddNew] = useState(false);

  const disabled = affiliateProductGroupData?.length >= productGroupMaximum;

  const handleClickAddGroup = (e) => {
    e.stopPropagation();
    if (disabled) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationTriangleIcon
            className="w-12 h-12"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px] mb-2">
              {t("ss_builder_product_group_total_limitation_warning_title")}
            </div>
            <div className="">
              {t("ss_builder_product_group_total_limitation_warning_content", {
                group_quantity_maximum: productGroupMaximum,
              })}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_confirm_replace_theme_template_understood"),
          },
        ],
      });
    } else setIsOpenGroupAddNew(true);
  };

  return (
    <>
      <div
        className="flex flex-shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full bg-[#2563EB] cursor-pointer ml-[8px]"
        onClick={handleClickAddGroup}
        style={{
          opacity: disabled ? "40%" : "",
        }}
      >
        <AddIcon className="w-[15px] h-[15px]" />
      </div>
      <PopupComponent
        visible={isOpenGroupAddNew}
        // onMaskClick={() => setIsOpenGroupAddNew(false)}
        title={t("ss_builder_add_new_affiliate_product_group_btn_label")}
        onClose={() => setIsOpenGroupAddNew(false)}
      >
        <AffiliateProductGroupAddNew
          onCancelAffiliateProductGroupAddNew={() =>
            setIsOpenGroupAddNew(false)
          }
          onSaveAffiliateProductGroupAddNew={onAddNewProductGroup}
          affiliateProductGroupList={affiliateProductGroupData}
        />
      </PopupComponent>
    </>
  );
};

export default GroupAddNewButton;
