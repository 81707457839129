import { TAB_DISPLAY } from "@share/configs/const";
import React, { useCallback } from "react";
import IconStyle from "./IconStyle";
import TextAndIconStyle from "./TextAndIconStyle";
import TextStyle from "./TextStyle";

export interface StyleAttributesInterface {
  display_style: string;
  ghim_style: string;
  position_style: string;
  tab_layout: string;
  tab_width_display: string;
}

type Props = {
  style_attributes: StyleAttributesInterface;
  tab: any;
};

export const TAB_STYLE_COMPONENT = {
  [TAB_DISPLAY.TEXT_AND_ICON]: TextAndIconStyle,
  [TAB_DISPLAY.TEXT]: TextStyle,
  [TAB_DISPLAY.ICON]: IconStyle,
};

const TabStyleDisplay = (props: Props) => {
  //   const { style_attributes, tab } = props;
  const TabLayoutRender = useCallback((props) => {
    let Element = <></>;
    if (TAB_STYLE_COMPONENT[props?.style_attributes?.display_style]) {
      const RenderComponent =
        TAB_STYLE_COMPONENT[props?.style_attributes?.display_style];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);

  return <TabLayoutRender {...props} />;
};

export default TabStyleDisplay;
