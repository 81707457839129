import { Block, POSITION_SLUG, Position } from "@features/main/store/interface";

export function getBlockListsOfPosition(
  position: Position
): Block[] {
  if (position.slug === POSITION_SLUG.BODY) {
    let blockListsArr = [];
    const tabLists = position?.block_list[0]?.content_attributes?.tabs;
    for (let tab of tabLists) {
      const blockLists = tab?.block_list;
      blockListsArr = [...blockListsArr, ...blockLists];
    }
    return blockListsArr;
  } else {
    return position?.block_list;
  }
}
