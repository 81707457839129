import { BLOCK_TYPE } from "@features/block/block.conf";
import BlockAffiliateProduct from "./components/CommonBlockComponent/BlockAffiliateProduct";
import BlockBio from "./components/CommonBlockComponent/BlockBio";
import BlockButtonLink from "./components/CommonBlockComponent/BlockButtonLink";
import BlockDigitalProduct from "./components/CommonBlockComponent/BlockDigitalProduct";
import BlockEmbed from "./components/CommonBlockComponent/BlockEmbed";
import BlockImage from "./components/CommonBlockComponent/BlockImage";
import BlockImageSlider from "./components/CommonBlockComponent/BlockImageSlider";
import BlockStory from "./components/CommonBlockComponent/BlockStory";
import BlockTabs from "./components/CommonBlockComponent/BlockTabs";
import BlockText from "./components/CommonBlockComponent/BlockText";
import BlockAffiliateProductGroup from "@features/design/components/CommonBlockComponent/BlockAffiliateProductGroup";
import BlockCard from "./components/CommonBlockComponent/BlockCard";
import BlockImageGallery from "./components/CommonBlockComponent/BlockImageGallery";
import { MINI_STORE_DESIGN_COMPONENT } from "@features/mini-store/mini-store.config";
import BlockVirtualGift from "@features/mini-store/design/BlockVirtualGift";
import BlockButtonMultiLink from "./components/CommonBlockComponent/BlockButtonMultiLink";
import { VIDEO_SHOUTOUT_DESIGN_COMPONENT } from "@features/video-shoutout/config";
import ImagePopup from "./components/CommonBlockComponent/ImagePopup";
import BlockSpacerAndDivider from "./components/CommonBlockComponent/BlockSpacerAndDivider";
import BlockCombined from "./components/CommonBlockComponent/BlockCombined";
import BlockBookingProfile from "./components/CommonBlockComponent/BlockBookingProfile";

export const DESIGN_COMPONENT = {
  [BLOCK_TYPE.TEXT]: BlockText,
  [BLOCK_TYPE.TAB_BAR]: BlockTabs,
  [BLOCK_TYPE.IMAGE_SLIDER]: BlockImageSlider,
  [BLOCK_TYPE.AFF_PRODUCT]: BlockAffiliateProduct,
  [BLOCK_TYPE.STORY]: BlockStory,
  [BLOCK_TYPE.BIO]: BlockBio,
  [BLOCK_TYPE.IMAGE]: BlockImage,
  [BLOCK_TYPE.BUTTON_LINK]: BlockButtonLink,
  [BLOCK_TYPE.DIGITAL_PRODUCT]: BlockDigitalProduct,
  [BLOCK_TYPE.AFF_PRODUCT_GROUP]: BlockAffiliateProductGroup,
  [BLOCK_TYPE.EMBED]: BlockEmbed,
  [BLOCK_TYPE.CARD]: BlockCard,
  [BLOCK_TYPE.IMAGE_GALLERY]: BlockImageGallery,
  [BLOCK_TYPE.GIFT]: BlockVirtualGift,
  [BLOCK_TYPE.BUTTON_MULTI_LINK]: BlockButtonMultiLink,
  [BLOCK_TYPE.IMAGE_POPUP]: ImagePopup,
  [BLOCK_TYPE.SPACER_AND_DIVIDER]: BlockSpacerAndDivider,
  [BLOCK_TYPE.COMBINED]: BlockCombined,
  [BLOCK_TYPE.BOOKING_PROFILE]: BlockBookingProfile,
  ...MINI_STORE_DESIGN_COMPONENT,
  // ...VIDEO_SHOUTOUT_DESIGN_COMPONENT,
};

// expand position config
export const EXPAND_POSITION = [
  BLOCK_TYPE.IMAGE_POPUP,
  BLOCK_TYPE.GIFT_FOOTER,
  BLOCK_TYPE.TRUTH_OR_DARE_FOOTER,
  BLOCK_TYPE.BRAND_GIFT_FOOTER,
];

// floating
export enum BLOCK_GROUP_TYPE {
  POPUP = "POPUP",
  FLOATING = "FLOATING",
}

export const COUNT_OF_FLOATING_BLOCK_MAXIMUM = 2;
export const COUNT_OF_BLOCK_MAXIMUM = 10;

export const BLOCK_TYPE_BY_GROUP = {
  [BLOCK_GROUP_TYPE.POPUP]: ["image_popup"],
  [BLOCK_GROUP_TYPE.FLOATING]: [
    "gift_footer",
    // "tod_footer",
    "brand_gift_footer",
  ],
};
