import { PRODUCT_ON_ROW } from "@share/configs/const";
import React, { useMemo } from "react";
import ProductDetailStyle1 from "../../ProductDetail/ProductDetailStyle1";

const ProductStyleGrid = (props) => {
  const {
    products,
    styleAttributes,
    affiliateProductGroupData,
    onUpdateProductGroupData,
    productGroupMaximum,
  } = props;

  const { style_display, item_on_row } = styleAttributes;

  const productListEnable = products?.filter((x) => x.enable === 1);

  const oldDisplayValueStyle = useMemo(() => {
    let gridStyleValue = null;
    switch (style_display) {
      case "grid-1":
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case "grid-2":
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case "grid-3":
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
    }
    return gridStyleValue;
  }, [style_display]);

  const displayValueStyle = useMemo(() => {
    let gridStyleValue = null;
    switch (item_on_row) {
      case PRODUCT_ON_ROW.ONE:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case PRODUCT_ON_ROW.TWO:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case PRODUCT_ON_ROW.THREE:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
    }
    return gridStyleValue;
  }, [item_on_row]);

  return (
    <div>
      <div
        className="affiliate-product-wrapper"
        style={
          style_display.includes("grid-")
            ? oldDisplayValueStyle
            : displayValueStyle
        }
      >
        {productListEnable?.map((ele, index) => (
          <ProductDetailStyle1
            product={ele}
            key={index}
            styleAttributes={styleAttributes}
            affiliateProductGroupData={affiliateProductGroupData}
            onUpdateProductGroupData={onUpdateProductGroupData}
            productGroupMaximum={productGroupMaximum}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductStyleGrid;
