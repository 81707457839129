import { useAppDispatch, useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import { Image } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockImage = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const style_attibutes = blockData?.style_attributes;
  const content_attributes = blockData?.content_attributes;
  const { ratio, image_orientation } = style_attibutes || {};
  const {
    block_heading,
    block_description,
    image: dataImage,
  } = content_attributes || {};

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <BlockHeading
        blockType={blockData.block_type}
        title={block_heading}
        subtitle={block_description}
      />
      <Image
        className="image-design image-border-radius"
        src={dataImage}
        fit="cover"
        style={{
          backgroundSize: "cover",
          aspectRatio: ratio,
        }}
      />
    </BlockWrapper>
  );
};

export default BlockImage;
