import React, { useMemo } from "react";
import { socialLinkIconRender } from "@features/design/components/CommonBlockComponent/BlockBio/const";
import draftToHtml from "draftjs-to-html";
import * as DOMPurify from "dompurify";
import CoverImage from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts/CoverImage";
import Avatar from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts/Avatar";
import BioContent from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts/BioContent";
import BookingProfile from "../BookingProfile";

const BioLayout1 = (props) => {
  // Layout 1,2,3,4
  const { content_attributes, style_attributes } = props;
  const {
    social_link_items,
    bio_image,
    bio_name,
    bio_subtitle,
    bio_content,
    bio_show_cover_image,
    bio_cover_image_list,
    bio_booking_profile_title,
    bio_booking_profile_description,
    bio_booking_profile_button_label,
    bio_booking_profile_display,
  } = content_attributes || {};
  
  const { style_display } = style_attributes || {};

  const socialLinkItemsEnable = useMemo(
    () => social_link_items?.filter((x) => x.enable === 1),
    [social_link_items]
  );

  const coverImageList = useMemo(
    () => bio_cover_image_list?.filter((x) => x.enable === 1),
    [bio_cover_image_list]
  );

  const BioContentElement = useMemo(() => {
    let bioElement = null;
    switch (style_display) {
      case "style-1":
        bioElement = (
          <div className="flex flex-col text-center items-center gap-3 w-full bio-wrapper-style-1">
            <div className="gap-1 flex flex-col text-center items-center">
              <Avatar
                size={120}
                url={bio_image}
              />
              <div className="bio-title-wrapper">
                <h4
                  className="w-full bio-name"
                  style={{
                    color: "var(--heading-color-6)",
                  }}
                >
                  {bio_name}
                </h4>
                {bio_subtitle && (
                  <div
                    className="w-full bio-subtitle"
                    style={{
                      color: "var(--text-color-6)",
                    }}
                  >
                    {bio_subtitle}
                  </div>
                )}
              </div>
            </div>

            {socialLinkItemsEnable?.length > 0 && (
              <div className="flex flex-row gap-3 bio-social-network flex-wrap">
                {socialLinkItemsEnable?.map((ele, index) => (
                  <div key={index}>{socialLinkIconRender(ele)}</div>
                ))}
              </div>
            )}
            {
              bio_booking_profile_display && 
              <BookingProfile
                bioBookingProfileTitle={bio_booking_profile_title}
                bioBookingProfileDescription={bio_booking_profile_description}
                bioBookingProfileButtonLabel={bio_booking_profile_button_label}
              />
            }
            {!!bio_content && (
              <BioContent
                data={bio_content}
                isCenter
              />
            )}
          </div>
        );
        break;
      case "style-2":
        bioElement = (
          <div className="flex flex-col text-left gap-3 bio-wrapper-style-2">
            <div className="flex flex-col text-left gap-1 ">
              <Avatar
                size={120}
                url={bio_image}
              />
              <div className="bio-title-wrapper">
                <h4
                  className="bio-name"
                  style={{
                    color: "var(--heading-color-6)",
                  }}
                >
                  {bio_name}
                </h4>
                {bio_subtitle && (
                  <div
                    className="bio-subtitle"
                    style={{
                      color: "var(--text-color-6)",
                    }}
                  >
                    {bio_subtitle}
                  </div>
                )}
              </div>
            </div>
            {!!bio_content && <BioContent data={bio_content} />}
            {socialLinkItemsEnable?.length > 0 && (
              <div className="flex flex-row gap-3 bio-social-network flex-wrap">
                {socialLinkItemsEnable?.map((ele, index) => (
                  <div key={index}>{socialLinkIconRender(ele)}</div>
                ))}
              </div>
            )}
            {
              bio_booking_profile_display && 
              <BookingProfile
                bioBookingProfileTitle={bio_booking_profile_title}
                bioBookingProfileDescription={bio_booking_profile_description}
                bioBookingProfileButtonLabel={bio_booking_profile_button_label}
              />
            }
          </div>
        );
        break;
      case "style-3":
        bioElement = (
          <div className="flex flex-col text-left gap-3 bio-wrapper-style-4">
            <div className="flex flex-col text-left gap-1">
              <div className="flex flex-row justify-between items-start">
                <Avatar
                  size={80}
                  url={bio_image}
                />
                {socialLinkItemsEnable?.length > 0 && (
                  <div
                    className="flex flex-wrap w-[132px] bio-social-network justify-end"
                    style={{
                      width: "132px",
                      marginLeft: "-6px",
                      marginRight: "-6px",
                      maxWidth: "calc(100% - 132px)",
                    }}
                  >
                    {socialLinkItemsEnable?.map((ele, index) => (
                      <div
                        key={index}
                        className="overflow-hidden"
                        style={{
                          width: "32px",
                          margin: "0 6px 12px 6px",
                        }}
                      >
                        {socialLinkIconRender(ele)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="bio-title-wrapper">
                <h4
                  className="bio-name"
                  style={{
                    color: "var(--heading-color-6)",
                  }}
                >
                  {bio_name}
                </h4>
                {bio_subtitle && (
                  <div
                    className="bio-subtitle"
                    style={{
                      color: "var(--text-color-6)",
                    }}
                  >
                    {bio_subtitle}
                  </div>
                )}
              </div>
            </div>
            {!!bio_content && <BioContent data={bio_content} />}
            {
              bio_booking_profile_display && 
              <BookingProfile
                bioBookingProfileTitle={bio_booking_profile_title}
                bioBookingProfileDescription={bio_booking_profile_description}
                bioBookingProfileButtonLabel={bio_booking_profile_button_label}
              />
            }
          </div>
        );
        break;
      case "style-4":
        bioElement = (
          <div className="flex flex-row gap-3 bio-wrapper-style-3">
            <Avatar
              size={80}
              url={bio_image}
            />
            <div
              className="flex flex-col gap-3 grow overflow-hidden"
              style={{
                width: "calc(100% - 92px)",
              }}
            >
              <div className="bio-title-wrapper">
                <h4 className="bio-name" style={{
                  color: "var(--heading-color-6)",
                }}>{bio_name}</h4>
                {bio_subtitle && (
                  <div className="bio-subtitle" style={{
                    color: "var(--text-color-6)",
                  }}>{bio_subtitle}</div>
                )}
              </div>
              {!!bio_content && (
                <BioContent
                  data={bio_content}
                  className="break-words"
                />
              )}
              {socialLinkItemsEnable?.length > 0 && (
                <div className="flex flex-row gap-3 bio-social-network flex-wrap">
                  {socialLinkItemsEnable?.map((ele, index) => (
                    <div key={index}>{socialLinkIconRender(ele)}</div>
                  ))}
                </div>
              )}
              {
                bio_booking_profile_display && 
                <BookingProfile
                  bioBookingProfileTitle={bio_booking_profile_title}
                  bioBookingProfileDescription={bio_booking_profile_description}
                  bioBookingProfileButtonLabel={bio_booking_profile_button_label}
                />
              }
            </div>
          </div>
        );

        break;
    }
    return bioElement;
  }, [
    style_display,
    bio_image,
    bio_name,
    bio_subtitle,
    socialLinkItemsEnable,
    bio_content,
  ]);

  return (
    <>
      {bio_show_cover_image && coverImageList.length > 0 && (
        <CoverImage
          content_attributes={content_attributes}
          style_attributes={style_attributes}
        />
      )}
      {BioContentElement}
    </>
  );
};
export default BioLayout1;
