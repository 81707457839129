import { Block } from "@features/main/store/interface";
import { MAX_PRODUCTS_ALL_GROUPS, PRODUCT_MAX } from "@share/configs/const";
import { AddIcon } from "@share/icons";
import { Button } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import QuickActionsComponent from "./QuickActionsComponent";

type Props = {
  productGroupId: string;
  affiliateProductGroupData: any;
  groupItem: any;
  productGroupMaximum: number;
  blockData: any;
  positionSlug: string;
  tabUid: string;
  isCreatedNew: boolean;
  onAddNewProduct: (e: any) => void;
  onGetLatestBlockData?: (block: Block) => void;
};

const GroupTitleComponent = (props: Props) => {
  const {
    productGroupId,
    affiliateProductGroupData,
    groupItem,
    productGroupMaximum,
    blockData,
    positionSlug,
    tabUid,
    isCreatedNew,
    onAddNewProduct,
    onGetLatestBlockData = () => {},
  } = props;

  const { t } = useTranslation();

  const title = groupItem?.content_attributes?.title_product_group;

  const isFulledProductsAllGroups = useMemo(() => {
    let existedProductArr = [];
    affiliateProductGroupData.forEach((x) => {
      const affiliateProductArr = x?.content_attributes?.items || [];
      existedProductArr = [...existedProductArr, ...affiliateProductArr];
    });
    return !!(existedProductArr?.length >= MAX_PRODUCTS_ALL_GROUPS);
  }, [affiliateProductGroupData]);

  const isAllowedToAddProducts = useMemo(
    () =>
      productGroupId
        ? affiliateProductGroupData
            ?.find((x) => x?.uid === productGroupId)
            ?.content_attributes?.items?.filter((y) => y?.is_cleanup_lock === 1)
            ?.length < PRODUCT_MAX
        : true,
    [productGroupId, affiliateProductGroupData]
  );

  const isDisableAddProductBtn = !!(
    isFulledProductsAllGroups || !isAllowedToAddProducts
  );

  const isEnabled = groupItem?.enable === 1;

  return (
    <>
      <div className="flex flex-row items-center justify-between mb-3">
        <h4
          style={{
            color: "var(--heading-color-6)",
            opacity: !isEnabled ? "60%" : "",
          }}
        >
          {title}
        </h4>
        <div className="flex flex-row gap-2 items-center">
          <Button
            size="mini"
            style={{
              backgroundColor: "#2563EB",
              borderRadius: "4px",
              color: "#FFFFFF",
              border: "1px solid #2563EB",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
              opacity: isDisableAddProductBtn ? "40%" : "",
              height: "26px",
              padding: "3px 8px",
            }}
            onClick={onAddNewProduct}
          >
            <AddIcon className="w-[13px] h-[13px]" />
            <span>{t("ss_builder_add_new_label")}</span>
          </Button>
          <QuickActionsComponent
            groupItem={groupItem}
            productGroupId={productGroupId}
            affiliateProductGroupData={affiliateProductGroupData}
            productGroupMaximum={productGroupMaximum}
            blockData={blockData}
            positionSlug={positionSlug}
            tabUid={tabUid}
            isCreatedNew={isCreatedNew}
            onGetLatestBlockData={onGetLatestBlockData}
          />
        </div>
      </div>
    </>
  );
};

export default GroupTitleComponent;
