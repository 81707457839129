import { useResizeObserveElement } from "@share/hooks";
import { AngelDownIcon, AngelUpIcon } from "@share/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export enum STATUS {
  INITIAL = "initial",
  EXPAND = "expand",
  COLLAPSE = "collapse",
}

interface Props {
  children: any;
  maxHeight: number;
  onUpdateStatus?: (status) => void;
}

const ExpandBlockHandler = ({ children, maxHeight, onUpdateStatus }: Props) => {
  const { t } = useTranslation();

  const childrenRef = useRef<HTMLDivElement>();
  const buttonRef = useRef<HTMLDivElement>();

  const [isHeightLimit, setIsHeighLimt] = useState(true);
  const { height: elementHeight } = useResizeObserveElement(childrenRef);

  const isChildrenHigher = elementHeight >= maxHeight;
  const isButtonEnable = isChildrenHigher;

  const handleExpandAndCollapse = () => {
    if (!isHeightLimit) {
      setTimeout(() => {
        buttonRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);
    }
    setIsHeighLimt((state) => !state);
  };

  const buttonContent = useMemo(() => {
    if (isButtonEnable) {
      if (isHeightLimit) {
        onUpdateStatus(STATUS.COLLAPSE);
        return (
          <>
            <span className="text-[#FFFFFF] text-[13px] leading-[150%] font-medium whitespace-nowrap">
              {t("ss_build_expand_block_label")}
            </span>
            <AngelDownIcon
              className="p-0"
              width={16}
              height={16}
              fillColor="#FFFFFF"
            />
          </>
        );
      } else {
        onUpdateStatus(STATUS.EXPAND);
        return (
          <>
            <span className="text-[#FFFFFF] text-[13px] leading-[150%] font-medium whitespace-nowrap">
              {t("ss_build_collapse_block_label")}
            </span>
            <AngelUpIcon
              className="p-0"
              width={16}
              height={16}
              fillColor="#FFFFFF"
            />
          </>
        );
      }
    } else {
      onUpdateStatus(STATUS.INITIAL);
    }
  }, [isHeightLimit, isButtonEnable]);

  return (
    <div
      style={{
        maxHeight: isChildrenHigher && isHeightLimit ? maxHeight : "",
        paddingBottom: isChildrenHigher ? "44px" : "",
      }}
      className="relative overflow-y-clip"
    >
      <div ref={childrenRef}>{children}</div>
      {isButtonEnable && (
        <div
          ref={buttonRef}
          className="flex flex-row gap-1 justify-center items-center absolute bottom-0 left-[50%] translate-x-[-50%] rounded-[100px] h-8 w-[92px] cursor-pointer z-[15]"
          style={{
            border: "1px solid rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(50px)",
            padding: "0px 8px 0px 12px",
            background: "rgba(0, 0, 0, 0.15)",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleExpandAndCollapse();
          }}
        >
          {buttonContent}
        </div>
      )}
    </div>
  );
};

export default ExpandBlockHandler;
