import { Button, Selector } from "antd-mobile";
import React from "react";
import ListItemScrollHorizontal from "@features/design/components/ListItemScrollHorizontal";
import CategoryItemDetail from "@features/block/components/BlockSelectionV2/components/Categories/item";
import gaEvents, { ACTION_CONFIG, CATEGORY_CONFIG } from "@share/lib/ga-events";
import { handleCapitalizeFirstLetter } from "@share/lib";
import {useSelector} from "react-redux";
import {selectProfile} from "@features/user/userSlice";
import {useAppSelector} from "@app/hooks";

const Categories = ({ items, onChange, selectedGroup }) => {
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  return (
    <div style={{ marginTop: "12px", backgroundColor: "rgba(255, 255, 255)" }}>
      <ListItemScrollHorizontal>
        {items.map((item, index) => (
          <CategoryItemDetail
            key={item.value}
            item={item}
            onSelect={(ele) => {
              gaEvents.submitEventV2(
                passioPageProfile,
                CATEGORY_CONFIG.BLOCK_TYPE,
                `BlockType_GroupFilter_${handleCapitalizeFirstLetter(
                  item?.value
                )}`,
                handleCapitalizeFirstLetter(item?.value),
                ACTION_CONFIG.GROUP_FILTER
              );
              onChange(item.value);
              ele.scrollIntoView({
                block: "nearest",
                behavior: "smooth",
                inline: "center",
              });
            }}
            listItemsDefault={items}
            selected={selectedGroup === item.value}
          />
        ))}
      </ListItemScrollHorizontal>
    </div>
  );
};

export default Categories;
