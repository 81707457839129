import { useAppSelector } from "@app/hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BLOCK_SETTING_DEFAULT_VALUE,
  BACKGROUND_SETTING_DEFAULT_VALUE,
  TITLE_SETTING_DEFAULT_VALUE,
  PARAGRAPH_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";

export default function Preview({ defaultValue }) {
  const BACKGROUND_TYPE_IMAGE = "image";
  const BACKGROUND_TYPE_SOLID = "solid";
  const BACKGROUND_TYPE_GRADENT = "gradient";
  const BACKGROUND_TYPE_COLOR = "color";
  const { t } = useTranslation();
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { background, paragraph_setting, title_setting } =
    siteSettings?.display_setting || {};
  const {
    font_family: font_family_title = TITLE_SETTING_DEFAULT_VALUE.FONT_FAMILY,
    font_color: font_color_title = TITLE_SETTING_DEFAULT_VALUE.FONT_COLOR,
    scale: scale_title = TITLE_SETTING_DEFAULT_VALUE.FONT_SCALE,
    font_size: font_size_title = TITLE_SETTING_DEFAULT_VALUE.FONT_SIZE,
    font_weight: font_weight_title = TITLE_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
    line_height: line_height_title = TITLE_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  } = title_setting || {};

  const {
    font_family:
      font_family_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY,
    font_color:
      font_color_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_COLOR,
    scale: scale_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SCALE,
    font_size: font_size_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SIZE,
    font_weight:
      font_weight_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
    line_height:
      line_height_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  } = paragraph_setting || {};
  const {
    type = BACKGROUND_SETTING_DEFAULT_VALUE.BACKGROUND_TYPE,
    color = BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_SOLID,
    gradient,
    image = BACKGROUND_SETTING_DEFAULT_VALUE.IMAGE,
    enable_overlay = BACKGROUND_SETTING_DEFAULT_VALUE.OVERLAY_ENABLE,
    overlay_color = BACKGROUND_SETTING_DEFAULT_VALUE.OVERLAY_COLOR,
    blur: blurBackgroundSetting = BACKGROUND_SETTING_DEFAULT_VALUE.BLUR,
  } = background || {};

  const { block_style, block_background, block_border, block_shadow } =
    defaultValue || {};

  const {
    radius_type = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_STYLE,
    radius_value = BLOCK_SETTING_DEFAULT_VALUE.BORDER_RADIUS,
    inner_space = BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE,
    block_space = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE,
    inner_space_number = BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER,
    outside_space = BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE,
  } = block_style || {};
  const {
    color: blockBackgroundColor = BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR,
    blur = BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_BLUR,
    type: type_bg = BACKGROUND_SETTING_DEFAULT_VALUE.BACKGROUND_TYPE,
  } = block_background || {};
  const {
    enable: borderEnable = BLOCK_SETTING_DEFAULT_VALUE.BORDER_ENABLE,
    border_width = BLOCK_SETTING_DEFAULT_VALUE.BORDER_WIDTH,
    color: borderColor = BLOCK_SETTING_DEFAULT_VALUE.BORDER_COLOR,
    type: borderType = BLOCK_SETTING_DEFAULT_VALUE.BORDER_STYLE,
  } = block_border || {};
  const {
    enable: shadowEnable = BLOCK_SETTING_DEFAULT_VALUE.SHADOW_ENABLE,
    color: shadowColor = BLOCK_SETTING_DEFAULT_VALUE.SHADOW_COLOR,
  } = block_shadow || {};

  const font_size_title_block =
    font_size_title * scale_title * scale_title * scale_title;
  const boxShadowStyle =
    shadowEnable === true ? { boxShadow: "0px 4px 4px " + shadowColor } : {};
  const borderRadiusValue = useMemo(() => {
    let value = "0px";
    if (radius_type === "rounded") {
      value = "8px";
    } else if (radius_type === "custom") {
      value = `${radius_value}px`;
    }
    return value;
  }, [radius_type, radius_value]);

  const styleBorder =
    borderEnable === true
      ? {
          borderWidth: border_width + "px",
          borderStyle: borderType,
          borderColor: borderColor,
        }
      : { border: "none" };
  const styleBackgroundImage =
    type === BACKGROUND_TYPE_IMAGE
      ? {
          backgroundPosition: "center center",
          filter: `blur(${blurBackgroundSetting}px) `,
          WebkitFilter: `blur(${blurBackgroundSetting}px) `,
          width: `calc(100% + ${blurBackgroundSetting * 2}px) `,
          height: `calc(100% + ${blurBackgroundSetting * 2}px) `,
          transform: `translate(-${blurBackgroundSetting}px, -${blurBackgroundSetting}px)`,
        }
      : "";
  const checkStyleBackgroundImage = () => {
    switch (type) {
      case BACKGROUND_TYPE_IMAGE:
        return `url("${image}")`;
      case BACKGROUND_TYPE_GRADENT:
        return gradient;
      default:
        return "";
    }
  };
  const innerSpaceNumber =
    typeof inner_space_number === "number"
      ? `${inner_space_number}px`
      : inner_space
      ? `${BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER}px`
      : 0;
  const styleOutsideSpace = outside_space
    ? {
        marginLeft: `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`,
        marginRight: `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`,
        width: `calc(100% - ${
          BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER * 2
        }px) `,
      }
    : "";

  return (
    <>
      <div className="bg-white mb-2">
        {/*<div className="text-[#666666] text-[15px]">*/}
        {/*  {t("ss_builder_preview")}*/}
        {/*</div>*/}
        <div className="mb-3 mt-1 pt-4 pb-4 w-full h-full relative overflow-hidden">
          {type === BACKGROUND_TYPE_IMAGE && enable_overlay === true ? (
            <div
              className="absolute top-0 left-[50%] translate-x-[-50%] block w-full h-full z-[1]"
              style={{ backgroundColor: overlay_color }}
            ></div>
          ) : (
            ""
          )}

          <div
            className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat object-cover"
            style={{
              backgroundColor:
                type === BACKGROUND_TYPE_SOLID || type === BACKGROUND_TYPE_COLOR
                  ? color
                  : "",
              backgroundImage: `${checkStyleBackgroundImage()}`,
              borderBottom: "1px solid var(--adm-color-border)",
              ...styleBackgroundImage,
            }}
          ></div>
          <div
            className="relative z-[3]"
            style={{ ...styleOutsideSpace }}
          >
            <div
              className="pt-3 pb-3"
              style={{
                backgroundColor: blockBackgroundColor,
                borderRadius: borderRadiusValue,
                backdropFilter: `blur(${blur}px) `,
                WebkitBackdropFilter: `blur(${blur}px) `,
                paddingLeft: innerSpaceNumber,
                paddingRight: innerSpaceNumber,
                ...styleBorder,
                ...boxShadowStyle,
              }}
            >
              <h3
                className="text-titleFontSize"
                style={{
                  color: font_color_title,
                  fontSize: font_size_title_block,
                  lineHeight: line_height_title,
                  fontWeight: font_weight_title,
                  fontFamily: font_family_title,
                }}
              >
                {t(`ss_builder_1st_block_preview_sample_title`)}
              </h3>
              <div
                className="content-block-preview"
                style={{
                  color: font_color_paragraph,
                  fontSize: font_size_paragraph,
                  lineHeight: line_height_paragraph,
                  fontWeight: font_weight_paragraph,
                  fontFamily: font_family_paragraph,
                }}
              >
                {t(`ss_builder_1st_block_preview_sample_description`)}
              </div>
            </div>
            <div style={{ height: `${block_space}px` }}></div>
            <div
              className={"pt-3 pb-3"}
              style={{
                backgroundColor: blockBackgroundColor,
                borderRadius: borderRadiusValue,
                backdropFilter: `blur(${blur}px) `,
                WebkitBackdropFilter: `blur(${blur}px) `,
                paddingLeft: innerSpaceNumber,
                paddingRight: innerSpaceNumber,
                ...styleBorder,
                ...boxShadowStyle,
              }}
            >
              <h3
                className="text-titleFontSize"
                style={{
                  color: font_color_title,
                  fontSize: font_size_title_block,
                  lineHeight: line_height_title,
                  fontWeight: font_weight_title,
                  fontFamily: font_family_title,
                }}
              >
                {t(`ss_builder_2st_block_preview_sample_title`)}
              </h3>
              <div
                className="content-block-preview"
                style={{
                  color: font_color_paragraph,
                  fontSize: font_size_paragraph,
                  lineHeight: line_height_paragraph,
                  fontWeight: font_weight_paragraph,
                  fontFamily: font_family_paragraph,
                }}
              >
                {t(`ss_builder_2st_block_preview_sample_description`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
