import { Button } from "antd-mobile";
import React, { useEffect, useMemo, useRef } from "react";

const ProductGroupItemDetail = (props: {
  item: any;
  onSelect: (HTMLSpanElement) => void;
  selected: boolean;
  // listItemsDefault: any;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const { item, onSelect, selected } = props;
  const itemQuantity = useMemo(
    () =>
      item?.content_attributes?.items?.filter((y) => y.enable === 1)?.length ??
      0,
    [item]
  );

  const isHideProductGroup = useMemo(() => !!(item?.enable !== 1), [item]);

  useEffect(() => {
    if (selected)
      ref.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
        inline: "center",
      });
  }, [selected]);

  return (
    <>
      <span ref={ref}>
        <Button
          style={{
            whiteSpace: "nowrap",
            border: "none",
            opacity: isHideProductGroup ? "40%" : "",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(ref.current);
          }}
          block
          shape="rounded"
          className={
            selected
              ? "button-background-color button-text-color"
              : "bg-neutral-100 text-neutral-700"
          }
        >
          <h6
            className={selected ? "button-text-color" : "text-neutral-700"}
          >{`${item?.title} (${itemQuantity})`}</h6>
        </Button>
      </span>
    </>
  );
};

export default ProductGroupItemDetail;
