import React, { useCallback, useMemo } from "react";
import { BIO_DISPLAY_STYLES } from "@share/configs/const";
import BioLayout1 from "../BioLayouts/BioLayout1";
import BioLayout2 from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts/BioLayout2";
import BioLayout3 from "@features/design/components/CommonBlockComponent/BlockBio/BioLayouts/BioLayout3";

const BIO_LAYOUT = {
  [BIO_DISPLAY_STYLES.STYLE1]: BioLayout1,
  [BIO_DISPLAY_STYLES.STYLE2]: BioLayout1,
  [BIO_DISPLAY_STYLES.STYLE3]: BioLayout1,
  [BIO_DISPLAY_STYLES.STYLE4]: BioLayout1,
  [BIO_DISPLAY_STYLES.STYLE5]: BioLayout2,
  [BIO_DISPLAY_STYLES.STYLE6]: BioLayout2,
  [BIO_DISPLAY_STYLES.STYLE7]: BioLayout3,
};

const BioLayouts = (props) => {
  const BioLayout = useCallback(
    (props) => {
      let Element = <></>;
      if (BIO_LAYOUT[props?.style_attributes?.style_display]) {
        const RenderComponent =
          BIO_LAYOUT[props?.style_attributes?.style_display];
        return <RenderComponent {...props} />;
      }
      return Element;
    },
    [props?.style_attributes?.style_display]
  );
  return <BioLayout {...props} />;
};

export default BioLayouts;
