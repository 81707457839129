import { useAppSelector } from "@app/hooks";
import digitalProductApi from "@features/block/block-editor/DigitalProduct/api";
import { Block } from "@features/main/store/interface";
import {
  formatNumberToCurrencyDonation,
  formatUnitSymbol,
  htmlDecode,
} from "@share/lib";
import { Ellipsis } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BlockWrapper from "../../BlockWrapper";
import { Image } from "antd-mobile";
import { useDispatch } from "react-redux";
import { digitalProductAction } from "@features/block/block-editor/DigitalProduct/store/digitalProductSlice";
import { CAT_ID } from "@features/block/block-editor/DigitalProduct/config/type";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockDigitalProduct = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;

  const enable = blockData && blockData.enable;
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const isDeleteItems = useAppSelector((state) => state.digitalProduct.isDeleteItems);
  const siteSettings = useAppSelector((state) => state.siteSettings);

  const dispatch = useDispatch();
  
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const [digitalProductArr, setDigitalProductArr] = useState([]);

  const userId = user?.kol?._id;
  const countryCode = user?.kol?.country;

  const getListProduct = async (dataFetch) => {
    if (blockData.content_attributes?.hasInit && !dataFetch ) {
       setDigitalProductArr([]);
       return;
    }

    let response;
    const params = dataFetch ? { ids: dataFetch.join(',') } : { status: 1 }
    try {
      response = await digitalProductApi.listProduct(params);
    } catch (error) {
      console.log(error);
    }
    if (response && response.success) {
      const sortResponse = dataFetch 
      ? dataFetch.map(id => response.data.find(item => item.id === id)) 
      : response.data;
      
      const productData = sortResponse.filter(Boolean).map((item) => ({
        name: item.name,
        price: item.price,
        image_url: item?.thumbnails[0] ? item?.thumbnails[0].url_small : "",
      }));
      if (isDeleteItems) {
        dispatch(digitalProductAction.setIsDeleteItems(false))
      }
      setDigitalProductArr(productData);
    }
  };

  useEffect(() => {
    const itemData = blockData.content_attributes?.dp_data?.length > 0 ? blockData.content_attributes.dp_data : null
    const itemID = blockData.content_attributes?.dp_items?.length > 0 ? blockData.content_attributes.dp_items : null
    if (itemData) {
      setDigitalProductArr(itemData);
    } else {
      getListProduct(itemID)
    }
  }, [countryCode, userId, blockData, isDeleteItems])


  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      {(digitalProductArr &&
        digitalProductArr.length > 0) ?
        <>
          <div className="grid grid-cols-2 gap-3">
            {
              digitalProductArr.map((ele, index) => (
                <div
                  key={index}
                  style={{
                    boxShadow: "0 3px 6px 0 rgb(54 54 54 / 20%)",
                    borderRadius: "8px",
                  }}
                >
                  <LazyLoadImage
                    src={ele.category?.id === CAT_ID.PHOTO ? ele?.cover_image?.url : ele.thumbnail ? ele.thumbnail : ele.image_url ? ele.image_url : (ele.thumbnails?.length ? ele.thumbnails[0].url : "")}
                    className="object-center rounded-t-lg"
                    style={{
                      backgroundSize: "cover",
                      aspectRatio: "1/1",
                      // margin: "0 auto",
                      objectFit: "cover",
                      width: "100%",
                      maxWidth: "222px",
                    }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src =
                        "https://i.ecomobi.com/ssp/passio-page/image-default.png";
                    }}
                  />
                  <div className="bg-[#FFFFFF] rounded-b-lg">
                    <Ellipsis
                      direction="end"
                      content={htmlDecode(ele.name)}
                      className="text-base font-bold pt-3 px-2 mb-[5px] text-[#333333]"
                      rows={2}
                      style={{
                        lineHeight: "var(--paragraph-line-height)",
                      }}
                    />
                    <div className="px-2 pb-3">
                      {ele.price > 0 ? (
                        <span className="text-red-600 font-medium">{`${formatNumberToCurrencyDonation(
                          ele.price
                        )} ${formatUnitSymbol(countryCode)}`}</span>
                      ) : (
                        <span className="text-green-500 font-medium">
                          {t("ss_builder_digital_product_free_label")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </> : (
          <div
            className="flex flex-col justify-center text-center gap-2 mt-5"
            style={{
              opacity: "60%",
            }}
          >
            <Image
              src="https://image.passio.eco/page-builder/page-builder/images-default/product-empty-image-default.png"
            />
            <div className="flex flex-col">
              <span
                className="lead-2"
                style={{
                  color: "var(--text-color-6)",
                }}
              >
                {t("ss_builder_product_design_empty_title")}
              </span>
              <span
                style={{
                  color: "var(--text-color-5)",
                }}
              >
                {t("ss_builder_product_design_empty_description")}
              </span>
            </div>
          </div>
        )
      }
    </BlockWrapper>
  );
};

export default BlockDigitalProduct;
