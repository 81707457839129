import { CARD_ITEM_ON_ROW, SLIDE_STYLE } from "@share/configs/const";
import { Button, Image } from "antd-mobile";
import React, { useMemo } from "react";
import * as DOMPurify from "dompurify";

type Props = {
  card_title: string;
  card_subtitle: string;
  card_description: string;
  card_image: string;
  card_label: string;
  card_btn_see_more: string;
  card_layout: string;
  card_image_ratio: string;
  card_url: string;
  item_on_row: string;
  card_slide_type: string;
};

const CardDetailLayout2 = (props: Props) => {
  const {
    card_title,
    card_subtitle,
    card_description,
    card_image,
    card_label,
    card_btn_see_more,
    card_layout,
    card_image_ratio,
    card_url,
    item_on_row,
    card_slide_type,
  } = props;

  const isHideCardLabel = useMemo(
    () => !card_label || item_on_row === CARD_ITEM_ON_ROW.TWO,
    [card_label, item_on_row]
  );

  const isHideCardButton = useMemo(
    () => !card_url || item_on_row === CARD_ITEM_ON_ROW.TWO,
    [card_url, item_on_row]
  );

  const isHideCardDescription = useMemo(
    () => !card_description || item_on_row === CARD_ITEM_ON_ROW.TWO,
    [card_description, item_on_row]
  );

  const isDisplayCardText =
    card_title || card_subtitle || !isHideCardDescription;

  // const cleanCardDescription = DOMPurify.sanitize(
  //   card_description?.replaceAll("\n", "</br>")
  // );

  return (
    <div
      className={`card-detail-layout relative ${card_layout}`}
      style={{
        marginBottom:
          card_slide_type === SLIDE_STYLE.NOT_PARTIAL_VISIBLE ? "20px" : "",
      }}
    >
      <Image
        src={card_image}
        style={{
          aspectRatio: card_image_ratio,
          borderRadius: "4px",
        }}
        fit="cover"
        className="card-image"
      />
      <div
        className="absolute top-0 left-0 block w-full h-full text-center"
        style={{
          backgroundColor: "#000000",
          opacity: "0.6",
          borderRadius: "4px",
        }}
      ></div>
      {!isHideCardLabel && (
        <span className="card-label text-neutral-900">{card_label}</span>
      )}
      <div className="absolute bottom-0 left-0 px-4 w-full pb-4">
        {isDisplayCardText && (
          <div className="card-text">
            {card_title && (
              <h4 className="card-title text-neutral-50">{card_title}</h4>
            )}
            {card_subtitle && (
              <div className="lead-2 card-subtitle text-neutral-50">
                {card_subtitle}
              </div>
            )}
            {!isHideCardDescription && (
              <div
                className="card-description text-neutral-50"
                // dangerouslySetInnerHTML={{
                //   __html: cleanCardDescription,
                // }}
              >
                {card_description}
              </div>
            )}
          </div>
        )}

        {!isHideCardButton && (
          <Button
            fill="solid"
            size="middle"
            className="pp-button"
          >
            {card_btn_see_more}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CardDetailLayout2;
