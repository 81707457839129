import cls from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BlockImageGalleryGrid = (props) => {
  const { imageGalleryList, number_items_per_row, ratio } = props;
  return (
    <div
      className={cls(
        `image-gallery-wrapper grid gap-1 grid-cols-${number_items_per_row}`
      )}
    >
      {imageGalleryList &&
        imageGalleryList.map((ele, index) => (
          <div key={index}>
            <LazyLoadImage
              src={ele.image}
              className="object-center image-gallery-image"
              style={{
                backgroundSize: "cover",
                aspectRatio: ratio,
                margin: "0 auto",
                objectFit: "cover",
                width: "100%",
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://i.ecomobi.com/ssp/passio-page/image-default.png";
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default BlockImageGalleryGrid;
