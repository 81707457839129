import {
  MAX_WIDTH_CONTENT_LAYOUT,
  THEME_PREVIEW_BY_IMAGE_KEY,
} from "@share/configs/const";
import { PreviewThemeIcon } from "@share/icons";
import { isDesktop } from "@share/lib";
import { Button, Image } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SimpleBarReact from "simplebar-react";

import "simplebar/src/simplebar.css";

type Props = {
  handleCancelThemeTemplatePreview: any;
  handleConfirmThemeTemplatePreview?: any;
  imageSrc: string;
  KEY: string;
};

const ThemeTemplatePreviewByImage = (props: Props) => {
  const {
    handleCancelThemeTemplatePreview,
    imageSrc,
    KEY,
    handleConfirmThemeTemplatePreview,
  } = props;
  const { t } = useTranslation();

  const buttonSaveLabelRender = useMemo(() => {
    let btnLabel = "";
    switch (KEY) {
      case THEME_PREVIEW_BY_IMAGE_KEY.PREVIEW_AND_APPLY:
        btnLabel = t("ss_builder_apply_preview_theme_template_btn_label");
        break;
      case THEME_PREVIEW_BY_IMAGE_KEY.PREVIEW_AND_REPLACE:
        btnLabel = t("ss_builder_replace_preview_theme_template_btn_label");
        break;
    }
    return btnLabel;
  }, [KEY]);

  return (
    <div className="w-full">
      <div
        className="flex flex-row text-center items-center justify-center py-3 gap-[10px] bg-white sticky top-0"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <PreviewThemeIcon className="w-6 h-6" />
        <div className="text-lg leading-6">
          {t("ss_builder_preview_theme_template_title")}
        </div>
      </div>
      {isDesktop() ? (
        <SimpleBarReact
          forceVisible="y"
          style={{ maxHeight: "calc(100vh - 112px)" }}
        >
          <Image
            src={imageSrc}
            fit="cover"
          />
        </SimpleBarReact>
      ) : (
        <Image
          src={imageSrc}
          fit="cover"
        />
      )}
      {KEY === THEME_PREVIEW_BY_IMAGE_KEY.PREVIEW ? (
        <div className="max-width-content-class px-4 py-3 fixed bottom-0 bg-white w-full">
          <Button
            block
            size="middle"
            color="primary"
            fill="solid"
            onClick={() => handleCancelThemeTemplatePreview()}
          >
            {t("ss_builder_cancel_preview_theme_template_btn_label")}
          </Button>
        </div>
      ) : (
        <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              onClick={handleCancelThemeTemplatePreview}
            >
              {t("ss_builder_cancel_preview_theme_template_btn_label")}
            </Button>
          </div>
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              color={"primary"}
              onClick={() => handleConfirmThemeTemplatePreview()}
            >
              {buttonSaveLabelRender}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThemeTemplatePreviewByImage;
