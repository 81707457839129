import React from "react";

type Props = {
  title?: string;
  subtitle?: string;
  blockType: string;
  titleStyle?: any;
  subtitleStyle?: any;
  blockUid?: string;
};

const BlockHeading = (props: Props) => {
  const { title, blockType, subtitle, titleStyle, subtitleStyle, blockUid } =
    props;
  return (
    <>
      {(title || subtitle) && (
        <div className="block-heading-class" id={blockUid ? `${blockUid}-heading` : ""}>
          {title && (
            <h3
              className={`block-title ${blockType}-title`}
              id={blockUid ? `${blockUid}-title` : ""}
              style={{
                color: "var(--heading-color-6)",
                wordBreak: "break-word",
                ...titleStyle,
              }}
            >
              {title}
            </h3>
          )}
          {subtitle && (
            <span
              className={`block-subtitle ${blockType}-subtitle`}
              id={blockUid ? `${blockUid}-subtitle` : ""}
              style={{
                color: "var(--text-color-4)",
                whiteSpace: "pre-wrap",
                ...subtitleStyle,
              }}
            >
              {subtitle}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default BlockHeading;
