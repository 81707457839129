export const CheckCircleIcon = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0003 2.66699C8.66699 2.66699 2.66699 8.66699 2.66699 16.0003C2.66699 23.3337 8.66699 29.3337 16.0003 29.3337C23.3337 29.3337 29.3337 23.3337 29.3337 16.0003C29.3337 8.66699 23.3337 2.66699 16.0003 2.66699ZM21.6003 13.7337L15.2003 20.1337C14.667 20.667 13.867 20.667 13.3337 20.1337L10.4003 17.2003C9.86699 16.667 9.86699 15.867 10.4003 15.3337C10.9337 14.8003 11.7337 14.8003 12.267 15.3337L14.267 17.3337L19.7337 11.867C20.267 11.3337 21.067 11.3337 21.6003 11.867C22.1337 12.4003 22.1337 13.2003 21.6003 13.7337Z"
        fill="#52C41A"
      />
    </svg>
  );
};
