import IconRender from "@share/IconRender";
import React from "react";

const IconStyle = (props) => {
  const { tab } = props;

  return (
    <div className="min-h-[24px] flex flex-row gap-[6px] text-center items-center justify-center">
      <IconRender iconName={tab.icon} />
    </div>
  );
};

export default IconStyle;
