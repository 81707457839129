import { ArrowRightIcon } from "@share/icons";
import { Button, Image } from "antd-mobile";
import React from "react";

interface Props {
  image: string;
  booking_profile_title: string;
  booking_profile_description: string;
  booking_profile_button_content: string;
}

const CardContentStyle = ({
  image,
  booking_profile_title,
  booking_profile_description,
  booking_profile_button_content,
}: Props) => {
  return (
    <div className="flex flex-col gap-3 justify-center text-center">
      {image && (
        <div className="rounded">
          <Image
            className="w-full rounded"
            src={image}
            fit="cover"
            style={{
              aspectRatio: "16/9",
            }}
          />
        </div>
      )}
      {(booking_profile_title || booking_profile_description) && (
        <div>
          {booking_profile_title && (
            <h3
              style={{
                color: "var(--text-color-6)",
                margin: 0,
              }}
            >
              {booking_profile_title}
            </h3>
          )}
          {booking_profile_description && (
            <p
              style={{
                color: "var(--text-color-6)",
                margin: 0,
              }}
            >
              {booking_profile_description}
            </p>
          )}
        </div>
      )}
      {booking_profile_button_content && (
        <div>
          <Button
            fill="solid"
            size="middle"
            className="pp-button"
          >
            <div className="flex items-center gap-x-2">
              {booking_profile_button_content}
              <ArrowRightIcon
                fillColor="var(--button-text-color)"
                style={{
                  width: "var(--middle-button-font-size)",
                  height: "var(--middle-button-font-size)",
                }}
              />
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default CardContentStyle;
