import React, { useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductDetailStyle1 from "@features/design/components/CommonBlockComponent/BlockAffiliateProductGroup/ProductDetail/ProductDetailStyle1";
import slice from "lodash/slice";
import SeeAll from "./component/SeeAll";
import FullScreenPopup from "@share/components/full-screen-popup";
import { useTranslation } from "react-i18next";
import ListProduct from "@features/design/components/CommonBlockComponent/BlockAffiliateProductGroup/ProductStyleDisplay/ProductStyleSlide/component/ListProduct";
import { StyleAttributesInterface } from "@features/design/components/CommonBlockComponent/BlockAffiliateProductGroup/ProductStyleDisplay";

type Props = {
  styleAttributes: StyleAttributesInterface;
  products: any;
  onUpdateProductGroupData: (data: any) => void;
  productGroupId?: string;
  affiliateProductGroupData?: any;
  productGroupMaximum?: number;
  allProductLists?: any;
};

const ProductStyleSlide = (props: Props) => {
  const {
    products,
    styleAttributes,
    affiliateProductGroupData,
    onUpdateProductGroupData,
    productGroupMaximum,
    allProductLists,
  } = props;

  const { t } = useTranslation();
  const refElement = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const productClone =
    allProductLists?.length > 12
      ? [...slice(products, 0, 12), { showAll: true }]
      : [...products];
  const heightOfElement = useMemo(() => {
    return refElement?.current?.offsetHeight;
  }, [refElement?.current?.offsetHeight]);

  return (
    <>
      <div
        ref={refElement}
        className="max-h-[225px]"
      >
        <Swiper
          slidesPerView={2.5}
          spaceBetween={8}
        >
          {productClone.map((ele, index) => (
            <SwiperSlide key={index}>
              {index === 12 ? (
                <SeeAll
                  height={heightOfElement}
                  handleClickShowAll={() => setVisible(true)}
                />
              ) : (
                <ProductDetailStyle1
                  product={ele}
                  key={index}
                  styleAttributes={styleAttributes}
                  affiliateProductGroupData={affiliateProductGroupData}
                  onUpdateProductGroupData={onUpdateProductGroupData}
                  productGroupMaximum={productGroupMaximum}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <FullScreenPopup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
      >
        <ListProduct
          onCancel={() => setVisible(false)}
          listProduct={products}
          styleAttributes={styleAttributes}
          affiliateProductGroupData={affiliateProductGroupData}
          onUpdateProductGroupData={onUpdateProductGroupData}
          productGroupMaximum={productGroupMaximum}
          allProductLists={allProductLists}
        />
      </FullScreenPopup>
    </>
  );
};

export default ProductStyleSlide;
