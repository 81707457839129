import React, { useRef } from "react";
import { NavBar, Popover } from "antd-mobile";
import {
  BackAngelIcon,
  DeleteIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  LineRename,
  MoreIcon,
} from "@share/icons";
import { Action } from "antd-mobile/es/components/popover";
import { useTranslation } from "react-i18next";
import { GroupActions } from "../../QuickActionsComponent";

const HeaderComponent = (props) => {
  const {
    onCancel,
    title,
    isEnable,
    groupId,
    onRemoveGroup,
    onVisibleGroup,
    onOpenEditGroup,
  } = props;

  const { t } = useTranslation();
  const popoverContainerRef = useRef(null);

  const actions: Action[] = [
    {
      key: GroupActions.EDIT_GROUP_NAME,
      icon: (
        <LineRename
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_edit_product_group_label"),
    },
    {
      key: GroupActions.VISIBLE_HIDE_GROUP,
      icon:
        isEnable === 1 ? (
          <EyeVisibleIcon
            className="fill-white"
            width={20}
            height={20}
          />
        ) : (
          <EyeHiddenIcon
            className="fill-white"
            width={20}
            height={20}
          />
        ),
      text:
        isEnable === 1
          ? t("ss_builder_hide_product_group_label")
          : t("ss_builder_show_product_group_label"),
    },
    {
      key: GroupActions.DELETE_GROUP,
      icon: (
        <DeleteIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_delete_product_group_label"),
    },
  ];

  const handleClickPopoverItem = (item: Action) => {
    const key = item?.key;
    switch (key) {
      case GroupActions.EDIT_GROUP_NAME:
        onOpenEditGroup();
        break;
      case GroupActions.VISIBLE_HIDE_GROUP:
        const newStatus = isEnable === 1 ? 2 : 1;
        onVisibleGroup(newStatus);
        break;
      case GroupActions.DELETE_GROUP:
        onRemoveGroup();
        break;
      default:
    }
  };

  return (
    <div className="sticky top-0 z-[11]">
      <div
        className="relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <NavBar
          backArrow={<BackAngelIcon />}
          onBack={onCancel}
          className="w-full"
        >
          <div className="flex flex-row text-center items-center justify-center gap-[10px] text-[13px]">
            <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[70vw] md:max-w-[20vw] text-titleFontSize">
              {title}
            </div>
            <div className="absolute right-4 flex items-center justify-center gap-[8px]">
              <div
                className="relative"
                ref={popoverContainerRef}
              >
                <Popover.Menu
                  mode="dark"
                  actions={actions}
                  placement="bottom-end"
                  trigger="click"
                  stopPropagation={["click"]}
                  getContainer={() => popoverContainerRef.current}
                  onAction={(item: Action) => handleClickPopoverItem(item)}
                >
                  <MoreIcon className="fill-black cursor-pointer" />
                </Popover.Menu>
              </div>
            </div>
          </div>
        </NavBar>
      </div>
      {isEnable !== 1 && (
        <div
          className="bg-[#FFF3E9] flex justify-center items-center p-2 gap-[10px]"
          style={{
            borderWidth: "1px 0px",
            borderStyle: "solid",
            borderColor: "#FFF3E9",
          }}
        >
          <EyeVisibleIcon
            className="align-middle inline-block fill-black"
            fillColor="#FF6010"
            width={18}
            height={18}
          />
          <div className="text-[#FF6010]">
            {t("ss_builder_group_is_hiding_label")}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;
