import { AppState } from "@app/store";
import { BLOCK_TYPE } from "@features/block/block.conf";
import {
  POSITION_TYPE,
  SelectionStateEnum,
} from "@features/block/config/interface";
import { BLOCK_FILTER_GROUP_TYPE, FINISH_ONBOARDING } from "@share/configs/const";
import {
  AffiliateIconV2,
  BookingProfileBlockIcon,
  DigitalProducticonV2,
  GiftIconV2,
  IconBrandGift,
  IconBrandRating,
  IconDonation,
  IconVideoShoutout,
  TruthOrDareIcon,
} from "@share/icons/block-icons";
import React from "react";
import { BlockGroupInterface } from "../block-selection";
import { SelectionState } from "../interface";
import {
  COUNT_OF_FLOATING_BLOCK_MAXIMUM,
  BLOCK_TYPE_BY_GROUP,
} from "@features/design/design.config";


export const REVENUE_GROUP: BlockGroupInterface = {
  name_key: "ss_builder_revenue_title",
  description_key: "ss_builder_revenue_group_description",
  slug: BLOCK_FILTER_GROUP_TYPE.MONETIZATION,
  block_list: [
    {
      name_key: "ss_builder_title_block_affiliate_product",
      block_type: BLOCK_TYPE.AFF_PRODUCT_GROUP,
      description_key: "ss_builder_block_affiliate_description",
      Icon: (width, height) => (
        <AffiliateIconV2
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: "ss_builder_title_brand_gift_footer",
      block_type: BLOCK_TYPE.BRAND_GIFT_FOOTER,
      description_key: "ss_brand_gift_setting_float_icon_footer_description",
      Icon: (width, height) => (
        <IconBrandGift
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
      defaultState: SelectionState.DISABLE,
      onLoad: (
        setDisplayState: (state: number) => void,
        setWarningKey: (key: string) => void,
        positionSlug: string,
        tabUid: string,
        state: AppState
      ) => {
        const { positions } = state;
        const allowStore = window["AllowStore"];
        if (!allowStore?.allow_view_brand_gift) {
          setDisplayState(SelectionState.HIDDEN);
          return;
        }
        const expand = positions.find(
          (position) => position.slug == POSITION_TYPE.EXPAND
        );
        const includeInExpand =
          expand &&
          expand.block_list.find(
            (block) => block.block_type == BLOCK_TYPE.BRAND_GIFT_FOOTER
          );

        const isFloatingFull =
          expand.block_list.filter((e) =>
            BLOCK_TYPE_BY_GROUP.FLOATING.includes(e.block_type)
          ).length >= COUNT_OF_FLOATING_BLOCK_MAXIMUM;
        if (isFloatingFull) {
          setWarningKey("ss_builder_floating_full");
        } else if (includeInExpand) {
          setWarningKey("ss_builder_gift_warning");
        } else {
          setDisplayState(SelectionStateEnum.ENABLE);
        }
      },
    },
    {
      name_key: "ss_builder_title_block_brand_gift",
      block_type: BLOCK_TYPE.BRAND_GIFT,
      description_key: "ss_builder_title_block_brand_gift_description",
      Icon: (width, height) => (
        <IconBrandGift
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
      defaultState: SelectionState.HIDDEN,
      onLoad: (
        setDisplayState: (state: number) => void,
        setWarningKey: (key: string) => void,
        positionSlug: string,
        tabUid: string,
        state: AppState
      ) => {
        const allowStore = window["AllowStore"];
        if (allowStore?.allow_view_brand_gift) {
          setDisplayState(SelectionState.ENABLE);
          return;
        }
      },
    },
    {
      name_key: "ss_builder_title_block_virtual_gift",
      block_type: BLOCK_TYPE.GIFT,
      description_key: "ss_builder_block_virtual_gift_description",
      Icon: (width, height) => (
        <GiftIconV2
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
      defaultState: SelectionState.DISABLE,
      onLoad: (
        setDisplayState: (state: number) => void,
        setWarningKey: (key: string) => void,
        positionSlug: string,
        tabUid: string,
        state: AppState
      ) => {
        const { positions } = state;
        const allowStore = window["AllowStore"];
        if (!allowStore?.allow_view_virtual_gift) {
          setDisplayState(SelectionState.HIDDEN);
          return;
        }
        if (positionSlug !== "body") {
          // Disable in body
          return;
        }
        // setWarningKey("Hoàn thiện tài khoản để sử dụng block này");
        const header = positions.find((position) => position.slug == "header");
        const includeInHeader =
          header &&
          header.block_list.find(
            (block) => block.block_type == BLOCK_TYPE.GIFT
          );
        const footer = positions.find((position) => position.slug == "footer");
        const includeInFooter =
          footer &&
          footer.block_list.find(
            (block) => block.block_type == BLOCK_TYPE.GIFT
          );
        if (!includeInHeader && !includeInFooter) {
          setDisplayState(SelectionState.ENABLE);
        }
      },
    },
    {
      name_key: "ss_builder_title_block_virtual_gift_footer",
      block_type: BLOCK_TYPE.GIFT_FOOTER,
      description_key: "ss_builder_block_virtual_gift_footer_description",
      Icon: (width, height) => (
        <GiftIconV2
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
      defaultState: SelectionStateEnum.DISABLE,
      onLoad: (
        setDisplayState: (state: number) => void,
        setWarningKey: (key: string) => void,
        positionSlug: string,
        tabUid: string,
        state: AppState
      ) => {
        const { positions } = state;
        const allowStore = window["AllowStore"];
        if (!allowStore?.allow_view_virtual_gift) {
          setDisplayState(SelectionState.HIDDEN);
          return;
        }
        if (positionSlug === "body") {
          return;
        }
        const expand = positions.find((position) => position.slug == "expand");
        const includeInExpand =
          expand &&
          expand.block_list.find(
            (block) => block.block_type == BLOCK_TYPE.GIFT_FOOTER
          );

        const isFloatingFull =
          expand.block_list.filter((e) =>
            BLOCK_TYPE_BY_GROUP.FLOATING.includes(e.block_type)
          ).length >= COUNT_OF_FLOATING_BLOCK_MAXIMUM;

        if (isFloatingFull) {
          setWarningKey("ss_builder_floating_full");
        } else if (includeInExpand) {
          setWarningKey("ss_builder_gift_warning");
        } else {
          setDisplayState(SelectionStateEnum.ENABLE);
        }
      },
    },
    // {
    //   name_key: "ss_builder_title_block_truth_or_dare",
    //   block_type: BLOCK_TYPE.TRUTH_OR_DARE,
    //   description_key: "ss_builder_block_truth_or_dare_description",
    //   Icon: (width, height) => (
    //     <TruthOrDareIcon
    //       className="align-middle rounded-lg inline-block"
    //       width={width}
    //       height={height}
    //     />
    //   ),
    //   defaultState: SelectionState.HIDDEN,
    //   onLoad: (
    //     setDisplayState: (state: number) => void,
    //     setWarningKey: (key: string) => void,
    //     positionSlug: string,
    //     tabUid: string,
    //     state: AppState
    //   ) => {
    //     const allowStore = window["AllowStore"];
    //     if (allowStore?.allow_view_tod) {
    //       setDisplayState(SelectionState.ENABLE);
    //       return;
    //     }
    //   },
    // },
    // {
    //   name_key: "ss_builder_title_block_truth_or_dare_footer",
    //   block_type: BLOCK_TYPE.TRUTH_OR_DARE_FOOTER,
    //   description_key: "ss_builder_block_truth_or_dare_footer_description",
    //   Icon: (width, height) => (
    //     <TruthOrDareIcon
    //       className="align-middle inline-block rounded-lg"
    //       width={width}
    //       height={height}
    //     />
    //   ),
    //   defaultState: SelectionStateEnum.DISABLE,
    //   onLoad: (
    //     setDisplayState: (state: number) => void,
    //     setWarningKey: (key: string) => void,
    //     positionSlug: string,
    //     tabUid: string,
    //     state: AppState
    //   ) => {
    //     const { positions } = state;
    //     const expand = positions.find(
    //       (position) => position.slug == POSITION_TYPE.EXPAND
    //     );
    //     const includeInExpand =
    //       expand &&
    //       expand.block_list.find(
    //         (block) => block.block_type == BLOCK_TYPE.TRUTH_OR_DARE_FOOTER
    //       );

    //     const isFloatingFull =
    //       expand.block_list.filter((e) =>
    //         BLOCK_TYPE_BY_GROUP.FLOATING.includes(e.block_type)
    //       ).length >= COUNT_OF_FLOATING_BLOCK_MAXIMUM;
    //     if (isFloatingFull) {
    //       setWarningKey("ss_builder_floating_full");
    //     } else if (includeInExpand) {
    //       setWarningKey("ss_builder_truth_or_dare_warning");
    //     } else {
    //       setDisplayState(SelectionStateEnum.ENABLE);
    //     }
    //   },
    // },
    {
      name_key: "ss_builder_title_block_donation",
      block_type: BLOCK_TYPE.DONATION,
      description_key: "ss_builder_block_donation_description",
      Icon: (width, height) => (
        <IconDonation
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
      defaultState: SelectionState.HIDDEN,
      onLoad: (
        setDisplayState: (state: number) => void,
        setWarningKey: (key: string) => void,
        positionSlug: string,
        tabUid: string,
        state: AppState
      ) => {
        const allowStore = window["AllowStore"];
        if (allowStore?.allow_view_donation) {
          setDisplayState(SelectionState.ENABLE);
          return;
        }
      },
    },
    {
      name_key: "ss_builder_title_block_digital_product",
      block_type: BLOCK_TYPE.DIGITAL_PRODUCT,
      description_key: "ss_builder_block_digital_product_description",
      Icon: (width, height) => (
        <DigitalProducticonV2
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
    },
    // {
    //   name_key: "ss_builder_title_block_video_shoutout",
    //   block_type: BLOCK_TYPE.VIDEO_SHOUTOUT,
    //   description_key: "ss_builder_title_block_video_shoutout_description",
    //   Icon: (width, height) => (
    //     <IconVideoShoutout
    //       className="align-middle inline-block rounded-lg"
    //       width={width}
    //       height={height}
    //     />
    //   ),
    // },
    {
      name_key: "ss_builder_title_block_brand_rating",
      block_type: BLOCK_TYPE.BRAND_RATING,
      description_key: "ss_builder_title_block_brand_rating_description",
      Icon: () => (
        <IconBrandRating className="align-middle inline-block w-12 h-12 rounded-lg" />
      ),
      defaultState: SelectionStateEnum.HIDDEN,
      onLoad: (
        setDisplayState: (state: number) => void,
        setWarningKey: (key: string) => void,
        positionSlug: string,
        tabUid: string,
        state: AppState
      ) => {
        const allowStore = window["AllowStore"];
        if (allowStore?.allow_view_brand_rating) {
          setDisplayState(SelectionState.ENABLE);
          return;
        }
      },
    },
    {
      name_key: "ss_builder_title_block_booking_profile",
      block_type: BLOCK_TYPE.BOOKING_PROFILE,
      description_key: "ss_builder_title_block_booking_profile_description",
      defaultState: SelectionState.ENABLE,
      onLoad: (
        setDisplayState: (state: number) => void,
        setWarningKey: (key: string) => void,
        positionSlug: string,
        tabUid: string,
        state: AppState
      ) => {
        if (state?.bookingProfile?.finish_onboard !== FINISH_ONBOARDING) {
          setDisplayState(SelectionState.DISABLE);
          setWarningKey("ss_builder_title_block_booking_profile_warning");
        }
      },
      Icon: (width, height) => (
        <BookingProfileBlockIcon
          className="align-middle inline-block rounded-lg"
          width={width}
          height={height}
        />
      ),
    },
  ],
};
