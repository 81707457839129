import { useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import React from "react";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";
import * as DOMPurify from "dompurify";
import { useMemo } from "react";
import {
  extractAttrFromString,
  checkDirtyCodeIframe,
} from "@features/share/helper/validator";

const URL_PARAMS_KEY = "{url_params}";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockEmbed = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const transformEmbedContent = (embedString: string) => {
    const listAttrs = extractAttrFromString(embedString);
    const titleAttr = listAttrs?.find((e) => e[0] === "title");

    const titleValueClean =
      titleAttr &&
      titleAttr.length > 1 &&
      titleAttr[1]
        .replaceAll(">", "&gt;")
        .replaceAll("<", "&lt;")
        .replaceAll('"', "&quot;")
        .replaceAll("'", "&#39;");

    const newEmbedString = embedString.replace(
      /(?:title=(.+?)"+)/,
      `title="${titleValueClean}"`
    );
    return newEmbedString;
  };
  const cleanEmbedCode = useMemo(() => {
    let embed = transformEmbedContent(
      blockData?.content_attributes?.embed_code
    );
    if (checkDirtyCodeIframe(embed)) {
      if (embed?.includes(URL_PARAMS_KEY)) {
        embed = embed.replaceAll(URL_PARAMS_KEY, "");
      }
      return embed;
    }
    return "";
  }, [blockData]);

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <BlockHeading
        blockType={blockData.block_type}
        title={blockData?.content_attributes?.block_heading}
        subtitle={blockData?.content_attributes?.block_description}
      />
      <div
        className="relative w-full h-full"
        dangerouslySetInnerHTML={{
          __html: cleanEmbedCode,
        }}
      ></div>
      <div
        className="absolute top-0 left-0 block w-full h-full text-center block-border-radius"
        style={{
          backgroundColor: "transparent",
        }}
      ></div>
    </BlockWrapper>
  );
};

export default BlockEmbed;
