import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { openPopup } from "@features/block/store/edittingBlockSlice";
import {
  IntroduceDoubleTapEnum,
  setIntroduceDoubleTapState,
} from "@features/design/store/externalStateSlice";
import { setParamsTab } from "@features/main/store/dynamicSlice";
import {
  disableEnableBlock,
  sortBlock,
} from "@features/main/store/positionsSlice";
import {
  ACTION_BLOCK,
  DISABLE_BLOCK,
  ENABLE_BLOCK,
  POSITION_STICKY,
  TAB_STICKY_CLASS,
} from "@features/share/configs/const";
import {
  BLOCK_TAB_MENU_DESIGN_CLASS,
  KEY_BLOCK,
  TAB_DISPLAY,
  TAB_GHIM_STYLE,
  TAB_LAYOUT,
  TAB_POSITION,
  TAB_SETTING,
  TAB_TEMPLATE,
  TAB_WIDTH_DISPLAY,
} from "@share/configs/const";
import Portal from "@share/HOC/portal";
import {
  ArrowDown,
  ArrowUp,
  CheckCircleIcon,
  EditTabIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  MoreIcon,
  PenIcon,
} from "@share/icons";
import { handleCapitalizeFirstLetter } from "@share/lib";
import gaEvents, {
  ACTION_CONFIG,
  BLOCK_TYPE_EVENT_CONFIG,
  CATEGORY_CONFIG,
} from "@share/lib/ga-events";
import { Checkbox, Modal, Popover, Toast } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDoubleTap } from "use-double-tap";
import { PRODUCT_TOUR_CLASS_NAME } from "../../ProductTour/product-tour-config";
import TabWidthStyleDisplay from "./TabWidthStyle";
import { useSelector } from "react-redux";
import { selectProfile } from "@features/user/userSlice";

type Props = {
  blockData: any;
  positionSlug: string;
  tabUid?: string;
  blockUid?: string;
  isEnable?: number;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
};

const BlockTabs = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
  } = props;

  const enable = blockData && blockData.enable;
  const blockUid = blockData && blockData.uid;
  const isEnable = enable;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};
  const introduceDoubleTap = useAppSelector(
    (state) => state?.externalState?.introduce_double_tap
  );

  const popoverRef = useRef(null);
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const tabMenuHeightRef = useRef(null);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const tabListBody = useMemo(
    () =>
      blockData &&
      blockData.content_attributes &&
      blockData.content_attributes.tabListBody,
    [blockData]
  );

  const style_attributes = {
    ...blockData.style_attributes,
    display_style:
      blockData?.style_attributes?.display_style ?? TAB_DISPLAY.TEXT_AND_ICON,
    ghim_style:
      blockData?.style_attributes?.ghim_style ?? TAB_GHIM_STYLE.NOT_GHIM,
    tab_layout:
      blockData?.style_attributes?.tab_layout ?? TAB_LAYOUT.TEXT_RIGHT,
    tab_width_display:
      blockData?.style_attributes?.tab_width_display ?? TAB_WIDTH_DISPLAY.AUTO,
  };

  const display_style = style_attributes && style_attributes.display_style;
  const ghim_style = style_attributes && style_attributes.ghim_style;
  const position_style = style_attributes && style_attributes.position_style;
  const tab_template = style_attributes && style_attributes.tab_template;
  const tab_shadow_enable =
    (style_attributes && style_attributes.tab_shadow_enable) ||
    TAB_SETTING.SHADOW_ENABLE;
  const tab_shadow_color =
    (style_attributes && style_attributes.tab_shadow_color) ||
    TAB_SETTING.SHADOW_COLOR;

  const activeKeyTabState =
    dynamicConfig && dynamicConfig.tabs && dynamicConfig.tabs.activeKeyTab;

  const positionTabMenuState =
    dynamicConfig && dynamicConfig.tabs && dynamicConfig.tabs.positionTabMenu;

  const activeKey = useMemo(() => {
    const isExistActiveTab = tabListBody?.find(
      (x) => x?.uid === activeKeyTabState
    );
    if (activeKeyTabState && isExistActiveTab) {
      return activeKeyTabState;
    } else {
      const tabDefault =
        tabListBody && tabListBody.find((x) => x.isDefault === true);
      const activeKeyDefault = tabDefault
        ? tabDefault?.uid
        : tabListBody[0]?.uid;
      return activeKeyDefault;
    }
  }, [activeKeyTabState, tabListBody]);

  const [tabActive, setTabActive] = useState(activeKey);
  const [checkTabMenuPosition, setCheckTabMenuPosition] =
    useState(positionTabMenuState);
  const [tabBarHeaderHeight, setTabBarHeaderHeight] = useState(null);
  const [tabBarFooterHeight, setTabBarFooterHeight] = useState(null);

  useEffect(() => {
    if (activeKey) setTabActive(activeKey);
  }, [activeKey]);

  useEffect(() => {
    if (ghim_style === "auto-ghim") {
      const mobileElement = document.querySelector(
        ".mobile-layout"
      ) as HTMLElement;
      mobileElement.addEventListener("scroll", handleStickyTabMenu);
      return () => {
        mobileElement.removeEventListener("scroll", handleStickyTabMenu);
      };
    }
  }, [ghim_style]);

  const handleStickyTabMenu = useCallback(
    (e) => {
      const mobileElement = document.querySelector(
        ".mobile-layout"
      ) as HTMLElement;
      const tabMenuStickyElement = document.querySelector(
        `.${TAB_STICKY_CLASS}`
      ) as HTMLElement;
      const footerDesignElement = document.querySelector(
        ".footer-design"
      ) as HTMLElement;
      const mobileElementScrollTop = mobileElement.scrollTop;
      const distanceFooterWithTopScreen =
        footerDesignElement && footerDesignElement.getBoundingClientRect().top;
      const distanceTabMenuWithTopScreen =
        tabMenuStickyElement &&
        tabMenuStickyElement.getBoundingClientRect().top;
      if (distanceFooterWithTopScreen <= 57) {
        setCheckTabMenuPosition("hidden");
      } else if (mobileElementScrollTop <= tabMenuHeightRef.current) {
        setCheckTabMenuPosition("");
      } else if (distanceTabMenuWithTopScreen <= 57) {
        if (!tabMenuHeightRef.current) {
          tabMenuHeightRef.current = mobileElementScrollTop;
        }
        setCheckTabMenuPosition(POSITION_STICKY);
      }
    },
    [tabMenuHeightRef.current, positionTabMenuState]
  );

  useEffect(() => {
    if (
      tabActive &&
      ghim_style === "auto-ghim" &&
      checkTabMenuPosition === POSITION_STICKY
    ) {
      const bodyDesignElement = document.querySelector(
        ".body-design"
      ) as HTMLElement;
      const mobileElement = document.querySelector(
        ".mobile-layout"
      ) as HTMLElement;
      const bodyTop = bodyDesignElement.offsetTop;
      mobileElement.scrollTop = bodyTop - 104;
    }
  }, [tabActive, ghim_style]);

  useEffect(() => {
    let topTimeOut = null;
    const loadTopHeight = () => {
      const tabBarHeaderElement = document.querySelector(
        ".tab-bar-design-header"
      ) as HTMLElement;
      if (tabBarHeaderElement) {
        setTabBarHeaderHeight(tabBarHeaderElement.offsetHeight);
      } else {
        topTimeOut = setTimeout(loadTopHeight, 300);
      }
    };

    let bottomTimeOut = null;

    const loadBottomHeight = () => {
      const tabBarFooterElement = document.querySelector(
        ".tab-bar-footer"
      ) as HTMLElement;
      if (tabBarFooterElement) {
        setTabBarFooterHeight(tabBarFooterElement.offsetHeight);
      } else {
        bottomTimeOut = setTimeout(loadBottomHeight, 300);
      }
    };
    loadTopHeight();
    loadBottomHeight();
    return () => {
      if (topTimeOut) {
        clearTimeout(topTimeOut);
      }
      if (bottomTimeOut) {
        clearTimeout(bottomTimeOut);
      }
    };
  }, []);

  const handleChangeTab = (key) => {
    setTabActive(key);
    dispatch(
      setParamsTab({
        data: {
          activeKeyTab: key,
        },
      })
    );
  };

  const handleClickEditTab = (event?: any) => {
    if (event?.defaultPrevented) {
      return;
    }
    dispatch(
      openPopup({
        block: blockData,
        position_uid: positionSlug,
        tab_uid: tabUid,
        KEY: KEY_BLOCK.EDIT,
      })
    );
  };

  const actions: Action[] = [
    {
      key: ACTION_BLOCK.VISIBLE_BLOCK,
      icon:
        isEnable === ENABLE_BLOCK ? (
          <EyeVisibleIcon
            className=""
            width={20}
            height={20}
          />
        ) : (
          <EyeHiddenIcon
            className=""
            width={20}
            height={20}
          />
        ),
      text:
        isEnable === ENABLE_BLOCK
          ? t("ss_builder_hide_block_label")
          : t("ss_builder_show_block_label"),
    },
    {
      key: ACTION_BLOCK.MOVE_UP_BLOCK,
      icon: (
        <ArrowUp
          className=""
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_move_up_block_label"),
    },
    {
      key: ACTION_BLOCK.MOVE_DOWN_BLOCK,
      icon: (
        <ArrowDown
          className=""
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_move_down_block_label"),
    },
    {
      key: ACTION_BLOCK.EDIT_BLOCK,
      icon: (
        <EditTabIcon
          className=""
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_edit_block_label"),
    },
  ];
  const onMoveBlock = (action) => {
    const startIndex = index;
    const endIndex = action === "down" ? startIndex + 1 : startIndex - 1;
    if (endIndex < 0 || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    dispatch(sortBlock({ itemsOrder, positionSlug, tabUid }));
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(listBlocksSortOrder);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedBlockList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedBlockList;
  };

  const onDisableEnableBlock = (
    positionSlug: string,
    blockUid: string,
    blockStatus: number
  ) => {
    dispatch(
      disableEnableBlock({
        positionSlug,
        blockUid,
        blockStatus,
        tabUid,
      })
    );
  };

  const handleEnableDisableBlock = () => {
    const blockStatus =
      isEnable === ENABLE_BLOCK ? DISABLE_BLOCK : ENABLE_BLOCK;
    onDisableEnableBlock(positionSlug, blockUid, blockStatus);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {isEnable === ENABLE_BLOCK
            ? t("ss_builder_disable_block_successfully_label")
            : t("ss_builder_enable_block_successfully_label")}
        </div>
      ),
    });
  };
  const blockTypeEvent =
    BLOCK_TYPE_EVENT_CONFIG[BLOCK_TYPE.TAB_BAR?.toUpperCase()] ||
    handleCapitalizeFirstLetter(BLOCK_TYPE.TAB_BAR);
  const handleUpdateStatusIntroduceDoubleTap = () => {
    dispatch(setIntroduceDoubleTapState(IntroduceDoubleTapEnum.FALSE));
  };
  const openModalIntroduceDoubleTap = () => {
    let isDontShowAgain = false;
    Modal.alert({
      image:
        "https://image.passio.eco/page-builder/page-builder/position-modal/introduce-edit-block.svg",
      title: t("ss_builder_introduce_double_tap_title"),
      className: "modal-product-tour modal-default",
      content: (
        <div>
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_introduce_double_tap_content")}
          </div>
          <Checkbox
            block
            onChange={(val) => (isDontShowAgain = val)}
            className="mt-5 radio_introduce"
          >
            {t("ss_builder_introduce_double_tap_label_check")}
          </Checkbox>
        </div>
      ),
      confirmText: (
        <span className="flex items-center w-full justify-center gap-2 leading-[140%] text-[17px]">
          <PenIcon
            width={17}
            height={17}
            fillColor="#fff"
          />{" "}
          {t("ss_builder_introduce_double_tap_button")}
        </span>
      ),
      onConfirm: () => {
        if (isDontShowAgain) {
          handleUpdateStatusIntroduceDoubleTap();
        }
        handleClickEditTab();
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.BLOCK,
          `Block_GoEditPopover_${blockTypeEvent}`,
          blockTypeEvent,
          ACTION_CONFIG.START_EDITING_POPOVER
        );
        return;
      },
    });
  };

  const handleClickPopoverItem = (item: Action) => {
    const key = item?.key;
    switch (key) {
      case ACTION_BLOCK.VISIBLE_BLOCK:
        handleEnableDisableBlock();
        break;
      case ACTION_BLOCK.MOVE_UP_BLOCK:
        onMoveBlock("up");
        break;
      case ACTION_BLOCK.MOVE_DOWN_BLOCK:
        onMoveBlock("down");
        break;
      case ACTION_BLOCK.EDIT_BLOCK:
        if (introduceDoubleTap == IntroduceDoubleTapEnum.TRUE) {
          openModalIntroduceDoubleTap();
        } else {
          handleClickEditTab();
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.BLOCK,
            `Block_GoEditPopover_${blockTypeEvent}`,
            blockTypeEvent,
            ACTION_CONFIG.START_EDITING_POPOVER
          );
        }
        break;
      default:
        if (introduceDoubleTap == IntroduceDoubleTapEnum.TRUE) {
          openModalIntroduceDoubleTap();
        } else {
          handleClickEditTab();
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.BLOCK,
            `Block_GoEditPopover_${blockTypeEvent}`,
            blockTypeEvent,
            ACTION_CONFIG.START_EDITING_POPOVER
          );
        }
    }
  };
  let actionMoreElement = actions;
  const [forceVisible, setForceVisible] = useState(false);
  if (
    ghim_style === TAB_GHIM_STYLE.ALWAYS_GHIM ||
    listBlocksSortOrder.length === 1
  ) {
    actionMoreElement = actions.filter(
      (actions) =>
        actions.key !== ACTION_BLOCK.MOVE_DOWN_BLOCK &&
        actions.key !== ACTION_BLOCK.MOVE_UP_BLOCK
    );
  } else if (index === listBlocksSortOrder.length - 1) {
    actionMoreElement = actions.filter(
      (actions) => actions.key !== ACTION_BLOCK.MOVE_DOWN_BLOCK
    );
  } else if (index === 0) {
    actionMoreElement = actions.filter(
      (actions) => actions.key !== ACTION_BLOCK.MOVE_UP_BLOCK
    );
  }
  useEffect(() => {
    const castWindow = window as any;
    if (isFirstItem) {
      castWindow.triggerVisiblePopoverBlock = setForceVisible;
    }
    () => {
      if (castWindow.triggerVisiblePopoverBlock) {
        castWindow.triggerVisiblePopoverBlock = undefined;
        setForceVisible(false);
      }
    };
  }, [isFirstItem]);
  const checkGhimStyle = useMemo(() => {
    return (
      ghim_style === TAB_GHIM_STYLE.ALWAYS_GHIM ||
      (ghim_style === TAB_GHIM_STYLE.AUTO_GHIM &&
        checkTabMenuPosition !== POSITION_STICKY)
    );
  }, [ghim_style, checkTabMenuPosition, position_style]);
  const tabContentElementRender = useMemo(() => {
    return (
      <>
        <div
          className={cls(
            TAB_STICKY_CLASS,
            PRODUCT_TOUR_CLASS_NAME.STEP_5,
            isFirstItem
              ? `${PRODUCT_TOUR_CLASS_NAME.STEP_12} ${PRODUCT_TOUR_CLASS_NAME.STEP_13}`
              : "",
            tab_template === TAB_TEMPLATE.TEMPLATE_2 ? "py-2" : "",
            checkGhimStyle ? "" : "block-border-radius"
          )}
          style={{
            borderBottom:
              position_style === TAB_POSITION.TOP && checkGhimStyle
                ? `1px solid rgba(132, 132, 132, 0.3)`
                : "none",
            borderTop:
              position_style === TAB_POSITION.BOTTOM && checkGhimStyle
                ? `1px solid rgba(132, 132, 132, 0.3)`
                : "none",
            paddingLeft: `${inner_space_number}px`,
            paddingRight: `${inner_space_number}px`,
          }}
        >
          <TabWidthStyleDisplay
            style_attributes={style_attributes}
            tabListBody={tabListBody}
            onUpdateTabActive={handleChangeTab}
            tabActiveDefault={tabActive}
            onDoubleTab={handleClickEditTab}
          />
        </div>
        {enable !== 1 && (
          <div
            className={cls(
              "absolute top-0 left-0 block w-full h-full text-center",
              ghim_style === "not-ghim" ? "rounded-lg" : ""
            )}
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <EyeVisibleIcon
                className="align-middle inline-block"
                fillColor="#333"
              />
              <div className="text-[#333]">
                {t("ss_builder_block_design_hiding_label")}
              </div>
            </div>
          </div>
        )}
        <div
          className="flex flex-row items-center justify-center absolute right-1 top-1
      w-[32px] h-[32px] gap-[15px] cursor-pointer"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            borderRadius: "100px",
            border: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <div
            className="relative"
            ref={popoverRef}
          >
            <Popover.Menu
              {...(forceVisible ? { visible: true } : {})}
              className={isFirstItem ? PRODUCT_TOUR_CLASS_NAME.STEP_3 : ""}
              mode="dark"
              actions={actionMoreElement}
              placement={
                position_style === TAB_POSITION.BOTTOM
                  ? "top-end"
                  : "bottom-end"
              }
              trigger="click"
              stopPropagation={["click"]}
              getContainer={() => document.querySelector(".design-class")}
              onAction={(item: Action) => handleClickPopoverItem(item)}
            >
              <MoreIcon
                fillColor="#fff"
                className=""
              />
            </Popover.Menu>
          </div>
        </div>
      </>
    );
  }, [
    ghim_style,
    tabListBody,
    enable,
    style_attributes,
    checkGhimStyle,
    tabActive,
  ]);

  const tabContentElementRenderWithStyle = useMemo(() => {
    let tabElement = null;
    switch (ghim_style) {
      case TAB_GHIM_STYLE.NOT_GHIM:
        tabElement = tabContentElementRender;
        break;
      case TAB_GHIM_STYLE.ALWAYS_GHIM:
        const style =
          position_style === TAB_POSITION.TOP
            ? { top: `${tabBarHeaderHeight}px` }
            : { bottom: `${tabBarFooterHeight}px` };

        tabElement = (
          <Portal elementId="tabMenuDesignPortal">
            <div
              id={BLOCK_TAB_MENU_DESIGN_CLASS}
              className="max-width-content-class tab-design-always-ghim fixed z-[5] w-full left-[50%] translate-x-[-50%]"
              style={style}
            >
              <div
                className={cls(
                  "relative block-tab-background-color",
                  PRODUCT_TOUR_CLASS_NAME.STEP_5
                )}
              >
                {tabContentElementRender}
              </div>
            </div>
          </Portal>
        );
        break;
      case "auto-ghim":
        if (checkTabMenuPosition === POSITION_STICKY) {
          tabElement = (
            <Portal elementId="tabMenuDesignPortal">
              <div className="max-width-content-class tab-design-auto-ghim fixed z-[2] w-full top-[57px] left-[50%] translate-x-[-50%]">
                <div className="relative block-tab-background-color">
                  {tabContentElementRender}
                </div>
              </div>
            </Portal>
          );
        } else if (checkTabMenuPosition === "hidden") {
          tabElement = null;
        } else tabElement = tabContentElementRender;
        break;
    }
    return tabElement;
  }, [
    ghim_style,
    position_style,
    checkTabMenuPosition,
    tabContentElementRender,
    tabBarHeaderHeight,
    tabBarFooterHeight,
  ]);

  const productTourTabClassSticky = checkGhimStyle
    ? ""
    : PRODUCT_TOUR_CLASS_NAME.STEP_5;

  const bindDoubleTapToBlock = useDoubleTap((event) => {
    handleClickEditTab(event);
  });

  return (
    <div
      {...bindDoubleTapToBlock}
      className={cls(
        `block-tabs-class block-border-radius block-border-width block-border-style block-border-color block-custom-style block-background-color relative`,
        checkTabMenuPosition === POSITION_STICKY ? TAB_STICKY_CLASS : "",
        productTourTabClassSticky
      )}
      style={{
        boxShadow:
          tab_shadow_enable && ghim_style !== TAB_GHIM_STYLE.ALWAYS_GHIM
            ? `0 4px 4px ${tab_shadow_color}`
            : "none",
        marginLeft:
          ghim_style === TAB_GHIM_STYLE.NOT_GHIM && outside_space
            ? "12px"
            : "0px",
        marginRight:
          ghim_style === TAB_GHIM_STYLE.NOT_GHIM && outside_space
            ? "12px"
            : "0px",
        backdropFilter: checkGhimStyle
          ? "none"
          : "blur(var(--block-background-blur))",
      }}
    >
      {tabListBody &&
        Array.isArray(tabListBody) &&
        tabListBody.length > 0 &&
        tabContentElementRenderWithStyle}
    </div>
  );
};

export default BlockTabs;
