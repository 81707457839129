import { CheckCircleIcon } from "@features/mini-store/share/icons";
import { usePopup } from "@share/hooks";
import { CloseIcon, IntroIcon } from "@share/icons";
import { Popup, SafeArea } from "antd-mobile";
import cls from "classnames";
import { default as React } from "react";
import { useTranslation } from "react-i18next";

const IntroductionService = () => {
  const { t } = useTranslation();
  const { visible, openPopup, closePopup } = usePopup();

  return (
    <>
      <IntroIcon
        className={"cursor-pointer"}
        onClick={openPopup}
      />
      <Popup
        visible={visible}
        onMaskClick={closePopup}
        position={"bottom"}
        bodyClassName="adm-popup-body-position-bottom-panel"
        bodyStyle={{
          backgroundColor: "none",
        }}
      >
        <SafeArea position="top" />
        <div
          className={cls(
            "bg-[#fff] max-width-content-layout m-auto relative h-full max-h-[80vh] overflow-y-scroll "
          )}
          style={{
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <div className="sticky top-0 flex flex-row text-center items-center justify-center relative bg-white">
            <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[70vw] md:max-w-[20vw] text-[18.72px] m-3 font-medium">
              {t("ss_builder_title_introduction_service")}
            </div>
            <div className="absolute right-3 flex items-center justify-center gap-3">
              <CloseIcon
                fillColor={"#999999"}
                className={
                  "absolute right-4 flex items-center justify-center gap-3 cursor-pointer"
                }
                onClick={closePopup}
                width={13}
                height={12}
              />
            </div>
          </div>
          <div className={"px-5 text-[15.6px] flex flex-col gap-3 break-words"}>
            <div>{t("ss_builder_content_1_introduction_service")}</div>
            <div className={"flex flex-row gap-2 items-center"}>
              <CheckCircleIcon style={{ minWidth: "32px" }} />
              <div>{t("ss_builder_content_list_1_introduction_service")}</div>
            </div>
            <div className={"flex flex-row gap-2 items-center"}>
              <CheckCircleIcon style={{ minWidth: "32px" }} />
              <div>{t("ss_builder_content_list_2_introduction_service")}</div>
            </div>
            <div className={"flex flex-row gap-2 items-center "}>
              <CheckCircleIcon style={{ minWidth: "32px" }} />
              <div>{t("ss_builder_content_list_3_introduction_service")}</div>
            </div>
            <div className={"font-medium"}>
              {t("ss_builder_content_2_introduction_service")}
            </div>
          </div>
          <div
            className={"sticky bottom-0 py-3 px-5 bg-white"}
            style={{
              boxShadow: "inset 0px 1px 0px #EEEEEE",
            }}
          >
            <button
              className={
                "bg-[#EE3244] w-full py-2 rounded text-white text-[17px]"
              }
              onClick={closePopup}
            >
              {t("ss_builder_button_close_introduction_service")}
            </button>
          </div>
        </div>
        <SafeArea position="bottom" />
      </Popup>
    </>
  );
};

export default IntroductionService;
