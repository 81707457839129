import { useAppSelector } from "@app/hooks";
import { generateColorFunc } from "@features/block/libs";
import { useEffect } from "react";

const PreviewStyleHOC = ({
  elementId,
  advancedSetting,
  isAppliedBlockGlobalSettings,
  children,
  blockUid,
}: {
  elementId?: string;
  advancedSetting?: any;
  isAppliedBlockGlobalSettings: boolean;
  children: any;
  blockUid?: string;
}) => {
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { heading_color_generate = {}, text_color_generate = {} } =
    siteSettings?.display_setting || {};

  useEffect(() => {
    const combinedElement = document.getElementById(elementId);
    const combinedHeadingElement = document.querySelectorAll(
      `#${blockUid}-heading`
    ) as any;
    
    const titleColorArr = generateColorFunc(advancedSetting?.color_title || "");
    const paragraphColorArr = generateColorFunc(
      advancedSetting?.color_paragraph || ""
    );
    if (
      advancedSetting?.use_advanced_setting &&
      !isAppliedBlockGlobalSettings
    ) {
      titleColorArr?.forEach((ele, index) => {
        combinedElement?.style.setProperty(`--heading-color-${index + 1}`, ele);
      });
      paragraphColorArr?.forEach((ele, index) => {
        combinedElement?.style.setProperty(`--text-color-${index + 1}`, ele);
      });
      for (let i = 0; i < combinedHeadingElement.length; i++) {
        titleColorArr?.forEach((ele, index) => {
          combinedHeadingElement[i]?.style.setProperty(
            `--heading-color-${index + 1}`,
            ele
          );
        });
        paragraphColorArr?.forEach((ele, index) => {
          combinedHeadingElement[i]?.style.setProperty(
            `--text-color-${index + 1}`,
            ele
          );
        });
      }
    } else if (
      advancedSetting?.use_advanced_setting &&
      isAppliedBlockGlobalSettings
    ) {
      Object.values(heading_color_generate)?.forEach((ele: any, index) => {
        combinedElement?.style.setProperty(`--heading-color-${index + 1}`, ele);
      });
      Object.values(text_color_generate)?.forEach((ele: any, index) => {
        combinedElement?.style.setProperty(`--text-color-${index + 1}`, ele);
      });
      for (let i = 0; i < combinedHeadingElement.length; i++) {
        titleColorArr?.forEach((ele, index) => {
          combinedHeadingElement[i]?.style.setProperty(
            `--heading-color-${index + 1}`,
            ele
          );
        });
        paragraphColorArr?.forEach((ele, index) => {
          combinedHeadingElement[i]?.style.setProperty(
            `--text-color-${index + 1}`,
            ele
          );
        });
      }
    } else {
      Object.values(heading_color_generate)?.forEach((ele: any, index) => {
        combinedElement?.style.setProperty(`--heading-color-${index + 1}`, ele);
      });
      Object.values(text_color_generate)?.forEach((ele: any, index) => {
        combinedElement?.style.setProperty(`--text-color-${index + 1}`, ele);
      });
      for (let i = 0; i < combinedHeadingElement.length; i++) {
        Object.values(heading_color_generate)?.forEach((ele, index) => {
          combinedHeadingElement[i]?.style.setProperty(
            `--heading-color-${index + 1}`,
            ele
          );
        });
        Object.values(text_color_generate)?.forEach((ele, index) => {
          combinedHeadingElement[i]?.style.setProperty(
            `--text-color-${index + 1}`,
            ele
          );
        });
      }
    }
  });
  return children;
};

export default PreviewStyleHOC;
