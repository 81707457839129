import React, { useEffect, useState } from "react";
import cls from "classnames";
import { List } from "antd-mobile";
import { BlockSelectionInterface } from "@features/block/config/block-selection";
import { SelectionStateEnum } from "@features/block/config/interface";
import { useTranslation } from "react-i18next";
import { OutLineInfoIcon } from "../..";
import style from "./style.module.scss";
interface Props {
  block: BlockSelectionInterface;
  onClick: () => void;
  init: any;
}

const Info = ({ title }) => {
  return (
    <div
      style={{ color: "#1890FF" }}
      className="pl-3 flex items-center justify-left text-sm"
    >
      <span className="mr-1">
        <OutLineInfoIcon />
      </span>
      <span>{title}</span>
    </div>
  );
};

export default function BlockSelection(props: Props) {
  const { block, onClick, init } = props;
  const [displayState, setDisplayState] = useState<SelectionStateEnum>(
    block.defaultState !== undefined
      ? block.defaultState
      : SelectionStateEnum.ENABLE
  );
  const [warningKey, setwarningKey] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (block.onLoad) {
      init(block.onLoad, setDisplayState, setwarningKey);
    }
  }, []);
  return displayState !== SelectionStateEnum.HIDDEN ? (
    <div
      className={cls(
        `mb-2 ${!!warningKey ? "pb-3" : ""} bg-white `,
        style.rounded,
        displayState !== SelectionStateEnum.DISABLE ? style.activeButton : null,
        style.area1,
        style.area2
      )}
    >
      <List.Item
        disabled={displayState !== SelectionStateEnum.ENABLE}
        description={t(block.description_key)}
        prefix={block.Icon(48, 48)}
        onClick={onClick}
      >
        <div
          className={cls("text-titleFontSize", style.fontSize)}
          style={{ fontSize: "18px !important" }}
        >
          {t(block.name_key)}
        </div>
      </List.Item>
      {!!warningKey && <Info title={t(warningKey)} />}
    </div>
  ) : (
    <></>
  );
}
