import { CardInterface } from "@features/main/store/interface";
import { CARD_LAYOUTS, LINK_LAYOUTS } from "@share/configs/const";
import React, { useCallback, useMemo } from "react";
import TextRightLeftLayout from "./TextRightLeftLayout";
import TextUnderLayout from "./TextUnderLayout";

export interface StyleAttributesInterface {
  button_size: any;
  button_link_template: string;
  button_link_layout: string;
  button_link_style: string;
}

export interface ContentAttributesInterface {
  button_content: string;
  button_icon: string;
  button_icon_url: string;
  use_aff_url: number;
  target_url: string;
  origin_url: string;
  button_description: string;
  image: string;
  image_name: string;
}

type Props = {
  style_attributes: StyleAttributesInterface;
  content_attributes: ContentAttributesInterface;
  isMultiLink: boolean;
};

export const LINK_LAYOUT_COMPONENT = {
  [LINK_LAYOUTS.TEXT_RIGHT]: TextRightLeftLayout,
  [LINK_LAYOUTS.TEXT_LEFT]: TextRightLeftLayout,
  [LINK_LAYOUTS.TEXT_UNDER]: TextUnderLayout,
};

const LinkLayouts = (props: Props) => {
  const LinkLayoutRender = useCallback(
    (props) => {
      let Element = <></>;
      if (LINK_LAYOUT_COMPONENT[props?.style_attributes?.button_link_layout]) {
        const RenderComponent =
          LINK_LAYOUT_COMPONENT[props?.style_attributes?.button_link_layout];
        return <RenderComponent {...props} />;
      }
      return Element;
    },
    [props?.style_attributes?.button_link_layout]
  );

  return <LinkLayoutRender {...props} />;
};

export default LinkLayouts;
