import React, { useEffect } from "react";
import BlockHeading from "@features/design/components/BlockHeadingComponent";
import AdvancedSettingWrapper from "@features/design/components/CommonBlockComponent/BlockCombined/AdvancedSettingWrapper";
import BlockListsDisplay from "@features/design/components/CommonBlockComponent/BlockCombined/BlockListsDisplay";
import cls from "classnames";
import { useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";

const PreviewContent = ({
  advancedSettingData,
  enableBlocks,
  blockSpace,
  blockTitle,
  blockDescription,
  isApplyGlobalSettings,
  blockType,
  blockUid,
}: {
  advancedSettingData?: any,
  enableBlocks?: Block[];
  blockSpace?: number;
  blockTitle?: string;
  blockDescription?: string;
  isApplyGlobalSettings?: boolean;
  blockType?: string;
  blockUid?: string;
}) => {
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const customStyle = !advancedSettingData?.use_advanced_setting
    ? {
        marginLeft: "var(--block-outside-space)",
        marginRight: "var(--block-outside-space)",
        padding: " 12px var(--block-inner-space)",
        backgroundColor: "var(--block-background-color)",
        backdropFilter: "blur(var(--block-background-blur))",
        boxShadow: "var(--block-box-shadow)",
        borderRadius: "var(--block-border-radius)",
        borderWidth: "var(--block-border-width)",
        borderStyle: "var(--block-border-style)",
        borderColor: "var(--block-border-color)",
      }
    : {};

  return (
    <div
      className="block-combined-preview-fullscreen-content design-class py-3 background-image background-color"
      style={{
        pointerEvents: "none",
        backgroundPosition: "center center",
        objectFit: "cover",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className={cls("relative", `block-${blockType}`)}
        style={customStyle}
      >
        {advancedSettingData?.use_advanced_setting ? (
          <AdvancedSettingWrapper advancedSetting={advancedSettingData}>
            <BlockHeading
              title={blockTitle}
              blockType={blockType}
              subtitle={blockDescription}
              blockUid={blockUid}
            />
            <div
              style={{
                marginLeft:
                  isApplyGlobalSettings && outside_space ? "-12px" : "0px",
                marginRight:
                  isApplyGlobalSettings && outside_space ? "-12px" : "0px",
              }}
            >
              <BlockListsDisplay
                blocks={enableBlocks}
                blockSpace={blockSpace}
                isApplyGlobalSettings={isApplyGlobalSettings}
              />
            </div>
          </AdvancedSettingWrapper>
        ) : (
          <>
            <BlockHeading
              title={blockTitle}
              blockType={blockType}
              subtitle={blockDescription}
            />
            <div
              style={{
                marginLeft:
                  isApplyGlobalSettings && outside_space ? "-12px" : "0px",
                marginRight:
                  isApplyGlobalSettings && outside_space ? "-12px" : "0px",
              }}
            >
              <BlockListsDisplay
                blocks={enableBlocks}
                blockSpace={blockSpace}
                isApplyGlobalSettings={isApplyGlobalSettings}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PreviewContent;
