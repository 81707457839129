import { CardInterface } from "@features/main/store/interface";
import { PRODUCT_DISPLAY_STYLES } from "@share/configs/const";
import React, { useCallback, useMemo } from "react";
import ProductStyleGrid from "./ProductStyleGrid";
import ProductStyleSlide from "./ProductStyleSlide";
import ProductStyleTextOverImage from "./ProductStyleTextOverImage";
import ProductStyleZigzag from "./ProductStyleZigzag";

export interface StyleAttributesInterface {
  style_display: string;
  item_on_row: string;
}

type Props = {
  styleAttributes: StyleAttributesInterface;
  products: any;
  onUpdateProductGroupData: (data: any) => void;
  productGroupId?: string;
  affiliateProductGroupData?: any;
  productGroupMaximum?: number;
  productCount?: number;
  allProductLists?: any;
};

export const PRODUCT_LAYOUT_COMPONENT = {
  [PRODUCT_DISPLAY_STYLES.GRID]: ProductStyleGrid,
  [PRODUCT_DISPLAY_STYLES.SLIDE]: ProductStyleSlide,
  [PRODUCT_DISPLAY_STYLES.TEXT_OVER_IMAGE]: ProductStyleTextOverImage,
  [PRODUCT_DISPLAY_STYLES.ZIGZAG]: ProductStyleZigzag,
};

const ProductStyleDisplay = (props: Props) => {
  const ProductListLayoutRender = useCallback((props) => {
    let Element = <></>;
    const styleDisplay = props?.styleAttributes?.style_display?.includes(
      "grid-"
    )
      ? "grid"
      : props?.styleAttributes?.style_display;

    if (PRODUCT_LAYOUT_COMPONENT[styleDisplay]) {
      const RenderComponent = PRODUCT_LAYOUT_COMPONENT[styleDisplay];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);

  return <ProductListLayoutRender {...props} />;
};

export default ProductStyleDisplay;
