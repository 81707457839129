import React, { useEffect, useMemo, useRef, useState } from "react";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { CollapseIcon, ExpandIcon } from "@share/icons";
import draftToHtml from "draftjs-to-html";
import * as DOMPurify from "dompurify";

const BioContent = (props) => {
  const { data, isCenter = false, className = "" } = props;
  const { t } = useTranslation();
  const refOfElement = useRef<HTMLInputElement>();

  const [isExpand, setIsExpand] = useState(true);
  const [isShowButton, setIsShowButton] = useState(false);

  const cleanBioContent = useMemo(() => {
    let bioContent = "";
    if (data) {
      if (typeof data == "string") {
        bioContent = data;
      } else {
        bioContent = draftToHtml(data);
      }
    }
    return DOMPurify.sanitize(bioContent);
  }, [data]);

  useEffect(() => {
    let height;
    const timer = setTimeout(() => {
      if (refOfElement) {
        height = refOfElement.current?.offsetHeight;
        if (height) {
          setIsShowButton(parseInt(height) > 80);
        }
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [cleanBioContent]);

  return (
    <div>
      <div
        ref={refOfElement}
        className={cls(
          "w-full bio-content",
          isShowButton && isExpand && "bio-collapse",
          className
        )}
        style={{
          color: "var(--text-color-6)",
        }}
        dangerouslySetInnerHTML={{
          __html: cleanBioContent,
        }}
      ></div>
      {isShowButton && (
        <div
          className={cls(
            "cursor-pointer mt-1 text-[13px] font-medium flex gap-x-1 w-fit",
            isCenter && "mx-auto"
          )}
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? t("ss_builder_expand") : t("ss_builder_collapse")}
          <div className="my-auto">
            {isExpand ? (
              <ExpandIcon
                className=""
                fillColor="var(--text-color-6)"
              />
            ) : (
              <CollapseIcon
                className=""
                fillColor="var(--text-color-6)"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default BioContent;
