import { Block } from "@features/main/store/interface";
import { LazadaIcon, ShopeeIcon, TikiIcon } from "@share/icons";
import { Button, Ellipsis } from "antd-mobile";
import React, { useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
};

const PRODUCT_MAX_DEFAULT = 12;

const BlockAffiliateProduct = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
  } = props;
  const enable = blockData && blockData.enable;

  const content_attributes = blockData && blockData.content_attributes;
  const style_attributes = blockData && blockData.style_attributes;
  const { style_display } = style_attributes;

  const {
    title_product_group,
    see_more_btn_content,
    items: dataProductListArr,
  } = content_attributes;

  const dataProductListSortOrder =
    dataProductListArr && orderBy(dataProductListArr, ["sort_order"], ["asc"]);

  const productListEnable =
    dataProductListSortOrder &&
    dataProductListSortOrder.filter((x) => x.enable === 1);

  const productListDefault = useMemo(() => {
    const productListEnableClone = cloneDeep(productListEnable);
    if (productListEnableClone.length > PRODUCT_MAX_DEFAULT) {
      productListEnableClone.length = PRODUCT_MAX_DEFAULT;
    }
    return productListEnableClone;
  }, [productListEnable]);

  const [productListDisplay, setProductListDisplay] =
    useState(productListDefault);

  const SourceTypeRender = (type) => {
    const sourceType = type.sourceType;
    let iconElement = null;
    switch (sourceType) {
      case "shopee":
        iconElement = (
          <div className="flex flex-row gap-1">
            <ShopeeIcon className="w-4 h-4" />
            <div className="text-xs leading-4">Shoppe.vn</div>
          </div>
        );
        break;
      case "lazada":
        iconElement = (
          <div className="flex flex-row gap-1">
            <LazadaIcon className="w-4 h-4" />
            <div className="text-xs leading-4">Lazada.vn</div>
          </div>
        );
        break;
      case "tiki":
        iconElement = (
          <div className="flex flex-row gap-1">
            <TikiIcon className="w-4 h-4" />
            <div className="text-xs leading-4">Tiki.vn</div>
          </div>
        );
        break;
    }
    return iconElement;
  };

  const styleDisPlayClass = useMemo(() => {
    let className = "";
    switch (style_display) {
      case "grid-1":
        className = "grid grid-cols-1 gap-3";
        break;
      case "grid-2":
        className = "grid grid-cols-2 gap-3";
        break;
      case "grid-3":
        className = "grid grid-cols-3 gap-3";
        break;
      case "slide":
        className = "";
        break;
    }
    return className;
  }, [style_display]);

  const handleSeeMoreProducts = (event) => {
    event.stopPropagation();
    setProductListDisplay(productListEnable);
  };

  const isDisplayBtnSeeMore =
    style_display !== "slide" &&
    productListEnable.length > productListDisplay.length;

  return (
    <BlockWrapper
      blockType={blockData.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      isFirstItem={isFirstItem}
    >
      <BlockHeading
        blockType={blockData.block_type}
        title={title_product_group}
      />
      <div className={`${styleDisPlayClass} affiliate-product-wrapper`}>
        {productListDisplay &&
          productListDisplay.map((ele, index) => (
            <div key={index}>
              <LazyLoadImage
                //   width={101}
                //   height={101}
                src={ele.image}
                // fit="cover"
                className="object-center affiliate-product-image"
                style={{
                  backgroundSize: "cover",
                  aspectRatio: "1/1",
                  borderRadius: "4px",
                  margin: "0 auto",
                  objectFit: "cover",
                  width: "100%",
                  // maxWidth: "144px"
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src =
                    "https://i.ecomobi.com/ssp/passio-page/image-default.png";
                }}
              />
              <Ellipsis
                direction="end"
                content={ele.title}
                className="mt-2 affiliate-product-name"
                rows={2}
                // style={{
                //   lineHeight: "var(--paragraph-line-height)"
                // }}
              />
              <div>
                <SourceTypeRender sourceType={ele.source_type} />
              </div>
            </div>
          ))}
      </div>
      {isDisplayBtnSeeMore && (
        <div>
          <Button
            block
            // color="primary"
            size="middle"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
              gap: "5px",
            }}
            onClick={handleSeeMoreProducts}
            // className="button-border-radius button-background-color button-border-width button-border-color button-border-style button-text-color button-box-shadow affiliate-product-button-see-more middle-button"
            className="pp-button"
          >
            <div>{see_more_btn_content}</div>
          </Button>
        </div>
      )}
    </BlockWrapper>
  );
};

export default BlockAffiliateProduct;
