import React, { useMemo } from "react";
import { useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import {
  BIG,
  MIDDLE,
  ROUND_PHOTO,
  SMAll,
} from "@share/configs/const";
import { Ellipsis } from "antd-mobile";
import orderBy from "lodash/orderBy";
import DndScrollable from "@share/components/dnd-scrollable";
import BlockWrapper from "../../BlockWrapper";
import BlockHeading from "../../BlockHeadingComponent";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockStory = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const {
    block_heading,
    block_description,
    items: dataImageArr,
    size_of_image,
    type_of_image = ROUND_PHOTO,
  } = blockData?.content_attributes || {};

  const dataImageSortOrder =
    dataImageArr && orderBy(dataImageArr, ["sort_order"], ["asc"]);

  const imageArrEnable =
    dataImageSortOrder && dataImageSortOrder.filter((x) => x.enable === 1);

  const sizeImage = useMemo(() => {
    switch (size_of_image) {
      case SMAll:
        return "84px";
      case MIDDLE:
        return "100px";
      case BIG:
        return "125px";
      default:
        return "100px";
      // to do
    }
  }, [size_of_image]);

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <BlockHeading
        blockType={blockData.block_type}
        title={block_heading}
        subtitle={block_description}
      />
      <DndScrollable className="custom-gap">
        {imageArrEnable &&
          Array.isArray(imageArrEnable) &&
          imageArrEnable.map((ele, index) => {
            // const urlResize = `${DOMAIN_RESIZE_IMG}${
            //   ele?.image?.split("builder")[1]
            // }`;
            return (
              <div
                key={ele.uid}
                className="gap-[4px] flex flex-col"
              >
                <div
                  style={
                    {
                      WebkitBoxShadow: `inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2)`,
                      MozBoxShadow: `inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2)`,
                      boxShadow: `inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2)`,
                      // background: `url(${urlResize}) center center / cover`,
                      background: `url("${ele?.image}") center center / cover`,
                      width: sizeImage,
                      height: sizeImage,
                      borderRadius:
                        type_of_image === ROUND_PHOTO ? "9999px" : "4px",
                    } as any
                  }
                ></div>
                {ele!.title && (
                  <Ellipsis
                    className="text-center story-title"
                    content={ele.title}
                    rows={2}
                    style={{ maxWidth: sizeImage }}
                  />
                )}
              </div>
            );
          })}
      </DndScrollable>
    </BlockWrapper>
  );
};

export default BlockStory;
