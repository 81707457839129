import { useAppSelector } from "@app/hooks";
import { Block } from "@features/main/store/interface";
import { CARD_STYLE } from "@share/configs/const";
import React from "react";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";
import CardStyleGrid from "./CardStyleDisplay/CardStyleGrid";
import CardStyleSlide from "./CardStyleDisplay/CardStyleSlide";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockCard = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const style_attributes = blockData?.style_attributes;
  const content_attributes = blockData?.content_attributes;
  const { card_style } = style_attributes || {};
  const {
    block_heading,
    block_description,
    items: cardItemArr,
    see_more_btn_content,
  } = content_attributes || {};

  const cardsEnable = cardItemArr?.filter((x) => x.enable === 1);

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <BlockHeading
        blockType={blockData.block_type}
        title={block_heading}
        subtitle={block_description}
      />
      {card_style === CARD_STYLE.GRID && (
        <CardStyleGrid
          style_attibutes={style_attributes}
          cardItemArr={cardsEnable}
          see_more_btn_content={see_more_btn_content}
        />
      )}
      {card_style === CARD_STYLE.SLIDE && (
        <CardStyleSlide
          style_attibutes={style_attributes}
          cardItemArr={cardsEnable}
          see_more_btn_content={see_more_btn_content}
        />
      )}
    </BlockWrapper>
  );
};

export default BlockCard;
