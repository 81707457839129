import {
  ArrowCenter,
  ArrowDown,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpLeft,
  ArrowUpRight,
} from "@share/icons";
import React from "react";
import { BUTTON_SETTING_RADIUS_STYLE } from "@share/configs/const";
import {
  HardShadowCustomIcon,
  HardShadowRoundedIcon,
  HardShadowSquareIcon,
  NoShadowCustomIcon,
  NoShadowRoundedIcon,
  NoShadowSquareIcon,
  SoftShadowCustomIcon,
  SoftShadowRoundedIcon,
  SoftShadowSquareIcon,
} from "@features/setting/components/Icons";

export const directionButtonOptions = [
  {
    label: <ArrowUpLeft className="align-middle inline-block" />,
    value: "top left",
  },
  {
    label: <ArrowUp className="align-middle inline-block" />,
    value: "top",
  },
  {
    label: <ArrowUpRight className="align-middle inline-block" />,
    value: "top right",
  },
  {
    label: <ArrowLeft className="align-middle inline-block" />,
    value: "left",
  },
  {
    label: <ArrowCenter className="align-middle inline-block" />,
    value: "center",
  },
  {
    label: <ArrowRight className="align-middle inline-block" />,
    value: "right",
  },
  {
    label: <ArrowDownLeft className="align-middle inline-block" />,
    value: "bottom left",
  },
  {
    label: <ArrowDown className="align-middle inline-block" />,
    value: "bottom",
  },
  {
    label: <ArrowDownRight className="align-middle inline-block" />,
    value: "bottom right",
  },
];

export const directionButtonOptionsV2 = [
  {
    label: <ArrowUpLeft className="align-middle inline-block" />,
    value: "to top left",
  },
  {
    label: <ArrowUp className="align-middle inline-block" />,
    value: "to top",
  },
  {
    label: <ArrowUpRight className="align-middle inline-block" />,
    value: "to top right",
  },
  {
    label: <ArrowLeft className="align-middle inline-block" />,
    value: "to left",
  },
  {
    label: <div className="" />,
    value: "center",
    disabled: true,
  },
  {
    label: <ArrowRight className="align-middle inline-block" />,
    value: "to right",
  },
  {
    label: <ArrowDownLeft className="align-middle inline-block" />,
    value: "to bottom left",
  },
  {
    label: <ArrowDown className="align-middle inline-block" />,
    value: "to bottom",
  },
  {
    label: <ArrowDownRight className="align-middle inline-block" />,
    value: "to bottom right",
  },
];

export const marks = {
  0: 0,
  100: 100,
};
export const COLOR_TYPE_SOLID = "solid";
export const COLOR_TYPE_GRADIENT = "gradient";

export const RADIUS_STYLE_SQUARE = "square";
export const RADIUS_STYLE_ROUNDED = "rounded";
export const RADIUS_STYLE_CUSTOM = "custom";

export const masks = {
  0: 0,
  1: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
  7: "",
  8: "",
  9: "",
  10: "",
  11: "",
  12: "",
  13: "",
  14: "",
  15: "",
  16: "",
  17: "",
  18: "",
  19: "",
  20: "",
  21: "",
  22: "",
  23: "",
  24: "",
  25: "",
  26: "",
  27: "",
  28: "",
  29: "",
  30: "",
  31: "",
  32: "",
  33: "",
  34: "",
  35: "",
  36: "",
  37: "",
  38: "",
  39: "",
  40: "",
  41: "",
  42: "",
  43: "",
  44: "",
  45: "",
  46: "",
  47: "",
  48: 48,
};

export const noShadowIcon = (radius_type) => {
  let iconElement = null;
  switch (radius_type) {
    case BUTTON_SETTING_RADIUS_STYLE.SQUARE:
      iconElement = <NoShadowSquareIcon className={"m-auto"} />;
      break;
    case BUTTON_SETTING_RADIUS_STYLE.ROUNDED:
      iconElement = <NoShadowRoundedIcon className={"m-auto"} />;
      break;
    case BUTTON_SETTING_RADIUS_STYLE.CUSTOM:
      iconElement = <NoShadowCustomIcon className={"m-auto"} />;
      break;
    default:
      iconElement = <NoShadowSquareIcon className={"m-auto"} />;
  }
  return iconElement;
};

export const softShadowIcon = (radius_type) => {
  let iconElement = null;
  switch (radius_type) {
    case BUTTON_SETTING_RADIUS_STYLE.SQUARE:
      iconElement = <SoftShadowSquareIcon className={"m-auto"} />;
      break;
    case BUTTON_SETTING_RADIUS_STYLE.ROUNDED:
      iconElement = <SoftShadowRoundedIcon className={"m-auto"} />;
      break;
    case BUTTON_SETTING_RADIUS_STYLE.CUSTOM:
      iconElement = <SoftShadowCustomIcon className={"m-auto"} />;
      break;
    default:
      iconElement = <SoftShadowSquareIcon className={"m-auto"} />;
  }
  return iconElement;
};
export const hardShadowIcon = (radius_type) => {
  let iconElement = null;
  switch (radius_type) {
    case BUTTON_SETTING_RADIUS_STYLE.SQUARE:
      iconElement = <HardShadowSquareIcon className={"m-auto"} />;
      break;
    case BUTTON_SETTING_RADIUS_STYLE.ROUNDED:
      iconElement = <HardShadowRoundedIcon className={"m-auto"} />;
      break;
    case BUTTON_SETTING_RADIUS_STYLE.CUSTOM:
      iconElement = <HardShadowCustomIcon className={"m-auto"} />;
      break;
    default:
      iconElement = <HardShadowSquareIcon className={"m-auto"} />;
  }
  return iconElement;
};

export const masksForBorderWidth = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
};

export const TITLE_COMBINED = "Combined block";
export const DES_COMBINED =
  "Maecenas faucibus tempor nulla, ac eleifend nulla commodo eu. Nam id risus nisi.";
