import { useAppSelector } from "@app/hooks";
import BlockImageGalleryGrid from "@features/design/components/CommonBlockComponent/BlockImageGallery/block-grid";
import ImageGalleryStyleSlide from "@features/design/components/CommonBlockComponent/BlockImageGallery/ImageGalleryStyleSlide";
import { Block } from "@features/main/store/interface";
import { STYLE_SLIDE_IMAGE } from "@share/configs/const";
import orderBy from "lodash/orderBy";
import React, { useLayoutEffect } from "react";
import StackGrid from "react-stack-grid";
import BlockHeading from "../../BlockHeadingComponent";
import BlockWrapper from "../../BlockWrapper";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockImageGallery = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const content_attributes = blockData && blockData.content_attributes;
  const style_attributes = blockData && blockData.style_attributes;
  const { style_display, number_items_per_row, ratio } = style_attributes;

  const {
    title_image_gallery,
    description_image_gallery,
    items: dataImageGalleryListArr,
  } = content_attributes;

  useLayoutEffect(() => {
    if (dataImageGalleryListArr) {
    }
  }, [dataImageGalleryListArr]);

  const dataImageGalleryListSortOrder =
    dataImageGalleryListArr &&
    orderBy(dataImageGalleryListArr, ["sort_order"], ["asc"]);

  const imageGalleryListEnable =
    dataImageGalleryListSortOrder &&
    dataImageGalleryListSortOrder.filter((x) => x.enable === 1);

  return (
    <div id={`${blockData?.uid} ${enable}`}>
      <BlockWrapper
        blockType={blockData?.block_type}
        isEnable={enable}
        positionSlug={positionSlug}
        tabUid={tabUid}
        blockUid={blockData?.uid}
        index={index}
        listBlocksSortOrder={listBlocksSortOrder}
        outsideSpace={outside_space}
        insideSpace={inner_space_number}
        isFirstItem={isFirstItem}
        isApplyBlockSettings={isApplyBlockSettings}
        isHideMoreActions={isHideMoreActions}
      >
        <BlockHeading
          title={title_image_gallery}
          blockType={blockData.block_type}
          subtitle={description_image_gallery}
        />
        <div className="w-full h-auto">
          {style_display === STYLE_SLIDE_IMAGE.GRID && (
            <BlockImageGalleryGrid
              imageGalleryList={imageGalleryListEnable}
              number_items_per_row={number_items_per_row}
              ratio={ratio}
            />
          )}
          {style_display === STYLE_SLIDE_IMAGE.MASONRY && (
            <StackGrid
              columnWidth="50%"
              monitorImagesLoaded={true}
            >
              {imageGalleryListEnable?.map((ele, index) => (
                <div key={index}>
                  <img
                    alt={ele.name}
                    src={ele.image}
                    style={{
                      maxWidth: "100%",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ))}
            </StackGrid>
          )}
          {style_display === STYLE_SLIDE_IMAGE.SLIDE && (
            <ImageGalleryStyleSlide
              style_attributes={style_attributes}
              imageGalleryItemArr={imageGalleryListEnable}
            />
          )}
        </div>
      </BlockWrapper>
    </div>
  );
};

export default BlockImageGallery;
