import { setDynamicParams } from "@features/main/store/dynamicSlice";
import { setPosition } from "@features/main/store/positionsSlice";
import {
  setDisplaySetting,
  setSiteInfo,
} from "@features/main/store/siteSettingSlice";
import {
  fetchSiteSetting,
  publishSetting,
  requestRestoreTheme,
  requestSaveThemeSettings,
  requestUploadImage,
  saveSiteSetting,
} from "@share/api";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";

export const getSite = () => async (dispatch, getState) => {
  const {
    user: { kol },
  } = getState();
  const userId = kol && kol._id;
  let responseSite = null;
  try {
    responseSite = await fetchSiteSetting(userId);
  } catch (error) {
    console.log(error);
  }
  if (responseSite) {
    const payloadPosition = responseSite.positions;
    const responseSiteClone = cloneDeep(responseSite);
    if (responseSiteClone) delete responseSiteClone.positions;
    dispatch(setPosition({ positions: payloadPosition }));
    dispatch(setSiteInfo({ sites: responseSiteClone }));
  }
};
var sending = false;
var timeOut = null;

const handleAutoSave = debounce(
  async (dispatch, dataResquest, callback, getState) => {
    try {
      const { positions, siteSettings } = getState();
      const data = {
        ...siteSettings,
        positions,
      };
      if (sending) {
        if (timeOut) {
          clearTimeout(timeOut);
          return;
        }
        timeOut = setTimeout(
          () => handleAutoSave(dispatch, data, callback, getState),
          300
        );
        return;
      }
      sending = true;
      await saveSiteSetting(data).then((res) => {
        callback(res);
        sending = false;
      });
    } catch (error) {
      sending = false;
      dispatch(
        setDynamicParams({
          data: {
            isError: true,
          },
        })
      );
    }
  },
  3000
);

const handleAutoSaveForBackgroundSetting = debounce(
  async (dispatch, dataResquest, callback, getState, displaySettings) => {
    try {
      const { positions, siteSettings } = getState();
      const data = {
        ...siteSettings,
        display_setting: displaySettings,
        positions,
      };
      if (sending) {
        if (timeOut) {
          clearTimeout(timeOut);
          return;
        }
        timeOut = setTimeout(
          () =>
            handleAutoSaveForBackgroundSetting(
              dispatch,
              data,
              callback,
              getState,
              displaySettings
            ),
          300
        );
        return;
      }
      sending = true;
      await saveSiteSetting(data).then((res) => {
        callback(res);
        sending = false;
      });
    } catch (error) {
      sending = false;
      dispatch(
        setDynamicParams({
          data: {
            isError: true,
          },
        })
      );
    }
  },
  3000
);

export const saveSite =
  (isAutoSave?: boolean, displaySettings = null) =>
  async (dispatch, getState) => {
    let isCheckSaveSite = false;
    const { positions, siteSettings } = getState();
    const dataResquest = {
      ...siteSettings,
      positions,
    };
    let responseSaveSite = null;
    const handleResponseData = (responseSaveSite) => {
      if (responseSaveSite) {
        isCheckSaveSite = true;
        const payloadPosition = responseSaveSite.positions;
        const responseSaveSiteClone = cloneDeep(responseSaveSite);
        if (responseSaveSiteClone) delete responseSaveSiteClone.positions;
        dispatch(setPosition({ positions: payloadPosition }));
        dispatch(setSiteInfo({ sites: responseSaveSiteClone }));
        dispatch(
          setDynamicParams({
            data: {
              isSaving: false,
            },
          })
        );
        dispatch(
          setDynamicParams({
            data: {
              isError: false,
            },
          })
        );
      } else {
        dispatch(
          setDynamicParams({
            data: {
              isError: true,
            },
          })
        );
      }
    };
    try {
      if (isAutoSave) {
        if (displaySettings) {
          handleAutoSaveForBackgroundSetting(
            dispatch,
            dataResquest,
            handleResponseData,
            getState,
            displaySettings
          );
        } else {
          handleAutoSave(dispatch, dataResquest, handleResponseData, getState);
        }
      } else {
        responseSaveSite = await saveSiteSetting(dataResquest);
        handleResponseData(responseSaveSite);

        return isCheckSaveSite;
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setDynamicParams({
          data: {
            isError: true,
          },
        })
      );
    }
  };

export const publishSite =
  (handleShowResultPublish, handleHideLoadingBtn) =>
  async (dispatch, getState) => {
    // let checkSaveSite = null;
    // try {
    //   checkSaveSite = await dispatch(saveSite());
    // } catch (error) {
    //   console.log(error);
    //   setDynamicParams({
    //     data: {
    //       isError: true,
    //     },
    //   });
    // }
    const { siteSettings } = getState();
    const dataResquest = {
      id: siteSettings.id,
      user_id: siteSettings.user_id,
      version: siteSettings.version,
    };

    let responsePublishSite = null;
    // if (checkSaveSite) {
    try {
      responsePublishSite = await publishSetting(dataResquest);
    } catch (error) {
      console.log(error);
      setDynamicParams({
        data: {
          isError: true,
        },
      });
    }
    if (responsePublishSite) {
      handleShowResultPublish(true);
      handleHideLoadingBtn();
      const payloadPosition = responsePublishSite.positions;
      const responsePublishSiteClone = cloneDeep(responsePublishSite);
      if (responsePublishSiteClone) delete responsePublishSiteClone.positions;
      dispatch(setPosition({ positions: payloadPosition }));
      dispatch(setSiteInfo({ sites: responsePublishSiteClone }));
      dispatch(
        setDynamicParams({
          data: {
            isError: false,
          },
        })
      );
    } else {
      dispatch(
        setDynamicParams({
          data: {
            isError: true,
          },
        })
      );
    }
    // }
  };

export const saveColor =
  (
    primaryColor,
    secondColor,
    primaryColorGenerate = {},
    secondaryColorGenerate = {}
  ) =>
  async (dispatch, getState) => {
    const currenState = getState();
    const { siteSettings } = currenState;
    const displaySettings = {
      ...siteSettings.display_setting,
      primary_color: primaryColor,
      second_color: secondColor,
      primary_color_generate: primaryColorGenerate,
      secondary_color_generate: secondaryColorGenerate,
    };
    dispatch(setDisplaySetting({ data: displaySettings }));
    // dispatch(saveSite());
  };
export const saveMainColor = (primaryColor) => async (dispatch, getState) => {
  const currenState = getState();
  const { siteSettings } = currenState;
  const displaySettings = {
    ...siteSettings.display_setting,
    primary_color: primaryColor,
  };
  dispatch(setDisplaySetting({ data: displaySettings }));
  // dispatch(saveSite());
};

export const saveThemeBlockSetting = (data) => (dispatch, getState) => {
  const currentState = getState();
  const { siteSettings } = currentState;
  const displaySettings = {
    ...siteSettings.display_setting,
    block_setting: data,
  };
  dispatch(setDisplaySetting({ data: displaySettings }));
  // dispatch(saveSite());
};

export const saveButtonThemeSetting = (data) => (dispatch, getState) => {
  const currentState = getState();
  const { siteSettings } = currentState;
  const displaySettings = {
    ...siteSettings.display_setting,
    button_setting: data,
  };
  dispatch(setDisplaySetting({ data: displaySettings }));
  // dispatch(saveSite());
};

export const saveBackgroundSetting = (data) => (dispatch, getState) => {
  console.log(data);
};

export const saveThemeSetting =
  (callbackFunctions, data) => async (dispatch, getState) => {
    const {
      handleShowSuccessAlert,
      handleShowFailAlert,
      handleCancelThemeSetting,
    } = callbackFunctions;
    const { dataImageBase64, dataImageFileName } = data;
    const {
      siteSettings,
      siteSettings: {
        display_setting: {
          background: { gradient },
          meta_data,
        },
      },
      dynamicConfig: {
        themeSettings: {
          primaryColor,
          fontFamily,
          gradientColor,
          solidColor,
          typeBackground,
          imageUrl,
        },
      },
    } = getState();

    const dataResquestUploadImage = {
      id: siteSettings.id,
      user_id: siteSettings.user_id,
      name: dataImageFileName,
      content: dataImageBase64 || imageUrl,
    };

    let response = null;
    let responseImage = null;
    if (dataImageBase64) {
      try {
        responseImage = await requestUploadImage(dataResquestUploadImage);
      } catch (error) {
        console.log(error);
      }
      if (responseImage) {
        const dataResquest = {
          id: siteSettings.id,
          user_id: siteSettings.user_id,
          display_setting: {
            primary_color: primaryColor,
            font_family: fontFamily,
            background: {
              type: typeBackground,
              color: solidColor,
              gradient: gradientColor || gradient,
              image: responseImage.url,
            },
            meta_data: meta_data,
          },
        };
        try {
          response = await requestSaveThemeSettings(dataResquest);
        } catch (error) {
          console.log(error);
        }
        if (response && /^20[0-9]$/.test(response.status.toString())) {
          const data = response.data;
          dispatch(setDisplaySetting({ data: data }));
          handleShowSuccessAlert();
          handleCancelThemeSetting();
          // dispatch(saveSite());
        } else handleShowFailAlert();
      }
    } else {
      const dataResquest = {
        id: siteSettings.id,
        user_id: siteSettings.user_id,
        display_setting: {
          primary_color: primaryColor,
          font_family: fontFamily,
          background: {
            type: typeBackground,
            color: solidColor,
            gradient: gradientColor || gradient,
            image: imageUrl,
          },
          meta_data: meta_data,
        },
      };
      try {
        response = await requestSaveThemeSettings(dataResquest);
      } catch (error) {
        console.log(error);
      }
      if (response && /^20[0-9]$/.test(response.status.toString())) {
        const data = response.data;
        dispatch(setDisplaySetting({ data: data }));
        handleShowSuccessAlert();
        handleCancelThemeSetting();
        // dispatch(saveSite());
      } else handleShowFailAlert();
    }
  };

export const restoreTheme =
  (handleShowRestoreSuccessfully) => async (dispatch, getState) => {
    const { siteSettings } = getState();
    const dataResquest = {
      id: siteSettings.id,
      user_id: siteSettings.user_id,
    };
    let response = null;
    try {
      response = await requestRestoreTheme(dataResquest);
    } catch (error) {
      console.log(error);
    }
    if (response && /^20[0-9]$/.test(response.status.toString())) {
      const payloadPosition =
        response && response.data && response.data.positions;
      const responseClone =
        response && response.data && cloneDeep(response.data);
      if (responseClone) delete responseClone.positions;
      dispatch(setPosition({ positions: payloadPosition }));
      dispatch(setSiteInfo({ sites: responseClone }));
      handleShowRestoreSuccessfully();
    }
  };
