import { BLOCK_EDIT_COMPONENT_CONFIG } from "@features/block/block.conf";
import { Block } from "@features/main/store/interface";
import React, { useMemo, Suspense } from "react";

const Block = ({
  blockData,
  onUpdateData,
  setButtonStatus,
  setEditStatus,
  setButtonLoading,
  handleCancelBlock,
  setShowFooterBlockEdit,
  defaultBlockData,
}: {
  blockData: Block;
  onUpdateData: (data) => void;
  setButtonLoading?: (isLoading) => void;
  setButtonStatus: (status) => void;
  setEditStatus: (status) => void;
  handleCancelBlock;
  setShowFooterBlockEdit?: (status: boolean) => void;
  defaultBlockData?: Block;
}) => {
  const Component = useMemo(() => {
    if (BLOCK_EDIT_COMPONENT_CONFIG[blockData?.block_type]) {
      return BLOCK_EDIT_COMPONENT_CONFIG[blockData?.block_type];
    }
    return (props) => <></>;
  }, [blockData?.block_type]);
  return (
    <Suspense fallback={<></>}>
      <Component
        block={blockData}
        onUpdateData={onUpdateData}
        setButtonStatus={setButtonStatus}
        setEditStatus={setEditStatus}
        setButtonLoading={setButtonLoading}
        handleCancelBlock={handleCancelBlock}
        setShowFooterBlockEdit={setShowFooterBlockEdit}
        defaultBlockData={defaultBlockData}
      />
    </Suspense>
  );
};

export default Block;
