import { useAppSelector } from "@app/hooks";
import { LAYOUT_TYPE_ENUM } from "@features/block/block-editor/SpacerAndDivider/DividerSetting";
import DividerImage from "@features/block/block-editor/SpacerAndDivider/Preview/SpacerAndDividerSample/DividerImage";
import DividerLine from "@features/block/block-editor/SpacerAndDivider/Preview/SpacerAndDividerSample/DividerLine";
import { Block } from "@features/main/store/interface";
import React, { useMemo } from "react";
import BlockWrapper from "../../BlockWrapper";

type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};

const BlockSpacerAndDivider = (props: Props) => {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;

  const enable = blockData && blockData.enable;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space, block_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const {
    spacing_value,
    divider_thickness,
    divider_color,
    image: divider_image,
  } = blockData?.content_attributes || {};

  const { divider_type, layout_type } = blockData?.style_attributes || {};

  const layoutRender = useMemo(() => {
    if (!layout_type)
      return (
        <div
          className="w-full"
          style={{ height: `${spacing_value}px` }}
        ></div>
      );
    return (
      <div className="h-full w-full">
        <div style={{ height: `${block_space / 2}px` }}></div>
        <div style={{ height: `${spacing_value / 2}px` }}></div>
        {layout_type === LAYOUT_TYPE_ENUM.DEFAULTS && (
          <DividerLine
            divider_thickness={divider_thickness}
            divider_color={divider_color}
            divider_type={divider_type}
          />
        )}
        {layout_type === LAYOUT_TYPE_ENUM.IMAGE && (
          <DividerImage divider_image={divider_image} />
        )}
        <div style={{ height: `${spacing_value / 2}px` }}></div>
        <div style={{ height: `${block_space / 2}px` }}></div>
      </div>
    );
  }, [
    spacing_value,
    layout_type,
    block_space,
    divider_image,
    divider_thickness,
    divider_color,
    divider_type,
  ]);

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      {layoutRender}
    </BlockWrapper>
  );
};

export default BlockSpacerAndDivider;
