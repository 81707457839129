import { ButtonMultiLinkInterface } from "@features/main/store/interface";
import {
  LINK_LAYOUTS,
  MULTI_BUTTON_LINK_ITEM_ON_ROW,
} from "@share/configs/const";
import React, { Fragment, useCallback, useMemo } from "react";
import Layout1 from "./Layout1";
import Layout2 from "./Layout2";
import Layout3 from "./Layout3";

export interface StyleAttributesInterface {
  button_link_layout: string;
  button_link_slide_type: string;
  button_link_style: string;
  image_orientation: string;
  item_on_row: string;
  ratio: string;
}

type Props = {
  style_attributes: StyleAttributesInterface;
  button_link_attributes: ButtonMultiLinkInterface;
  button_link_see_more_btn: string;
  blockData: any;
};

export const BUTTON_MULTI_LINK_LAYOUT_COMPONENT = {
  [LINK_LAYOUTS.TEXT_RIGHT]: Layout1,
  [LINK_LAYOUTS.TEXT_LEFT]: Layout1,
  [LINK_LAYOUTS.TEXT_UNDER]: Layout2,
  [LINK_LAYOUTS.TEXT_ZIG_ZAG]: Layout3,
};

const ButtonMultiLinkDetail = (props: any) => {
  const {
    style_attributes,
    button_link_attributes,
    button_link_see_more_btn,
    content_attributes,
  } = props?.blockData;
  const {
    button_link_layout,
    button_link_slide_type,
    button_link_style,
    image_orientation,
    item_on_row,
    ratio,
    button_size,
  } = style_attributes || {};
  const { items } = content_attributes || {};
  const gridStyle = useMemo(() => {
    let gridStyleValue = null;
    switch (item_on_row) {
      case MULTI_BUTTON_LINK_ITEM_ON_ROW.ONE:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case MULTI_BUTTON_LINK_ITEM_ON_ROW.TWO:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case MULTI_BUTTON_LINK_ITEM_ON_ROW.THREE:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case MULTI_BUTTON_LINK_ITEM_ON_ROW.FOUR:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case MULTI_BUTTON_LINK_ITEM_ON_ROW.FIVE:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      case MULTI_BUTTON_LINK_ITEM_ON_ROW.SIX:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
          gap: "12px",
        };
        break;
      default:
        gridStyleValue = {
          display: "grid",
          gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
          gap: "12px",
        };
    }
    return gridStyleValue;
  }, [item_on_row]);

  const ButtonMultiLinkDetailLayoutRender = useCallback((props) => {
    let Element = <>DEFAULT</>;
    if (BUTTON_MULTI_LINK_LAYOUT_COMPONENT[props?.button_link_layout]) {
      const RenderComponent =
        BUTTON_MULTI_LINK_LAYOUT_COMPONENT[props?.button_link_layout];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);

  return (
    <>
      <div style={gridStyle}>
        {items
          ?.filter((item) => item?.enable === 1)
          ?.map((item, index) => {
            return (
              <Fragment key={item.uid}>
                <ButtonMultiLinkDetailLayoutRender
                  button_link_title={item?.content_attributes?.link_title}
                  button_link_description={
                    item?.content_attributes?.link_description
                  }
                  button_link_image={item?.content_attributes?.image}
                  button_link_layout={
                    item?.style_attributes?.button_link_layout
                  }
                  button_link_url={item?.content_attributes?.link_url}
                  item_on_row={
                    button_link_layout === LINK_LAYOUTS.TEXT_ZIG_ZAG
                      ? 1
                      : item?.style_attributes?.item_on_row
                  }
                  button_link_style={item?.style_attributes?.button_link_style}
                  button_link_slide_type={button_link_slide_type}
                  button_link_size={item?.style_attributes?.button_size}
                  button_link_icon={item?.content_attributes?.button_icon}
                  button_link_url_icon={
                    item?.content_attributes?.button_icon_url
                  }
                  index={index}
                  button_link_template={
                    item?.style_attributes?.button_link_template
                  }
                />
              </Fragment>
            );
          })}
      </div>
    </>
  );
};

export default ButtonMultiLinkDetail;
