import { CARD_ITEM_ON_ROW, SLIDE_STYLE } from "@share/configs/const";
import { Image } from "antd-mobile";
import React, { useMemo } from "react";

type Props = {
  card_title: string;
  card_subtitle: string;
  card_description: string;
  card_image: string;
  card_label: string;
  card_btn_see_more: string;
  card_layout: string;
  card_image_ratio: string;
  card_url: string;
  item_on_row: string;
  card_slide_type: string;
};

const CardDetailLayout4 = (props: Props) => {
  const {
    card_title,
    card_subtitle,
    card_image,
    card_label,
    card_layout,
    card_image_ratio,
    item_on_row,
    card_slide_type,
  } = props;

  const isHideCardLabel = useMemo(
    () => !card_label || item_on_row === CARD_ITEM_ON_ROW.TWO,
    [card_label, item_on_row]
  );

  const isDisplayCardText = card_title || card_subtitle;

  return (
    <div
      className={`card-detail-layout relative ${card_layout}`}
      style={{
        marginBottom:
          card_slide_type === SLIDE_STYLE.NOT_PARTIAL_VISIBLE ? "20px" : "",
      }}
    >
      <Image
        src={card_image}
        style={{
          aspectRatio: card_image_ratio,
          borderRadius: "4px",
        }}
        fit="cover"
        className="card-image"
      />
      {!isHideCardLabel && (
        <span
          className="card-label text-neutral-900"
          style={{
            backdropFilter: "blur(5px)",
            opacity: "80%",
          }}
        >
          {card_label}
        </span>
      )}
      {isDisplayCardText && (
        <div className="card-text">
          {card_title && (
            <h4 className="card-title text-neutral-800">{card_title}</h4>
          )}
          {card_subtitle && (
            <div className="card-subtitle text-neutral-500">
              {card_subtitle}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardDetailLayout4;
