import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade } from "swiper";
// import {}
// import TrippleSlider from '../../features/swiper/uiinitiative-triple-slider/src/triple-slider';
import EffectPanorama from "@features/swiper/uiinitiative-panorama-slider/dist/effect-panorama.esm";
import { CardInterface } from "@features/main/store/interface";
import CardDetail, { StyleAttributesInterface } from "../../../CardDetail";

const CardPanorama = (props) => {
  const { cardItemArr, style_attibutes, see_more_btn_content } = props;
  const itemOnrow = style_attibutes?.item_on_row;
  return (
    <div>
      <Swiper
        modules={[EffectFade, EffectPanorama]}
        setWrapperSize={false}
        // spaceBetween={50}
        slidesPerView={itemOnrow ? parseInt(itemOnrow) : 1}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        effect="panorama"
        allowTouchMove={false}
      >
        {cardItemArr?.map((ele, index) => (
          <SwiperSlide>
            <CardDetail
              key={ele?.uid}
              style_attributes={style_attibutes}
              card_attributes={ele}
              card_see_more_btn={see_more_btn_content}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CardPanorama;
