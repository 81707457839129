import digitalProductApi from "@features/block/block-editor/DigitalProduct/api";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { Block, Position } from "@features/main/store/interface";
import { updateBlock } from "@features/main/store/positionsSlice";
import { getBlockListsOfPosition } from "../helper";
import { ACTION_TYPE } from "../constant";

const fetchDataProductApi = async (listID?: Array<number>) => {
  const params = listID ? { ids: listID.join(",") } : { status: 1 };
  try {
    const res = await digitalProductApi.listProduct(params);
    if (res?.success) {
      return res.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export default async function digitalProductExtraFunc(
  blockData: Block,
  positions?: Position[],
  dispatch?: any,
  actionType?: string
) {
  //Lấy digital product list mới nhất
  const productList = await fetchDataProductApi();
  //Chuyển về đúng format đang lưu trong content_attributes của block
  const formatProductList = productList?.map((product) => ({
    category: { id: product?.category?.id },
    currency: product?.currency,
    id: product?.id,
    name: product?.name,
    ordinal_number_lucky_card: product?.ordinal_number_lucky_card,
    original_price: product?.original_price,
    price: product?.price,
    product_type: product?.product_type,
    thumbnail: product?.thumbnails[0]?.url_small,
    cover_image: product?.cover_image ? product?.cover_image : null,
  }));
  //Cập nhập thay đổi của 1 digital product block vào các digital product block khác trên site
  if (productList) {
    for (let position of positions) {
      const blockLists = getBlockListsOfPosition(position);
      const digitalProductBlockList = blockLists?.filter((block) =>
        actionType === ACTION_TYPE.CANCEL
          ? block?.block_type === BLOCK_TYPE.DIGITAL_PRODUCT
          : block?.block_type === BLOCK_TYPE.DIGITAL_PRODUCT &&
            block?.uid !== blockData?.uid
      );
      for (let block of digitalProductBlockList) {
        const dp_data = block?.content_attributes?.dp_data;
        const newDpData = dp_data.map((dp) => {
          const newProduct = formatProductList?.find((x) => x.id === dp.id);
          return newProduct || dp;
        });
        const newDpItems = newDpData.map((dp) => dp.id);
        const newBlockData = {
          ...block,
          content_attributes: {
            ...block.content_attributes,
            dp_data: newDpData,
            dp_items: newDpItems,
          },
        };
        const tabUid =
          block?.tab_uid && block?.tab_uid !== "0" ? block?.tab_uid : null;
        dispatch(
          updateBlock({
            tabUid,
            positionSlug: position.slug,
            blockData: newBlockData,
          })
        );
      }
    }
  }
}
